import { useState } from "react";

export const useFilters = (searchKey = "user_name") => {
  const [filters, setFilters] = useState({});

  const handleSearch = (q) => {
    setFilters((old) => {
      if (q) {
        return {
          ...old,
          [searchKey]: q,
        };
      } else {
        const newFilters = { ...old };
        delete newFilters[searchKey];
        return newFilters;
      }
    });
  };

  const handleApply = (f) => {
    const _filters = { ...f };
    if (filters[searchKey]) _filters[searchKey] = filters[searchKey];
    setFilters(_filters);
  };

  const handleRemoveFilter = (keys) => {
    const _filters = { ...filters };
    for (let key of keys) {
      delete _filters[key];
    }
    setFilters(_filters);
  };

  return {
    filters,
    handleSearch,
    handleApply,
    handleRemoveFilter,
  };
};
