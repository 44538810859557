import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteBriefingBoard } from "../../api/briefing-board";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { useEffect } from "react";

export default function useDeleteBriefingBoard() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteBriefingBoard,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["briefingboards"],
        refetchActive: true,
        refetchInactive: false,
      });
    },
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.success("Briefing board deleted successfully", TOAST_CONFIG);
    }
  }, [mutation.isSuccess]);

  useEffect(() => {
    if (mutation.isError) {
      toast.error("Failed to delete briefing board", TOAST_CONFIG);
    }
  }, [mutation.isError]);

  return mutation;
}
