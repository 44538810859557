import React, { useState } from "react";

import { Dialog } from "primereact/dialog";
import { Galleria } from "primereact/galleria";
import { ScrollTop } from "primereact/scrolltop";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { useCitation } from "../../hooks/citationQueries";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import Loader from "../loader";
import { CitationPrintable } from "./CitationPrintable";

export const ShowModal = ({ isVisible, citationId, onClose }) => {
  const [isPrintActive, setIsPrintActive] = useState(false);

  const handlePrint = () => {
    setIsPrintActive(true);
  };

  const { isPending: isPendingCitation, data: fetchedCitation } =
    useCitation(citationId);

  const itemTemplate = (item) => {
    return <img src={item} style={{ width: "100%", display: "block" }} />;
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item}
        style={{ maxWidth: "40%", width: "90px", display: "block" }}
      />
    );
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  const typeBodyTemplate = (citation) => {
    if (citation) {
      if (citation.daily_activity_report_code === "Red") {
        return (
          <Tag
            severity="danger"
            className="w-full text-base"
            text="white"
            style={{
              padding: "0.4rem",
              backgroundColor: "#d32f2fd1",
              textAlign: "center",
            }}
          >
            {citation.daily_activity_report_code}
          </Tag>
        );
      } else if (citation.daily_activity_report_code === "Yellow") {
        return (
          <Tag
            severity="warning"
            className="w-full text-base"
            text="black"
            style={{
              padding: "0.4rem",
              backgroundColor: "#fbc02dd1",
              textAlign: "center",
            }}
          >
            {citation.daily_activity_report_code}
          </Tag>
        );
      } else
        return (
          <Tag
            severity="success"
            className="w-full text-base"
            text="white"
            style={{
              padding: "0.4rem",
              backgroundColor: "#689f38d1",
              textAlign: "center",
            }}
          >
            {citation.daily_activity_report_code}
          </Tag>
        );
    }
  };

  return (
    <Dialog
      className="w-11 sm:w-8 md:w-7 lg:w-7 xl:w-6 h-auto"
      showHeader={false}
      contentStyle={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}
      dismissableMask
      closeOnEscape
      visible={isVisible}
      onHide={onClose}
    >
      {isPendingCitation ? (
        <Loader />
      ) : (
        <>
          <div className="p-2 ">
            <div className="grid">
              <div className="col">
                <span className="align-items-center inline-flex ">
                  <h2>Citation #{fetchedCitation?.data.data.citation_id}</h2>
                </span>
              </div>

              <div className="col">
                <div className="flex align-items-end justify-content-end">
                  <span className="mt-3">
                    <Button
                      icon="pi pi-times"
                      rounded
                      link
                      text
                      onClick={onClose}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="">
              <b>{typeBodyTemplate(fetchedCitation?.data.data)}</b>
              <p>
                Citation Date:{" "}
                <b>
                  {moment(
                    utcToLocale(fetchedCitation?.data.data.citation_date)
                  ).format("yyyy-MM-DD HH:mm")}
                </b>
              </p>

              <p>
                Citation Received:{" "}
                <ul>
                  {fetchedCitation?.data.data.citation_types.map(
                    (citation_type) =>
                      citation_type.used == 1 ? (
                        <li>{citation_type.type}</li>
                      ) : null
                  )}
                </ul>
              </p>

              <p className="text-base">
                License Plate:{" "}
                <b>{fetchedCitation?.data.data.vehicle.license_plate}</b>
              </p>

              <p className="text-base">
                Vehicle:{" "}
                <b>
                  {fetchedCitation?.data.data.vehicle.make +
                    " " +
                    fetchedCitation?.data.data.vehicle.model}
                </b>
              </p>

              <p className="text-base">
                Details:{" "}
                <b>
                  {fetchedCitation?.data.data.details
                    ? fetchedCitation?.data.data.details
                    : "N/A"}
                </b>
              </p>

              {fetchedCitation?.data.data.media ? (
                <>
                  <Galleria
                    value={fetchedCitation?.data.data.media.images}
                    responsiveOptions={responsiveOptions}
                    numVisible={5}
                    className="w-full "
                    circular
                    showItemNavigators
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                  />
                </>
              ) : null}

              <p>
                Created Date:{" "}
                <b>
                  {moment(
                    utcToLocale(fetchedCitation?.data.data.created_at)
                  ).format("yyyy-MM-DD HH:mm")}
                </b>
              </p>
              <p>
                Created by: <b>{fetchedCitation?.data.data.officer_name}</b>
              </p>

              <ScrollTop
                target="parent"
                threshold={100}
                className="w-2rem h-2rem border-round bg-primary"
                icon="pi pi-arrow-up text-base"
              />
            </div>
          </div>
          <Button
            onClick={handlePrint}
            label="Print"
            className="ml-2 hide-on-print"
          />
          {isPrintActive ? (
            <CitationPrintable
              isVisible
              citationId={citationId}
              onClose={() => setIsPrintActive(false)}
            />
          ) : null}
        </>
      )}
    </Dialog>
  );
};
