import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";

import { useLocalState } from "../../hooks/useLocalState";

import { Sidebar } from "primereact/sidebar";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { FloatLabel } from "primereact/floatlabel";

import { useUser, useInsertUser, useUpdateUser } from "../../hooks/userQueries";
import Loader from "../loader";
import {
  useHousehold,
  useHouseHoldByCriteria,
} from "../../hooks/houseHoldQueries";

function Header({ userId, isPending, isSubmitting }) {
  const action = userId ? "Updat" : "Creat";

  return (
    <div className="w-full flex align-items-center justify-content-between ">
      <div>
        <h2>{userId ? "Update user" : "Create user"}</h2>
      </div>
      <div className="flex gap-2">
        <Button
          disabled={isSubmitting || isPending}
          type="submit"
          form="user"
          label={`${action}${isSubmitting ? "ing..." : "e"}`}
        />
      </div>
    </div>
  );
}

const UserSheet = ({ isVisible, userId, onClose }) => {
  const [roles] = useLocalState("", "userRoles");
  const [properties] = useLocalState("", "userProperties");
  const [householdOptions, setHouseholdOptions] = useState([]);
  const { user, isPending: isUserPending } = useUser(userId);

  const { household: userHousehold, isPending: isPendingUserHousehold } =
    useHousehold(user?.house_hold_id);

  const defaultValues = {
    role_id: user?.role_id,
    email: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
    user_name: user?.user_name,
    house_hold_id: user?.house_hold_id,
    password: "",
    properties: user?.properties?.map((p) => p.property_id) ?? [],
    property_id: user?.properties?.[0]?.property_id ?? null,
  };

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    values: defaultValues,
  });

  const isPending =
    (userId && isUserPending) ||
    (user?.house_hold_id && isPendingUserHousehold);

  const selectedProperty = watch("property_id");
  const role_id = watch("role_id");
  const roleSelected = roles.find((r) => r.id === role_id);
  const isResident = roleSelected?.name === "Resident";

  const { households } = useHouseHoldByCriteria({
    q: "",
    propertyId: isResident ? selectedProperty : null,
    onlyResident: false,
  });

  const { mutateAsync: mutateInsertUser, isSuccess: isSuccessInsertUser } =
    useInsertUser();

  const { mutateAsync: mutateUpdateUser, isSuccess: isSuccessUpdateUser } =
    useUpdateUser();

  useEffect(() => {
    if (isSuccessInsertUser || isSuccessUpdateUser) {
      onClose();
    }
  }, [isSuccessInsertUser, isSuccessUpdateUser]);

  useEffect(() => {
    if (households.length > 0) {
      setHouseholdOptions(households);
    }
    if (
      userHousehold &&
      !householdOptions.find(
        (h) => h.house_hold_id === userHousehold.house_hold_id
      )
    ) {
      setHouseholdOptions([...households, userHousehold]);
    }
  }, [households, userHousehold]);

  const onSubmit = async (data) => {
    try {
      const mutate = userId === null ? mutateInsertUser : mutateUpdateUser;
      const userData = {
        role_id: data.role_id,
        status: data.status,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        user_name: data.user_name,
        // properties: data.properties.map((p) => ({ property_id: p })),
        house_hold_id: data.house_hold_id,
      };

      if (roleSelected.without_property === 0) {
        userData.properties =
          roleSelected.multiple_properties === 1
            ? data.properties.map((p) => ({ property_id: p }))
            : [{ property_id: data.property_id }];
      } else {
        userData.properties = [];
      }

      if (userId) {
        userData["user_id"] = userId;
      }

      await mutate(userData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Sidebar
        visible={isVisible}
        position="right"
        header={() => (
          <Header
            userId={userId}
            isPending={isPending}
            isSubmitting={isSubmitting}
          />
        )}
        onHide={onClose}
        className="w-full md:w-6 lg:w-5"
      >
        {isPending ? (
          <Loader />
        ) : (
          <>
            <form
              id="user"
              className="p-fluid mt-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="field ">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="role_id"
                    rules={{
                      validate: (value) =>
                        value !== undefined || "You must select a role",
                      valueAsNumber: true,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        filter
                        autoFocus
                        disabled={userId}
                        placeholder="Select a role"
                        optionLabel="name"
                        optionValue="id"
                        options={roles}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("properties", []);
                          setValue("house_hold_id", null);
                        }}
                      />
                    )}
                  />
                  <label htmlFor="role">Role</label>
                </span>
                <small className="block pt-1">
                  {errors.role_id && (
                    <div className="text-red-500">{errors.role_id.message}</div>
                  )}
                </small>
              </div>

              {roleSelected && !roleSelected.without_property ? (
                <div className="field ">
                  <span className="p-float-label">
                    {roleSelected.multiple_properties ? (
                      <Controller
                        control={control}
                        name="properties"
                        rules={
                          roleSelected.property_mandatory === 1
                            ? {
                                required:
                                  "You must select at least one property",
                              }
                            : {}
                        }
                        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                        render={({ field }) => (
                          <MultiSelect
                            id={field.name}
                            disabled={
                              userId && roleSelected.can_change_property === 0
                            }
                            placeholder="Select properties"
                            optionLabel="name"
                            optionValue="property_id"
                            options={properties}
                            maxSelectedLabels={3}
                            showClear
                            showSelectAll
                            className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                            display="chip"
                            {...field}
                          />
                        )}
                      />
                    ) : (
                      <Controller
                        control={control}
                        name="property_id"
                        rules={
                          roleSelected.property_mandatory === 1
                            ? { required: "You must select a property" }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <Dropdown
                            {...field}
                            disabled={
                              userId && roleSelected.can_change_property === 0
                            }
                            options={properties}
                            optionLabel="name"
                            optionValue="property_id"
                            placeholder="Select property"
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                    )}
                    <label htmlFor="properties">Properties</label>
                  </span>
                  <small className="block pt-1">
                    {(errors.properties || errors.property_id) && (
                      <div className="text-red-500">
                        {errors.properties?.message ||
                          errors.property_id?.message}
                      </div>
                    )}
                  </small>
                </div>
              ) : null}

              {isResident && (
                <div className="field">
                  <FloatLabel>
                    <Controller
                      control={control}
                      name="house_hold_id"
                      render={({ field }) => (
                        <Dropdown
                          placeholder="Select household"
                          showClear
                          disabled={
                            (user && user.house_hold_id) || !selectedProperty
                          }
                          optionLabel="name"
                          optionValue="house_hold_id"
                          options={householdOptions}
                          {...field}
                          onChange={(e) => {
                            const value = e.target.value ?? null;
                            setValue("house_hold_id", value);
                          }}
                        />
                      )}
                    />
                    <label htmlFor="house_hold_id">Household</label>
                  </FloatLabel>
                </div>
              )}

              <div className="field">
                <div className="formgrid grid">
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        name="first_name"
                        control={control}
                        rules={{ required: "Name is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            maxLength={50}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="first_name">First Name</label>
                      {errors.first_name && (
                        <div className="text-red-500">
                          {errors.first_name.message}
                        </div>
                      )}
                    </span>
                  </div>

                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        name="last_name"
                        control={control}
                        rules={{ required: "Name is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            maxLength={50}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="last_name">Last Name</label>
                      {errors.last_name && (
                        <div className="text-red-500">
                          {errors.last_name.message}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="formgrid grid">
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        name="email"
                        control={control}
                        rules={{ required: "Name is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            maxLength={50}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="email">Email</label>
                      {errors.email && (
                        <div className="text-red-500">
                          {errors.email.message}
                        </div>
                      )}
                    </span>
                  </div>
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        name="user_name"
                        control={control}
                        rules={{ required: "Username is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            autoFocus
                            maxLength={50}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="user_name">User Name</label>
                      {errors.user_name && (
                        <div className="text-red-500">
                          {errors.user_name.message}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default UserSheet;
