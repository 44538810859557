import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchCreateCitations,
  fetchCitations,
  fetchCitation,
  fetchCitationTypes,
  addCitation,
  updateCitation,
  deleteCitation,
} from "../api/citationApi";
import { useLocalState } from "./useLocalState";

let accessToken = JSON.parse(localStorage.getItem("accessToken")) || null;

//import { ACCESS_TOKEN } from "../utils/Constansts";

//console.log(ACCESS_TOKEN)

export function useCreateCitations(propertyId) {
  return useQuery({
    queryKey: ["citationCreate", { propertyId }],
    queryFn: () => fetchCreateCitations(propertyId),
    retry: false,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
  });
}

export function useCitationTypes(violationCreate) {
  return useQuery({
    queryKey: ["citationTypes", violationCreate],
    queryFn: () => fetchCitationTypes(),
    retry: false,
    enabled: violationCreate,
    staleTime: 10,
    refetchOnWindowFocus: true,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
  });
}

export function useCitations({
  rows,
  page,
  propertyId,
  sortField,
  sortOrder,
  user_name,
  citation_date_from,
  citation_date_to,
  license_plate,
  details,
}) {
  const order = sortOrder == 1 ? "asc" : "desc";

  return useQuery({
    queryKey: [
      "citations",
      {
        rows,
        page,
        propertyId,
        sortField,
        order,
        user_name,
        citation_date_from,
        citation_date_to,
        license_plate,
        details,
      },
    ],
    queryFn: () =>
      fetchCitations(
        rows,
        page,
        propertyId,
        sortField,
        order,
        user_name,
        citation_date_from,
        citation_date_to,
        license_plate,
        details
      ),
    retry: false,
    //staleTime: 10,
    refetchInterval: 400000,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
    onError: (error) => {
      console.log("ALL BAD");
      console.error(error.message);
    },
  });
}

export function useCitation(itemId, propertyId) {
  return useQuery({
    queryKey: ["citation", { itemId, propertyId }],
    queryFn: () => (itemId != null ? fetchCitation(itemId, propertyId) : null),
    retry: false,

    onSuccess: () => {
      console.log("ALL GOOD");
    },
    onError: (error) => {
      console.log("ALL BAD");
      console.error(error.message);
    },
  });
}

export const useInsertCitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addCitation,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["citations"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["vehicles_details"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export function useUpdateCitation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCitation,
    //networkMode: offlineFirst,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["citations"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["vehicles"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured");
    },
  });
}

export function useDeleteCitation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCitation,

    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["citations"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["vehicles"],
      });
    },
  });
}
