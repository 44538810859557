import React, { useCallback, useEffect, useMemo, useState } from "react";
import { add, update, remove, getAll, get, openDb } from "../utils/store";
import { useConnStatus } from "./network";
import secureLocalStorage from "react-secure-storage";
import { useBulk } from "../hooks/useBulk";
import { toast } from "react-toastify";

const OfflineContext = React.createContext();
OfflineContext.displayName = "OfflineContext";

const OfflineProvider = ({ children }) => {
  const { connStatus } = useConnStatus();
  const [items, setItems] = useState([]);
  const { mutateAsync, isSuccess, isPending, data, reset } = useBulk();

  useEffect(() => {
    async function run() {
      const items = await getAll();

      setItems(items);
    }
    run();
  }, []);

  const user = useMemo(() => {
    const userData = JSON.parse(secureLocalStorage.getItem("userData"));

    return userData;
  }, []);

  useEffect(() => {
    if (!connStatus || !user) return;

    const _items = items.filter((i) => !i.hasOwnProperty("status"));

    if (_items.length) {
      mutateAsync(_items);
    }
  }, [connStatus, user, items]);

  useEffect(() => {
    async function run() {
      const items = await getAll();

      let created = false;
      let errrors = false;
      for (const d of data.data) {
        const item = items.find((i) => i.temp_id === d.temp_id);
        if (d.status === 1) {
          created = true;
          await remove(item.id);
        } else if (d.status === 0) {
          errrors = true;
          await update(item.id, { ...item, status: d.status, errors: d.data });
        }
      }

      await refreshItems();
      reset();

      if (created && !errrors) {
        toast.success("Records created successfully.");
      } else if (created && errrors) {
        toast.info(
          "Some records were created but some others haver errors, please correct them to try again"
        );
      } else {
        toast.error(
          "Couln't create the records, please correct the errors to try again"
        );
      }
    }

    if (isSuccess) {
      run();
    }
  }, [data, isSuccess, reset]);

  const refreshItems = async () => {
    const items = await getAll();

    setItems(items);
  };

  const addItem = (type, data) => {
    return new Promise(async (resolve) => {
      const result = await add(type, data);
      const item = await get(result);

      setItems((old) => [...old, item]);
      resolve(item);
    });
  };

  const updateItem = (id, data) => {
    return new Promise(async (resolve) => {
      const { status, errors, ...item } = await get(id);
      await update(id, { ...item, data });
      const items = await getAll();

      setItems(items);
      resolve();
    });
  };

  const removeItem = (id) => {
    return new Promise(async (resolve) => {
      await remove(id);
      const items = await getAll();

      setItems(items);
      resolve();
    });
  };

  return (
    <OfflineContext.Provider
      value={{ items, addItem, updateItem, removeItem, isPending }}
    >
      {children}
    </OfflineContext.Provider>
  );
};

const useOffline = () => {
  const context = React.useContext(OfflineContext);
  if (context === undefined) {
    throw new Error("useOffline must be used within a OfflineProvider");
  }
  return context;
};

export { useOffline };
export default OfflineProvider;
