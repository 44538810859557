import { Dialog } from "primereact/dialog";
import { useBriefingBoard } from "../../hooks/briefingboards";
import Loader from "../loader";

export default function DetailModal({ isVisible, briefingBoardId, onClose }) {
  const { briefingBoard, isPending } = useBriefingBoard(briefingBoardId);

  return (
    <Dialog
      className="w-11 sm:w-8 md:w-7 lg:w-7 xl:w-6 h-auto"
      contentStyle={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}
      dismissableMask
      closeOnEscape
      header={`Briefing Board #${briefingBoardId}`}
      visible={isVisible}
      onHide={onClose}
    >
      {isPending ? (
        <Loader />
      ) : (
        <div className="flex flex-column gap-2">
          <p>{briefingBoard.brief}</p>
          {+briefingBoard.notified_to === +briefingBoard.read_by ? (
            <p className="text-sm">All recipients have read the briefing</p>
          ) : (
            <div className="text-sm flex gap-2">
              <span>Unread by: </span>
              <div className="flex flex-column gap-1">
                {briefingBoard.notifications
                  .filter((n) => !n.read_at)
                  .map((n) => (
                    <span key={n.id}>
                      {n.user_name} ({n.role_name})
                    </span>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
}
