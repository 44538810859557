import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addHouseHold,
  deleteHouseHold,
  fetchHouseHoldByCriteria,
  fetchHouseHoldById,
  fetchHouseHoldByPropertyId,
  fetchHouseHolds,
  updateHouseHold,
} from "../api/houseHoldApi";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";

export function useHouseholds(rows, page, propertyId) {
  const {
    data: fetchedHouseholds,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ["households", { rows, page, propertyId }],
    queryFn: () => fetchHouseHolds(rows, page, propertyId),
    retry: false,
    //staleTime: 10,
    networkMode: "offlineFirst",
  });

  const households = fetchedHouseholds?.data.households.data ?? [];
  const totalRecords = fetchedHouseholds?.data.households.total ?? 0;
  const from = fetchedHouseholds?.data.households.from ?? 0;
  const to = fetchedHouseholds?.data.households.to ?? 0;

  useEffect(() => {
    if (isError) {
      toast.error(error.code, TOAST_CONFIG);
    }
  }, [isError]);

  return { households, totalRecords, from, to, isPending, isError };
}

export function useHousehold(id) {
  const {
    data,
    isPending: _isPending,
    isError,
  } = useQuery({
    queryKey: ["households", { id }],
    queryFn: () => fetchHouseHoldById(id),
    enabled: Boolean(id),
    retry: false,
  });
  const household = !_isPending && id ? data.data.data : null;

  return { household, isPending: id && _isPending, isError };
}

export function useHouseHoldByCriteria({ q, propertyId, onlyResident = true }) {
  const { data, isPending, isError } = useQuery({
    queryKey: ["households", { q, propertyId, onlyResident }],
    queryFn: () => fetchHouseHoldByCriteria(q, propertyId, onlyResident),
    retry: false,
    enabled: Boolean(propertyId),
    onSuccess: () => {
      console.log("ALL GOOD");
    },
  });
  const households = !isPending ? data?.data?.households ?? [] : [];

  return { households, isPending, isError };
}

export function useHouseHoldByPropertyId(propertyId) {
  return useQuery({
    queryKey: ["households", { propertyId }],
    queryFn: () => fetchHouseHoldByPropertyId(propertyId),
    retry: false,
    //staleTime: 60 * 10000, //600 seconds
    //enabled: permits_by === 1,
    //enabled: false,
    staleTime: 60000,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
  });
}

export const useInsertHousehold = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addHouseHold,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["households"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.success("Household created", TOAST_CONFIG);
    }
  }, [mutation.isSuccess]);

  useEffect(() => {
    if (mutation.isError) {
      toast.error("Household creation failed", TOAST_CONFIG);
    }
  }, [mutation.isError]);

  return mutation;
};

export function useUpdateHousehold() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updateHouseHold,
    //networkMode: offlineFirst,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["households"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured");
    },
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.success("Household updated", TOAST_CONFIG);
    }
  }, [mutation.isSuccess]);

  useEffect(() => {
    if (mutation.isError) {
      toast.error("Household update failed", TOAST_CONFIG);
    }
  }, [mutation.isError]);

  return mutation;
}

export function useDeleteHousehold() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteHouseHold,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["households"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.success("Household deleted", TOAST_CONFIG);
    }
  }, [mutation.isSuccess]);

  return mutation;
}
