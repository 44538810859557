import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLocalState } from "../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";

import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Ripple } from "primereact/ripple";
import { StyleClass } from "primereact/styleclass";

import { LOGO } from "../utils/Constansts";

import { useProperty } from "../context/PropertyProvider";
import { getHasPermission } from "../helpers/helpers";
import useLogout from "../hooks/auth/useLogout";

const SECTIONS = {
  REPORT: "report",
  USER: "user",
  CLIENT: "client",
  PARKING: "parking",
  OFFICER: "officer",
  NOTIFICATION: "notification",
};

const PATH_SECTION = {
  "/dar": SECTIONS.REPORT,
  "/incidents": SECTIONS.REPORT,
  "/dispatchlogs": SECTIONS.REPORT,
  "/poolaccesslogs": SECTIONS.REPORT,
  "/users": SECTIONS.USER,
  "/households": SECTIONS.CLIENT,
  "/vehicles": SECTIONS.CLIENT,
  "/properties": SECTIONS.CLIENT,
  "/safelistings": SECTIONS.PARKING,
  "/citations": SECTIONS.PARKING,
  "/vehicleprofile": SECTIONS.PARKING,
  "/maitenance": SECTIONS.OFFICER,
  "/briefing": SECTIONS.NOTIFICATION,
};
const DETAULT_SECTION_STATE = {
  report: [],
  user: [],
  client: [],
  parking: [],
  officer: [],
  notification: [],
};

const Sidemenu = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="w-screen h-5rem px-4 py-2 bg-white flex align-conten-center shadow-1 isolate z-1">
      <div className="flex align-items-center gap-1">
        <div className=" p-toolbar-group-start p-toolbar-group-left">
          <Button
            icon="pi pi-bars"
            rounded
            text
            aria-label="Refresh"
            onClick={() => setVisible(true)}
          />
        </div>
        <span className="inline-flex align-items-center gap-1 px-2 py-2">
          <img
            width="35"
            height="35"
            alt="logo"
            src={LOGO}
            className="chakra-image"
          />

          <span className="font-medium text-xl font-semibold">
            STARS
            <span className="text-primary">PRO</span>
          </span>
        </span>
      </div>
      <Navbar visible={visible} onClose={() => setVisible(false)} />
    </div>
  );
};

function Navbar({ visible, onClose }) {
  const { mutate: logout } = useLogout();
  const [propertyId, setPropertyId] = useProperty();
  const [sections, setSections] = useState(
    structuredClone(DETAULT_SECTION_STATE)
  );

  const btnRef1 = useRef(null);
  const btnRef2 = useRef(null);
  const btnRef3 = useRef(null);
  const btnRef4 = useRef(null);
  const btnRef5 = useRef(null);
  const btnRef6 = useRef(null);
  const btnRef7 = useRef(null);

  const location = useLocation();

  const [properties] = useLocalState("", "userProperties");
  const [userData] = useLocalState("", "userData");

  const permissions = userData[0].user.roles[0].permissions;

  useEffect(() => {
    if (!propertyId) return;

    const property = properties.find((p) => p.property_id === propertyId);

    if (!property) return;
    const { property_hired_type: propertyHiredType } = property;

    const sections = propertyHiredType
      .filter((t) => t.granted)
      .reduce((acc, t) => {
        const section = PATH_SECTION[t.path];
        const hasAccess = permissions.find((p) => p.name === t.permissions);
        if (section && hasAccess) {
          acc[section].push(t);
        }

        return acc;
      }, structuredClone(DETAULT_SECTION_STATE));

    sections.report.sort((a, b) => (a.position > b.position ? 1 : -1));
    sections.user.sort((a, b) => (a.position > b.position ? 1 : -1));
    sections.client.sort((a, b) => (a.position > b.position ? 1 : -1));
    sections.parking.sort((a, b) => (a.position > b.position ? 1 : -1));
    sections.officer.sort((a, b) => (a.position > b.position ? 1 : -1));
    sections.notification.sort((a, b) => (a.position > b.position ? 1 : -1));

    setSections(sections);
  }, [propertyId, permissions, properties]);

  useEffect(() => {
    onClose();
  }, [location]);

  const handleChangeContextProperty = (event) => {
    const propertyId = Number(event.target.value);

    setPropertyId(propertyId);

    const property = properties.find(
      (property) => property.property_id === propertyId
    );
    secureLocalStorage.setItem(
      "userContextPropertyData",
      JSON.stringify(property)
    );
    secureLocalStorage.setItem(
      "userContextPropertyCitationTypes",
      JSON.stringify(property.property_citation_types)
    );

    onClose();
  };

  const handleLogout = () => {
    onClose();
    logout();
  };

  const hasUserPermissions = getHasPermission(permissions, "user list");
  const hasPropertiesPermissions = getHasPermission(
    permissions,
    "property list"
  );
  const hasVehiclesPermissions = getHasPermission(permissions, "vehicle list");

  const dispatchLogCreate = getHasPermission(permissions, "dispatchlog create");
  const darCreate = getHasPermission(permissions, "dar create");
  const incidentCreate = getHasPermission(permissions, "incidentreport create");
  const citationCreate = getHasPermission(permissions, "citation create");
  const poolAccessCreate = getHasPermission(
    permissions,
    "poolaccesslog create"
  );
  const isOfflineSyncActive =
    dispatchLogCreate ||
    darCreate ||
    incidentCreate ||
    citationCreate ||
    poolAccessCreate;

  return (
    <>
      <ConfirmDialog />

      <Sidebar
        visible={visible}
        onHide={() => onClose()}
        content={({ closeIconRef, hide }) => (
          <div className="min-h-screen flex relative lg:static surface-ground">
            <div
              id="app-sidebar-2"
              className="surface-section h-screen  lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none"
              style={{ width: "280px" }}
            >
              <div className="flex flex-column h-full">
                <div className="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
                  <span className="inline-flex align-items-center gap-2">
                    <span className="inline-flex align-items-center gap-1 px-2 py-2">
                      <img
                        width="35"
                        height="35"
                        alt="logo"
                        src={LOGO}
                        className="chakra-image css-1i779ob"
                      ></img>
                      <span className="font-medium text-xl font-semibold">
                        STARS<span className="text-primary">PRO</span>
                      </span>
                    </span>
                  </span>
                  <span>
                    <Button
                      type="button"
                      ref={closeIconRef}
                      onClick={(e) => hide(e)}
                      icon="pi pi-times"
                      rounded
                      outlined
                      className="h-2rem w-2rem"
                    ></Button>
                  </span>
                </div>
                <div className="overflow-y-auto">
                  <ul className="list-none p-3 m-0">
                    <br />
                    <span className="p-float-label w-full sm:w-14rem md:w-18rem">
                      <Dropdown
                        value={propertyId}
                        options={properties}
                        optionLabel="name"
                        optionValue="property_id"
                        onChange={(e) => {
                          handleChangeContextProperty(e);
                        }}
                        placeholder="Select a propery"
                        filter
                        className="w-full sm:w-14rem md:w-18rem"
                      />
                      <label htmlFor="property_id">Current Property</label>
                    </span>
                  </ul>
                  {sections.report.length ? (
                    <ul className="list-none p-3 m-0">
                      <li>
                        <StyleClass
                          nodeRef={btnRef1}
                          selector="@next"
                          enterFromClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          <div
                            ref={btnRef1}
                            className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                          >
                            <span className="font-medium">
                              <i className="pi pi-chart-bar mr-2"></i>REPORTS
                            </span>
                            <i className="pi pi-chevron-down"></i>
                            <Ripple />
                          </div>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 hidden overflow-y-hidden">
                          {sections.report.map((s) => (
                            <li key={s.path}>
                              <Link
                                to={s.path}
                                className="p-ripple no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                              >
                                <span className="font-medium">{s.label}</span>
                                <Ripple />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  ) : null}
                  {hasUserPermissions ? (
                    <ul className="list-none p-3 m-0">
                      <li>
                        <StyleClass
                          nodeRef={btnRef2}
                          selector="@next"
                          enterFromClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          <div
                            ref={btnRef2}
                            className="p-ripple no-underline p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                          >
                            <span className="font-medium">
                              <i className="pi pi-user mr-2"></i>USER MANAGEMENT
                            </span>
                            <i className="pi pi-chevron-down"></i>
                            <Ripple />
                          </div>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 hidden overflow-y-hidden">
                          <li>
                            <Link
                              to="users"
                              className="p-ripple no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                            >
                              <span className="font-medium">Users</span>
                              <Ripple />
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : null}
                  {hasPropertiesPermissions || hasVehiclesPermissions ? (
                    <ul className="list-none p-3 m-0">
                      <li>
                        <StyleClass
                          nodeRef={btnRef3}
                          selector="@next"
                          enterFromClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          <div
                            ref={btnRef3}
                            className="p-ripple no-underline p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                          >
                            <span className="font-medium">
                              <i className="pi pi-user mr-2"></i>
                              CLIENT MANAGEMENT
                            </span>
                            <i className="pi pi-chevron-down"></i>
                            <Ripple />
                          </div>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 hidden overflow-y-hidden">
                          {sections.client.map((s) => (
                            <li key={s.path}>
                              <Link
                                to={s.path}
                                className="p-ripple no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                              >
                                <span className="font-medium">{s.label}</span>
                                <Ripple />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  ) : null}
                  {sections.parking.length ? (
                    <ul className="list-none p-3 m-0">
                      <li>
                        <StyleClass
                          nodeRef={btnRef4}
                          selector="@next"
                          enterFromClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          <div
                            ref={btnRef4}
                            className="p-ripple no-underline p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                          >
                            <span className="font-medium">
                              <i className="pi pi-user mr-2"></i>PARKING
                              ENFORCEMENT
                            </span>
                            <i className="pi pi-chevron-down"></i>
                            <Ripple />
                          </div>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 hidden overflow-y-hidden">
                          {sections.parking.map((s) => (
                            <li key={s.path}>
                              <Link
                                to={s.path}
                                className="p-ripple no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                              >
                                <span className="font-medium">{s.label}</span>
                                <Ripple />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  ) : null}
                  {sections.officer.length ? (
                    <ul className="list-none p-3 m-0">
                      <li>
                        <StyleClass
                          nodeRef={btnRef5}
                          selector="@next"
                          enterFromClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          <div
                            ref={btnRef5}
                            className="p-ripple no-underline p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                          >
                            <span className="font-medium">
                              <i className="pi pi-user mr-2"></i>OFFICER
                              MANAGEMENT
                            </span>
                            <i className="pi pi-chevron-down"></i>
                            <Ripple />
                          </div>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 hidden overflow-y-hidden">
                          {sections.officer.map((s) => (
                            <li key={s.path}>
                              <Link
                                to={s.path}
                                className="p-ripple no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                              >
                                <span className="font-medium">{s.label}</span>
                                <Ripple />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  ) : null}
                  {sections.notification.length ? (
                    <ul className="list-none p-3 m-0">
                      <li>
                        <StyleClass
                          nodeRef={btnRef6}
                          selector="@next"
                          enterFromClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          <div
                            ref={btnRef6}
                            className="p-ripple no-underline p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                          >
                            <span className="font-medium">
                              <i className="pi pi-bell mr-2"></i>NOTIFICATIONS
                            </span>
                            <i className="pi pi-chevron-down"></i>
                            <Ripple />
                          </div>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 hidden overflow-y-hidden">
                          {sections.notification.map((s) => (
                            <li key={s.path}>
                              <Link
                                to={s.path}
                                className="p-ripple no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                              >
                                <span className="font-medium">{s.label}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  ) : null}

                  {isOfflineSyncActive ? (
                    <ul className="list-none p-3 m-0">
                      <li>
                        <StyleClass
                          nodeRef={btnRef7}
                          selector="@next"
                          enterFromClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          <div
                            ref={btnRef7}
                            className="p-ripple no-underline p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                          >
                            <span className="font-medium">
                              <i className="pi pi-wifi mr-2"></i>OFFLINE SYNC
                            </span>
                            <i className="pi pi-chevron-down"></i>
                            <Ripple />
                          </div>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 hidden overflow-y-hidden">
                          <li>
                            <Link
                              to="offline"
                              className="p-ripple no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                            >
                              <span className="font-medium">OFFLINE SYNC</span>
                              <Ripple />
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : null}
                  <ul className="list-none p-3 m-0">
                    <Button
                      onClick={handleLogout}
                      type="button"
                      icon="pi pi-sign-out"
                    >
                      Logout
                    </Button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}

export default Sidemenu;
