import axiosInstance from "./axios";

export async function fetchDars(
  rows,
  page,
  property_id,
  user_name,
  details,
  from,
  to
) {
  const params = new URLSearchParams();

  params.append("page", page);
  params.append("context_property_id", property_id);

  if (user_name) params.append("user_name", user_name);
  if (details) params.append("details", details);
  if (from) params.append("date_from", from);
  if (to) params.append("date_to", to);

  let url = `dailyactivityreport/list/${rows}/dar_date/desc?${params.toString()}`;

  return await axiosInstance.get(url);
}

export async function fetchDarCodes() {
  let url = "dailyactivityreportcode";
  return await axiosInstance.get(url);
}

export async function fetchDarTypes() {
  let url = "dailyactivityreporttype";
  return await axiosInstance.get(url);
}

export function fetchDar(id) {
  let url = `dailyactivityreport/show?daily_activity_report_id=${id}`;
  return axiosInstance.get(url);
}
export async function addDar(data) {
  let url = `dailyactivityreport/store`;

  return await axiosInstance.post(url, data);
}

export function updateDar(json) {
  let url = `dailyactivityreport/update`;
  return axiosInstance.post(url, json);
}

export function deleteDar(id) {
  let url = `dailyactivityreport/delete`;
  return axiosInstance.delete(url, { data: { daily_activity_report_id: id } });
}
