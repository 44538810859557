import { useState } from "react";
import Datatable from "./datatable";
import { Button } from "primereact/button";
import { useQueryClient } from "@tanstack/react-query";
import SideBar from "./SideBar";
import List from "../list";
import FiltersSheet from "./filters-sheet";
import { useSectionPermissions } from "../../hooks/useSectionPermissions";
import { Chip } from "primereact/chip";
import ProofSheet from "./proof-sheet";
import { CitationPrintable } from "./CitationPrintable";
import { ShowModal } from "./ShowModal";

const MODAL_KEYS = {
  SHEET: "sheet",
  MODAL: "modal",
  FILTERS: "filters",
  PRINT: "print",
  PROOF: "proof",
};

const Citations = () => {
  const [modalKeyOpen, setModalKeyOpen] = useState(null);
  const [citationId, setCitationId] = useState(null);
  const [filters, setFilters] = useState({});
  const queryClient = useQueryClient();

  const permissionsObject = useSectionPermissions("citation", [
    "create",
    "show",
    "update",
    "delete",
  ]);

  const handleOpenModal = (key, citationId) => {
    setModalKeyOpen(key);
    if (citationId) setCitationId(citationId);
  };

  const handleCloseModal = () => {
    setModalKeyOpen(null);
    setCitationId(null);
  };

  const handleCreate = (citationId) => {
    setModalKeyOpen(MODAL_KEYS.PROOF);
    setCitationId(citationId);
  };

  const handleSearch = (q) => {
    setFilters((old) => {
      if (q) {
        return {
          ...old,
          user_name: q,
        };
      } else {
        delete old.user_name;

        return { ...old };
      }
    });
  };

  const handleApply = (f) => {
    const _filters = { ...f };
    if (filters.user_name) _filters.user_name = filters.user_name;

    setFilters(_filters);
    handleCloseModal();
  };

  const handleRemoveFilter = (keys) => {
    const _filters = { ...filters };

    for (let key of keys) {
      delete _filters[key];
    }

    setFilters(_filters);
  };

  const refechCitations = (e) => {
    queryClient.invalidateQueries({
      queryKey: ["citations"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const vehicleSet = useState(0);

  return (
    <>
      <List>
        <List.Header title="Citations">
          <Button
            icon="pi pi-refresh"
            rounded
            text
            aria-label="Refresh"
            tooltip="Refresh"
            onClick={(e) => refechCitations(e)}
          />
        </List.Header>
        <List.Filters
          filterBy="user"
          showCreate={permissionsObject.create}
          entity="citation"
          onSearch={handleSearch}
          onFilterModal={() => handleOpenModal(MODAL_KEYS.FILTERS)}
          onCreate={() => handleOpenModal(MODAL_KEYS.SHEET)}
        />
        {Object.keys(filters).length ? (
          <div className="flex gap-2 flex-wrap">
            {filters.details ? (
              <Chip
                label={filters.details}
                removable
                onRemove={() => handleRemoveFilter(["details"])}
              />
            ) : null}
            {filters.license_plate ? (
              <Chip
                label={filters.license_plate}
                removable
                onRemove={() => handleRemoveFilter(["license_plate"])}
              />
            ) : null}
            {filters.citation_date_from ? (
              <Chip
                label={filters.citation_date_from}
                removable
                onRemove={() =>
                  handleRemoveFilter(["citation_date_from", "citation_date_to"])
                }
              />
            ) : null}
            {filters.citation_date_to ? (
              <Chip
                label={filters.citation_date_to}
                removable
                onRemove={() =>
                  handleRemoveFilter(["citation_date_from", "citation_date_to"])
                }
              />
            ) : null}
          </div>
        ) : null}
        <Datatable
          permissionsObject={permissionsObject}
          filters={filters}
          onShowDetail={(citationId) =>
            handleOpenModal(MODAL_KEYS.MODAL, citationId)
          }
          onEdit={(citationId) => handleOpenModal(MODAL_KEYS.SHEET, citationId)}
          onPrint={(citationId) =>
            handleOpenModal(MODAL_KEYS.PRINT, citationId)
          }
        />
      </List>

      {modalKeyOpen === MODAL_KEYS.MODAL ? (
        <ShowModal
          isVisible
          citationId={citationId}
          onClose={handleCloseModal}
        />
      ) : null}

      {modalKeyOpen === MODAL_KEYS.SHEET ? (
        <SideBar
          isVisible
          citationId={citationId}
          onCreate={handleCreate}
          onClose={handleCloseModal}
        />
      ) : null}

      {modalKeyOpen === MODAL_KEYS.PROOF ? (
        <ProofSheet
          isVisible
          citationId={citationId}
          onClose={handleCloseModal}
        />
      ) : null}

      {modalKeyOpen === MODAL_KEYS.PRINT ? (
        <CitationPrintable
          isVisible
          citationId={citationId}
          onClose={handleCloseModal}
        />
      ) : null}

      {modalKeyOpen === MODAL_KEYS.FILTERS ? (
        <FiltersSheet
          isVisible
          filters={filters}
          onApply={handleApply}
          onClose={handleCloseModal}
        />
      ) : null}
    </>
  );
};

export default Citations;
