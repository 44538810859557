import { Dialog } from "primereact/dialog";
import { useContextUser } from "../context/UserProvider";
import { useProperty } from "../context/PropertyProvider";
import { useForm, Controller } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function SelectProperty() {
  const navigate = useNavigate();
  const { user } = useContextUser();
  const [property, setProperty] = useProperty();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setProperty(data.property);
    navigate("/loading");
  };

  if (property) {
    return <Navigate to="/dar" />;
  }

  return (
    <Dialog
      header="Select Property"
      visible
      className="w-full md:w-5"
      closable={false}
      draggable={false}
      resizable={false}
      onHide={() => {}}
    >
      <div className="flex flex-column gap-4">
        <form id="property-form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="property"
            rules={{ required: "Select a property" }}
            render={({ field, fieldState }) => (
              <Dropdown
                options={user.properties}
                optionLabel="name"
                optionValue="property_id"
                placeholder="Property"
                {...field}
                className={classNames("w-full", {
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />
          {errors.property && (
            <small className="text-red-500">{errors.property.message}</small>
          )}
        </form>
        <div className="flex justify-content-end">
          <Button form="property-form" type="submit" label="Select" />
        </div>
      </div>
    </Dialog>
  );
}
