import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import PositionProvider from "./context/position";
import PropertyProvider from "./context/PropertyProvider";
import NetworkProvider from "./context/network";
import { PrimeReactProvider } from "primereact/api";
import IndexedDB from "./utils/indexedDB";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./config";

import "leaflet/dist/leaflet.css";

import "react-toastify/dist/ReactToastify.css";
import OfflineProvider from "./context/offline";
import UserProvider from "./context/UserProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, //60 seconds
    },
    onError: (error) => {
      console.log("nico");
      if (error.response.status === 403) {
        console.log("nico");
      }
    },
  },
  /*queryCache: new QueryCache({
    onError: (error) => { 
       console.log("Something went wrong");
       return
    },
  }),*/
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PrimeReactProvider>
    <IndexedDB />

    <Router>
      <NetworkProvider>
        <QueryClientProvider client={queryClient}>
          <PositionProvider>
            <UserProvider>
              <OfflineProvider>
                <PropertyProvider>
                  <App />
                </PropertyProvider>
              </OfflineProvider>
            </UserProvider>
          </PositionProvider>
        </QueryClientProvider>
      </NetworkProvider>
    </Router>
  </PrimeReactProvider>
);

//Initialize service worker
/*if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/sw.js");
}*/
