import { useEffect, useState } from "react";
import { useProperty } from "../context/PropertyProvider";

const ITEMS_PER_PAGE = 10;

export default function usePagination() {
  const [propertyId] = useProperty();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(ITEMS_PER_PAGE);

  const handlePageChange = (event) => {
    const page = event.page + 1;
    setPage(page);
  };

  useEffect(() => {
    setPage(1);
  }, [propertyId]);

  return { page, rows, handlePageChange };
}
