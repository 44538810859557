import React from "react";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

import { Dialog } from "primereact/dialog";
import { Galleria } from "primereact/galleria";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { OverlayPanel } from "primereact/overlaypanel";

import Map from "../map";

import { useIncidentReport } from "../../hooks/incidentReportQueries";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import Loader from "../loader";

export const ShowModal = ({ isVisible, incidentId, onClose }) => {
  const printRef = useRef();
  const mapOverlay = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const { isPending: isPendingIncidentReport, data: fetchedIncidentReport } =
    useIncidentReport(incidentId);

  const itemTemplate = (item) => {
    return <img src={item} style={{ width: "100%", display: "block" }} />;
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item}
        style={{ maxWidth: "40%", width: "90px", display: "block" }}
      />
    );
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  const typeBodyTemplate = (incidentReport) => {
    if (incidentReport) {
      if (incidentReport.daily_activity_report_code === "Red") {
        return (
          <Tag
            severity="danger"
            className="w-full text-base"
            text="white"
            style={{
              padding: "0.4rem",
              backgroundColor: "#d32f2fd1",
              textAlign: "center",
            }}
          >
            {incidentReport.incident_report_type}
          </Tag>
        );
      } else if (incidentReport.daily_activity_report_code === "Yellow") {
        return (
          <Tag
            severity="warning"
            className="w-full text-base"
            text="black"
            style={{
              padding: "0.4rem",
              backgroundColor: "#fbc02dd1",
              textAlign: "center",
            }}
          >
            {incidentReport.incident_report_type}
          </Tag>
        );
      } else
        return (
          <Tag
            severity="success"
            className="w-full text-base"
            text="white"
            style={{
              padding: "0.4rem",
              backgroundColor: "#689f38d1",
              textAlign: "center",
            }}
          >
            {incidentReport.incident_report_type}
          </Tag>
        );
    }
  };

  return (
    <Dialog
      className="w-11 sm:w-8 md:w-7 lg:w-7 xl:w-6 h-auto"
      showHeader={false}
      contentStyle={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}
      dismissableMask
      closeOnEscape
      visible={isVisible}
      onHide={onClose}
    >
      {isPendingIncidentReport ? (
        <Loader />
      ) : (
        <>
          <div ref={printRef} className="p-2 ">
            <div className="grid">
              <div className="col">
                <span className="align-items-center inline-flex ">
                  <h2>
                    Incident Report #
                    {fetchedIncidentReport?.data.data.incident_report_id}
                  </h2>
                </span>
                <Button
                  icon="pi pi-map-marker"
                  rounded
                  link
                  text
                  onClick={(e) => mapOverlay.current.toggle(e)}
                />
                <OverlayPanel ref={mapOverlay} className="w-5 s:w-6">
                  {" "}
                  <Map
                    position={fetchedIncidentReport?.data.data.gps_coordinates.split(
                      ","
                    )}
                  />
                </OverlayPanel>
              </div>

              <div className="col">
                <div className="flex align-items-end justify-content-end">
                  <span className="mt-3">
                    <Button
                      icon="pi pi-times"
                      rounded
                      link
                      text
                      onClick={onClose}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="">
              <b>{typeBodyTemplate(fetchedIncidentReport?.data.data)}</b>
              <p>
                Incident Date:{" "}
                <b>
                  {moment(
                    utcToLocale(fetchedIncidentReport?.data.data.incident_date)
                  ).format("yyyy-MM-DD HH:mm")}
                </b>
              </p>

              <p className="text-base">
                Details: <b>{fetchedIncidentReport?.data.data.details}</b>
              </p>

              {fetchedIncidentReport?.data.data.images ? (
                <>
                  <Galleria
                    value={fetchedIncidentReport?.data.data.images}
                    responsiveOptions={responsiveOptions}
                    numVisible={5}
                    className="w-full "
                    circular
                    showItemNavigators
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                  />
                </>
              ) : null}

              <p>
                Created Date:{" "}
                <b>
                  {moment(
                    utcToLocale(fetchedIncidentReport?.data.data.created_at)
                  ).format("yyyy-MM-DD HH:mm")}
                </b>
              </p>
              <p>
                Created by:{" "}
                <b>{fetchedIncidentReport?.data.data.officer_name}</b>
              </p>
            </div>
          </div>
          <Button
            onClick={handlePrint}
            label="Print"
            className="ml-2 hide-on-print"
          />
        </>
      )}
    </Dialog>
  );
};
