import React, { useState, useCallback, useMemo } from "react";
import secureLocalStorage from "react-secure-storage";

const UserContext = React.createContext();
UserContext.displayName = "UserContext";

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const userData = secureLocalStorage.getItem("userData");
    if (userData) {
      const {
        user: { roles, ...user },
        ...userRoles
      } = JSON.parse(userData)[0];
      return { ...user, ...userRoles, role: roles[0] };
    }
  });

  const permissions = useMemo(() => {
    const permissions = user?.role?.permissions ?? [];
    return new Set(permissions.map((p) => p.name));
  }, [user]);

  const handleUser = useCallback((user) => {
    setUser(user);
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser: handleUser, permissions }}>
      {children}
    </UserContext.Provider>
  );
};

const useContextUser = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(
      "useContextUser debe estar dentro del provider userContext"
    );
  }
  return context;
};

export { useContextUser };
export default UserProvider;
