import { Dialog } from "primereact/dialog";
import {
  useNotifications,
  useMarkReadNotification,
} from "../hooks/notifications";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";

export default function Notifications() {
  const [notificationIndex, setNotificationIndex] = useState(0);
  const { notifications, updateNotification } = useNotifications();
  const {
    markAsRead: markAsReadNotification,
    isPending: isMarkAsReadPending,
    isSuccess: isMarkAsReadSuccess,
    reset: resetMarkAsRead,
  } = useMarkReadNotification();

  const handleMarkAsRead = () => {
    const notification = notifications[notificationIndex];
    markAsReadNotification(notification.notification_id);
  };

  useEffect(() => {
    if (isMarkAsReadSuccess) {
      updateNotification(notifications[notificationIndex].notification_id);

      if (notificationIndex + 1 < notifications.length) {
        setNotificationIndex(notificationIndex + 1);
      }

      resetMarkAsRead();
    }
  }, [
    isMarkAsReadSuccess,
    updateNotification,
    notificationIndex,
    resetMarkAsRead,
  ]);

  if (!notifications.length || notifications.every((n) => n.read)) return null;

  return (
    <Dialog
      header="Notifications"
      visible={true}
      onHide={() => {}}
      closable={false}
      draggable={false}
      style={{ width: "50vw" }}
    >
      <div className=" mb-4">{notifications[notificationIndex].data.brief}</div>
      <div className="flex mb-4 gap-2 align-items-center justify-content-center">
        {notifications.map((n, index) => (
          <div
            key={n.notification_id}
            style={{ width: "25px", height: "0.5rem" }}
            className={`${
              index === notificationIndex ? "bg-blue-500" : "bg-gray-400"
            }`}
          />
        ))}
      </div>
      <div className="flex justify-content-end">
        <Button
          label="Mark as read"
          loading={isMarkAsReadPending}
          disabled={notifications[notificationIndex].read}
          onClick={handleMarkAsRead}
        />
      </div>
    </Dialog>
  );
}
