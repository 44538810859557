import axiosInstance from "./axios";

const BASE_URL = "notification";

export async function getNotifications() {
  const response = await axiosInstance.get(`${BASE_URL}/unread`);
  return response.data;
}

export async function markAsReadNotification(notificationId) {
  const response = await axiosInstance.post(`${BASE_URL}/mark_read`, {
    notification_id: notificationId,
  });
  return response.data;
}
