import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";

import { useConnStatus } from "../../context/network";

import { useUpdateEffect } from "primereact/hooks";

import { ShowModal } from "./ShowModal";

import {
  useCitations,
  useCitation,
  useDeleteCitation,
} from "../../hooks/citationQueries";

import VisibleContext from "../../context/VisibleContext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { useProperty } from "../../context/PropertyProvider";

import { CitationPrintable } from "./CitationPrintable";

import { toast } from "react-toastify";

import { TOAST_CONFIG } from "../../utils/Constansts";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import secureLocalStorage from "react-secure-storage";

const citationDateBodyTemplate = (citation) => {
  return moment(utcToLocale(citation.citation_date)).format("yyyy-MM-DD HH:mm");
};

const createdDateBodyTemplate = (citation) => {
  return moment(utcToLocale(citation.created_at)).format("yyyy-MM-DD HH:mm");
};

const detailsTemplate = (citation) => {
  let details = citation.details;

  if (citation.details.length > 100) {
    details = citation.details.substr(0, 100) + "...";
  }
  return details;
};

const mapBodyTemplate = (citation) => {
  return (
    <>
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${citation.gps_coordinates}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button icon="pi pi-map-marker" rounded link text />
      </a>
    </>
  );
};

const Datatable = ({
  permissionsObject,
  filters,
  onShowDetail,
  onEdit,
  onPrint,
}) => {
  const [propertyId] = useProperty();
  const { connStatus } = useConnStatus();

  const {
    VisibleFormContext,
    visibleLoadingModalContext,
    visibleModalContext,
    allowedOfflineActionContext,
    visibleCitationContext,
  } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;
  const [visibleModal, setVisibleModal] = visibleModalContext;

  const [citations, setCitations] = useState([]);
  const [officerNames, setOfficerNames] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  let [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "created_at",
    sortOrder: -1,
  });

  const {
    isPending,
    isSuccess,
    data: fetchedCitations,
  } = useCitations({
    rows: lazyState.rows,
    page: lazyState.page,
    propertyId,
    sortField: lazyState.sortField,
    sortOrder: lazyState.sortOrder,
    ...filters,
  });

  const totalRecords = fetchedCitations?.data.citation.total ?? 0;
  const from = fetchedCitations?.data.citation.from ?? 0;
  const to = fetchedCitations?.data.citation.to ?? 0;

  const {
    mutate: mutateDeleteCitation,
    isSuccess: isSuccessDeleteCitation,
    p,
  } = useDeleteCitation();

  useEffect(() => {
    if (isSuccess) {
      setCitations(fetchedCitations?.data.citation.data);

      let uniqueOfficers = [];
      fetchedCitations?.data.citation.data.map((citation) => {
        if (uniqueOfficers.indexOf(citation.officer_name) === -1) {
          uniqueOfficers.push(citation.officer_name);
        }
      });

      setOfficerNames(uniqueOfficers.map((item) => ({ officer_name: item })));
    }
  }, [isSuccess, fetchedCitations]);

  useEffect(() => {
    if (isSuccessDeleteCitation) {
      toast.success("Citation deleted", TOAST_CONFIG);
    }
  }, [isSuccessDeleteCitation]);

  useEffect(() => {
    setlazyState((old) => ({
      ...old,
      first: 0,
      page: 1,
      rows: 10,
    }));
  }, [propertyId]);

  const editBodyTemplate = (citation) => {
    if (permissionsObject.update) {
      return (
        <Button
          severity="info"
          icon="pi pi-pencil"
          size="small"
          onClick={() =>
            connStatus ? onEdit(citation.citation_id) : noConectionAlert()
          }
        />
      );
    }
  };

  const printBodyTemplate = (citation) => {
    return (
      <Button
        severity="help"
        icon="pi pi-print"
        size="small"
        onClick={() =>
          connStatus ? onPrint(citation.citation_id) : noConectionAlert()
        }
      />
    );
  };

  const noConectionAlert = () => {
    toast.warn(
      "Action not allowed while you don't have internet connection",
      TOAST_CONFIG
    );
  };

  const deleteBodyTemplate = (citation) => {
    if (permissionsObject.delete) {
      //console.log(`allowedOfflineAction 'false' ${connStatus.isOnline}`)
      return (
        <>
          <Button
            icon="pi pi-trash"
            onClick={() =>
              connStatus
                ? confirmDelete(citation.citation_id)
                : noConectionAlert()
            }
            severity="danger"
            size="small"
          />
        </>
      );
    }
  };

  const confirmDelete = (id) => {
    confirmDialog({
      key: 1,
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => mutateDeleteCitation(id),
    });
  };

  const onPage = (event) => {
    event.page = event.page + 1;
    setlazyState(event);
  };

  const onSort = (event) => {
    event.page = 0;

    if (event.sortField == null) {
      event.sortField = "created_at";
      event.sortOrder = 1;
    }
    setlazyState(event);
    //setlazyState(event);
  };

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <>
      <DataTable
        tableStyle={{ minWidth: "60rem" }}
        value={citations}
        lazy
        dataKey="citation_id_format"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        onRowSelect={(e) =>
          connStatus ? onShowDetail(e.data.citation_id) : noConectionAlert()
        }
        selectionMode="single"
        metaKeySelection={false}
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        loading={isPending}
        selection={selectedItem}
        isDataSelectable={() => true}
      >
        <Column field="citation_id_format" hidden header="ID" />
        <Column
          field="created_at"
          dataType="date"
          header="Created At"
          sortable
          body={createdDateBodyTemplate}
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="citation_date"
          dataType="date"
          header="Citation Date"
          sortable
          body={citationDateBodyTemplate}
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header="License Plate"
          field="license_plate"
          sortable
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header="Citation Received"
          field="citation_types"
          sortable
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="officer_name"
          header="User"
          sortable
          style={{ whiteSpace: "unset" }}
        />

        <Column header="Location" hidden body={mapBodyTemplate} />
        <Column
          field="details"
          header="Details"
          sortable
          body={detailsTemplate}
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header=""
          body={editBodyTemplate} //headerClassName="w-1rem"
        />

        <Column
          header=""
          body={printBodyTemplate} //headerClassName="w-1rem"
        />
        <Column
          header=""
          body={deleteBodyTemplate} //headerClassName="w-1rem"
        />
      </DataTable>

      <CitationPrintable />
    </>
  );
};

export default Datatable;
