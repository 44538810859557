import { useQueryClient } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import List from "../list";
import Datatable from "./datatable";
import DarSheet from "./dar-sheet";
import { DarInfoModal } from "./dar-info-modal";
import FiltersSheet from "./filters-sheet";
import { useSectionPermissions } from "../../hooks/useSectionPermissions";
import { useModal } from "../../hooks/useModal";
import { useFilters } from "../../hooks/useFilters";
import { MODAL_KEYS } from "../../constants/modalKeys";

const Dar = () => {
  const queryClient = useQueryClient();
  const { modalKeyOpen, itemId, handleOpenModal, handleCloseModal } =
    useModal();
  const { filters, handleSearch, handleApply, handleRemoveFilter } =
    useFilters("user_name");

  const permissionsObject = useSectionPermissions("dar", [
    "create",
    "show",
    "update",
    "delete",
  ]);

  const handleRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: ["dars"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const handleApplyFilters = (f) => {
    handleApply(f);
    handleCloseModal();
  };

  return (
    <>
      <List>
        <List.Header title="DAR">
          <Button
            icon="pi pi-refresh"
            rounded
            text
            aria-label="Refresh"
            tooltip="Refresh"
            onClick={handleRefresh}
          />
        </List.Header>
        <List.Filters
          showCreate={permissionsObject.create}
          filterBy="user"
          entity="DAR"
          onSearch={handleSearch}
          onFilterModal={() => handleOpenModal(MODAL_KEYS.FILTERS)}
          onCreate={() => handleOpenModal(MODAL_KEYS.SHEET)}
        />
        {Object.keys(filters).length ? (
          <div className="flex gap-2 flex-wrap">
            {filters.details && (
              <Chip
                label={filters.details}
                removable
                onRemove={() => handleRemoveFilter(["details"])}
              />
            )}
            {filters.date_from && (
              <Chip
                label={filters.date_from}
                removable
                onRemove={() => handleRemoveFilter(["date_from", "date_to"])}
              />
            )}
            {filters.date_to && (
              <Chip
                label={filters.date_to}
                removable
                onRemove={() => handleRemoveFilter(["date_from", "date_to"])}
              />
            )}
          </div>
        ) : null}

        <Datatable
          permissionsObject={permissionsObject}
          filters={filters}
          onShowDetail={(darId) => handleOpenModal(MODAL_KEYS.MODAL, darId)}
          onEdit={(darId) => handleOpenModal(MODAL_KEYS.SHEET, darId)}
        />
      </List>

      {modalKeyOpen === MODAL_KEYS.MODAL && (
        <DarInfoModal isVisible darId={itemId} onClose={handleCloseModal} />
      )}
      {modalKeyOpen === MODAL_KEYS.SHEET && (
        <DarSheet isVisible darId={itemId} onClose={handleCloseModal} />
      )}
      {modalKeyOpen === MODAL_KEYS.FILTERS && (
        <FiltersSheet
          isVisible
          filters={filters}
          onApply={handleApplyFilters}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Dar;
