import { useQuery } from "@tanstack/react-query";
import { getNotifications } from "../../api/notifications";
import { useCallback, useEffect, useState } from "react";

export default function useNotifications() {
  const [notifications, setNotifications] = useState([]);
  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: ["notifications"],
    queryFn: getNotifications,
    retry: false,
    networkMode: "offlineFirst",
  });

  const updateNotification = useCallback((notificationId) => {
    setNotifications((oldNotifications) => {
      return oldNotifications.map((n) =>
        n.notification_id === notificationId ? { ...n, read: true } : n
      );
    });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setNotifications(
        data.unread_notifications.map((n) => ({ ...n, read: false }))
      );
    }
  }, [isSuccess, data]);

  return { notifications, updateNotification, isLoading, error };
}
