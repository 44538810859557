import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContextUser } from "../context/UserProvider";

const RequireAuth = () => {
  const location = useLocation();
  const { user } = useContextUser();

  if (!user) return <Navigate to="/login" state={{ from: location }} replace />;

  return <Outlet />;
};

export default RequireAuth;
