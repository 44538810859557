import { Card } from "primereact/card";
import { timeout } from "../utils";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useLocalState } from "../hooks/useLocalState";
import { Dropdown } from "primereact/dropdown";

function Header({ title, children }) {
  return (
    <div className="mb-3 flex align-content-center">
      <h2
        className="text-2xl m-0 flex align-content-center flex-wrap pl-2 font-medium"
        style={{ transform: "translateX(-1px)" }}
      >
        {title}
      </h2>
      {children}
    </div>
  );
}

function Filters({
  showCreate,
  filterBy,
  entity,
  onSearch,
  onFilterModal,
  onCreate,
}) {
  // const [properties] = useLocalState("", "userProperties");
  const handleSearch = async (e) => {
    await timeout(300);
    const value = e.target.value;

    onSearch(value);
  };

  return (
    <div className="flex flex-column md:flex-row gap-2 align-items-center justify-content-between mb-2 pl-1 pr-2">
      <div className="flex flex-column md:flex-row align-items-center gap-2 flex-order-2 md:flex-order-1 w-full">
        {onSearch ? (
          <InputText
            className="p-0 px-3 w-full md:w-18rem"
            style={{ height: "2.5rem" }}
            placeholder={`Filter by ${filterBy}`}
            onChange={handleSearch}
          />
        ) : null}
        {/* <Dropdown
          options={properties}
          optionLabel="name"
          optionValue="property_id"
          value={2}
          style={{ height: "2.5rem" }}
          pt={{
            input: {
              className: "p-0 px-3 mt-auto mb-auto",
            },
          }}
          // value={filterBy}
          // onChange={(e) => onFilterBy(e.value)}
        /> */}
        {onFilterModal ? (
          <Button text className="hidden md:block" onClick={onFilterModal}>
            <span>Filters</span>
            <i className="pi pi-filter ml-2" />
          </Button>
        ) : null}
      </div>
      {showCreate ? (
        <div className="flex-order-1 md:flex-order-2 flex justify-content-end w-full gap-2">
          <Button text className="block md:hidden" onClick={onFilterModal}>
            <i className="pi pi-filter ml-2" />
          </Button>
          <Button label={`New ${entity}`} onClick={onCreate} />
        </div>
      ) : null}
    </div>
  );
}

function List({ children }) {
  return <Card>{children}</Card>;
}

List.Header = Header;
List.Filters = Filters;

export default List;
