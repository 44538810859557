import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchLogout } from "../../api/loginApi";
import { clearStore } from "../../utils";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useProperty } from "../../context/PropertyProvider";

export default function useLogout() {
  const navigate = useNavigate();
  const [_, setProperty] = useProperty();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: fetchLogout,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["login"],
        refetchActive: true,
        refetchInactive: false,
      });
    },
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries({
        queryKey: ["login"],
        refetchActive: true,
        refetchInactive: false,
      });

      clearStore();
      secureLocalStorage.clear();
      setProperty(null);

      navigate("/login");
    }
  }, [mutation.isSuccess]);

  return mutation;
}
