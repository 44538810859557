import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";

import { useLocalState } from "../../hooks/useLocalState";
import { useConnStatus } from "../../context/network";

import { useUpdateEffect } from "primereact/hooks";

import {
  useUsers,
  useUser,
  useDeleteUser,
  useUpdateUserStatus,
} from "../../hooks/userQueries";

import VisibleContext from "../../context/VisibleContext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { toast } from "react-toastify";

import { TOAST_CONFIG } from "../../utils/Constansts";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import { useProperty } from "../../context/PropertyProvider";

const Datatable = ({ permissionsObject, onShowDetail, onEdit }) => {
  const navigate = useNavigate();
  const galleria = useRef(null);
  const { connStatus } = useConnStatus();

  const [userData] = useLocalState("", "userData");
  const [roles] = userData[0].user.roles;
  const permissions = roles?.permissions || null;

  const createPermission = permissions.find(({ name }) => name === "user store")
    ? true
    : false;
  const updatePermission = permissions.find(
    ({ name }) => name === "user update"
  )
    ? true
    : false;
  const deletePermission = permissions.find(
    ({ name }) => name === "user delete"
  )
    ? true
    : false;

  //const [visibleVariables, setVisibleVariables] = useContext(VisibleFormContext);
  const {
    VisibleFormContext,
    visibleLoadingModalContext,
    visibleModalContext,
    allowedOfflineActionContext,
  } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;
  const [visibleModal, setVisibleModal] = visibleModalContext;
  const [allowedOfflineAction, setAllowedOfflineAction] =
    allowedOfflineActionContext;

  //const [visibleForm,setVisibleForm] = useLocalState(null,"visibleForm");
  //console.log(visibleForm)

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [dars, setUsers] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [property] = useProperty();

  let idToDelete = 0;
  let idToUpdate = 0;

  let [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "user_id",
    sortOrder: 0,
    filters: {
      daily_activity_report_id_format: { value: "", matchMode: "contains" },
      details: { details: "", matchMode: "contains" },
    },
  });

  const {
    isPending,
    isSuccess,
    isError: isErrorfetchedUsers,
    data: fetchedUsers,
    error,
    refetch,
  } = useUsers(lazyState.rows, lazyState.page, property);

  const {
    mutate: mutateDeleteUser,
    data: deletedUser,
    error: errorDeleteUser,
    isPending: isPendingDeleteUser,
    isSuccess: isSuccessDeleteUser,
  } = useDeleteUser();

  const {
    mutate: mutateUpdateUserStatus,
    data: updatedUserStatus,
    error: errorUpdateUserStatus,
    isPending: isPendingUpdateUserStatus,
    isSuccess: isSuccessUpdateUserStatus,
  } = useUpdateUserStatus();

  const {
    isPending: isPendingUser,
    isSuccess: isSuccessUser,
    isError: isErrorUser,
    data: fetchedUser,
    errorUser,
  } = useUser(visibleModal.itemId);

  useUpdateEffect(() => {
    if (isSuccess) {
      setUsers(fetchedUsers?.data.users.data);
      setTotalRecords(fetchedUsers?.data.users.total);
      setFrom(fetchedUsers?.data.users.from);
      setTo(fetchedUsers?.data.users.to);

      setLoading(false);
    }
    if (isPending) {
      setLoading(true);
    }

    if (isErrorfetchedUsers) {
      if (error?.response.status == 401) {
        toast.error(`You need to authenticate`, TOAST_CONFIG);

        navigate("/login");
      }
    }
  }, [fetchedUsers, isErrorfetchedUsers]);

  useUpdateEffect(() => {
    if (isSuccessDeleteUser) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success("User deleted successfully", TOAST_CONFIG);
    }
    if (isPendingDeleteUser) {
      setLoading(true);
      //console.log(isPendingDeleteUser);
    }
    if (errorDeleteUser) {
      console.log(errorDeleteUser);
    }
  }, [deletedUser]);

  useEffect(() => {
    setlazyState((old) => ({
      ...old,
      first: 0,
      page: 1,
      rows: 10,
    }));
  }, [property]);

  const userBodyTemplate = (item) => {
    return item.first_name + " " + item.last_name;
  };

  const createdDateBodyTemplate = (item) => {
    return moment(utcToLocale(item.created_at)).format("yyyy-MM-DD HH:mm");
  };

  const verifiedAtDateBodyTemplate = (item) => {
    return item.email_verified_at
      ? moment(utcToLocale(item.email_verified_at)).format("yyyy-MM-DD HH:mm")
      : null;
  };

  const lastLoginDateBodyTemplate = (item) => {
    return item.last_login_date
      ? moment(utcToLocale(item.last_login_date)).format("yyyy-MM-DD HH:mm") +
          " || " +
          item.last_login_date_humans
      : null;
  };

  const statusBodyTemplate = (item) => {
    if (!item) return null;

    if (item.status === "Disabled") {
      return (
        <Button
          label={item.status}
          severity="danger"
          size="small"
          onClick={() =>
            connStatus ? confirmUpdateStatus(item) : noConectionAlert()
          }
        />
      );
    } else
      return (
        <Button
          label={item.status}
          severity="success"
          size="small"
          onClick={() =>
            connStatus ? confirmUpdateStatus(item) : noConectionAlert()
          }
        />
      );
  };

  const editBodyTemplate = (item) => {
    if (permissionsObject.update) {
      return (
        <Button
          severity="info"
          icon="pi pi-pencil"
          size="small"
          onClick={() =>
            connStatus ? onEdit(item.user_id) : noConectionAlert()
          }
        />
      );
    } else {
      return (
        <Button severity="info" icon="pi pi-pencil" size="small" disabled />
      );
    }
  };

  const deleteBodyTemplate = (item) => {
    if (deletePermission) {
      //console.log(`allowedOfflineAction 'false' ${connStatus.isOnline}`)
      return (
        <>
          <Button
            icon="pi pi-trash"
            onClick={() =>
              connStatus ? confirmDelete(item.user_id) : noConectionAlert()
            }
            severity="danger"
            size="small"
          />
        </>
      );
    }
  };

  const noConectionAlert = () => {
    toast.warn(
      "Action not allowed while you don't have internet connection",
      TOAST_CONFIG
    );
  };

  const confirmDelete = (id) => {
    idToDelete = id;

    confirmDialog({
      key: 1,
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => mutateDeleteUser(idToDelete),
    });
  };

  const confirmUpdateStatus = (item) => {
    idToUpdate = item.user_id;

    confirmDialog({
      key: 1,
      message:
        item.status === "Enabled"
          ? "Do you want to disabled this user?"
          : "Do you want to enabled this user?",
      header: "Update Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => mutateUpdateUserStatus(idToUpdate),
    });
  };

  const onSelectionChange = (event) => {
    console.log(6666);
    const value = event.value;

    setSelectedItem(value);
    //setSelectAll(value.length === totalRecords);
  };

  const isSelectable = (data) => true;

  const isRowSelectable = (event) =>
    event.data ? isSelectable(event.data) : true;

  const onPage = (event) => {
    event.page = event.page + 1;
    setlazyState(event);
  };

  const onSort = (event) => {
    setlazyState(event);
  };

  const onFilter = (event) => {
    console.log(event);
    event["first"] = 0;
    setlazyState(event);
  };

  const openSidebarForm = (modalTitle, modalButton, id) => {
    setVisibleForm({
      visible: true,
      itemId: id,
      title: modalTitle,
    });
    setVisibleLoadingModal({
      visible: true,
      itemId: null,
      title: null,
    });
  };

  const openModal = (id) => {
    setVisibleModal({
      visible: true,
      itemId: id,
    });
  };

  const onHideModal = () => {
    setVisibleModal({
      visible: false,
      itemId: null,
    });
  };

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <>
      <DataTable
        //scrollHeight="500px"
        className={"h-full"}
        resizableColumns
        columnResizeMode="expand"
        value={dars}
        lazy
        /*filterDisplay="row"*/ dataKey="user_id"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        onRowSelect={(e) =>
          connStatus ? onShowDetail(e.data.user_id) : noConectionAlert()
        }
        selectionMode="single"
        metaKeySelection={false}
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onFilter={onFilter}
        filters={lazyState.filters}
        loading={loading}
        tableStyle={{ minWidth: "50rem" }}
        selection={selectedItem}
        isDataSelectable={isRowSelectable}
      >
        <Column
          field="created_at"
          header="Created At"
          body={createdDateBodyTemplate}
          filterPlaceholder="Search"
          style={{ minWidth: "20px", maxWidth: "40px", whiteSpace: "unset" }}
        />

        <Column
          header="Type"
          field="role_name"
          style={{ whiteSpace: "unset", maxWidth: "40px" }}
        />

        <Column
          field="first_name"
          header="User"
          body={userBodyTemplate}
          filterPlaceholder="Search"
          style={{ minWidth: "20px", maxWidth: "40px", whiteSpace: "unset" }}
        />

        <Column
          field="email_verified_at"
          header="Verified at"
          body={verifiedAtDateBodyTemplate}
          filterPlaceholder="Search"
          style={{ minWidth: "20px", maxWidth: "40px", whiteSpace: "unset" }}
        />

        <Column
          field="last_login_date"
          header="Last Login"
          body={lastLoginDateBodyTemplate}
          filterPlaceholder="Search"
          style={{ minWidth: "20px", maxWidth: "60px", whiteSpace: "unset" }}
        />
        <Column header="" body={statusBodyTemplate} className="w-1rem" />

        <Column header="" body={editBodyTemplate} className="w-1rem" />
        <Column header="" body={deleteBodyTemplate} className="w-1rem" />
      </DataTable>
    </>
  );
};

export default Datatable;
