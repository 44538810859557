import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";

import { useLocalState } from "../../hooks/useLocalState";
import { useConnStatus } from "../../context/network";

import { useUpdateEffect } from "primereact/hooks";

import { ShowModal } from "./incident-info-modal";

import {
  useIncidentReports,
  useIncidentReport,
  useDeleteIncidentReport,
} from "../../hooks/incidentReportQueries";

import VisibleContext from "../../context/VisibleContext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { toast } from "react-toastify";

import { TOAST_CONFIG } from "../../utils/Constansts";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import secureLocalStorage from "react-secure-storage";
import { useProperty } from "../../context/PropertyProvider";

const incidentDateBodyTemplate = (incidentReport) => {
  return moment(utcToLocale(incidentReport.incident_date)).format(
    "yyyy-MM-DD HH:mm"
  );
};

const createdDateBodyTemplate = (incidentReport) => {
  return moment(utcToLocale(incidentReport.created_at)).format(
    "yyyy-MM-DD HH:mm"
  );
};

const detailsTemplate = (incidentReport) => {
  let details = incidentReport.details;

  if (incidentReport.details.length > 100) {
    details = incidentReport.details.substr(0, 100) + "...";
  }
  return details;
};

const typeBodyTemplate = (incidentReport) => {
  if (incidentReport.code_type === "Red") {
    return (
      <Tag
        severity="danger"
        className=" w-10rem md:w-12rem lg:w-14rem "
        text="white"
        style={{
          padding: "0.4rem",
          backgroundColor: "#d32f2fd1",
          textAlign: "center",
        }}
      >
        {incidentReport.incident_report_type}
      </Tag>
    );
  } else if (incidentReport.code_type === "Yellow") {
    return (
      <Tag
        severity="warning"
        className=" w-10rem md:w-12rem lg:w-14rem "
        text="black"
        style={{
          padding: "0.4rem",
          backgroundColor: "#fbc02dd1",
          textAlign: "center",
        }}
      >
        {incidentReport.incident_report_type}
      </Tag>
    );
  } else
    return (
      <Tag
        severity="success"
        className=" w-10rem md:w-12rem lg:w-14rem "
        text="white"
        style={{
          padding: "0.4rem",
          backgroundColor: "#689f38d1",
          textAlign: "center",
        }}
      >
        {incidentReport.incident_report_type}
      </Tag>
    );
};

const mapBodyTemplate = (incidentReport) => {
  return (
    <>
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${incidentReport.gps_coordinates}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button icon="pi pi-map-marker" rounded link text />
      </a>
    </>
  );
};

const Datatable = ({ permissionsObject, onShowDetail, onEdit }) => {
  const [propertyId] = useProperty();
  const { connStatus } = useConnStatus();

  let [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "incident_report_id",
    sortOrder: 0,
    filters: {
      daily_activity_report_id_format: { value: "", matchMode: "contains" },
      details: { details: "", matchMode: "contains" },
    },
  });

  const {
    isLoading,
    isError: isErrorfetchedIncicents,
    data: fetchedIncidentReports,
    error,
  } = useIncidentReports(lazyState.rows, lazyState.page, propertyId);

  const {
    mutate: mutateDeleteIncidentReport,
    isSuccess: isSuccessDeleteIncidentReport,
    p,
  } = useDeleteIncidentReport();

  const incidentReports = fetchedIncidentReports?.data.incident_report.data;
  const totalRecords = fetchedIncidentReports?.data.incident_report.total ?? 0;
  const from = fetchedIncidentReports?.data.incident_report.from ?? 0;
  const to = fetchedIncidentReports?.data.incident_report.to ?? 0;

  useEffect(() => {
    if (isErrorfetchedIncicents) {
      toast.error(error.code, TOAST_CONFIG);
    }
  }, [isErrorfetchedIncicents]);

  useEffect(() => {
    if (isSuccessDeleteIncidentReport) {
      toast.success("Incident report deleted", TOAST_CONFIG);
    }
  }, [isSuccessDeleteIncidentReport]);

  useEffect(() => {
    setlazyState((old) => ({
      ...old,
      first: 0,
      page: 1,
      rows: 10,
    }));
  }, [propertyId]);

  const editBodyTemplate = (incidentReport) => {
    if (permissionsObject.update) {
      return (
        <Button
          severity="info"
          icon="pi pi-pencil"
          size="small"
          onClick={() =>
            connStatus
              ? onEdit(incidentReport.incident_report_id)
              : noConectionAlert()
          }
        />
      );
    }
  };

  const deleteBodyTemplate = (incidentReport) => {
    if (permissionsObject.delete) {
      //console.log(`allowedOfflineAction 'false' ${connStatus.isOnline}`)
      return (
        <>
          <Button
            icon="pi pi-trash"
            onClick={() =>
              connStatus
                ? confirmDelete(incidentReport.incident_report_id)
                : noConectionAlert()
            }
            severity="danger"
            size="small"
          />
        </>
      );
    }
  };

  const noConectionAlert = () => {
    toast.warn(
      "Action not allowed while you don't have internet connection",
      TOAST_CONFIG
    );
  };

  const confirmDelete = (id) => {
    confirmDialog({
      key: 1,
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: mutateDeleteIncidentReport(id),
    });
  };

  const onPage = (event) => {
    event.page = event.page + 1;
    setlazyState(event);
  };

  const onSort = (event) => {
    setlazyState(event);
  };

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <>
      <DataTable
        className={"h-full hide-on-print"}
        resizableColumns
        columnResizeMode="expand"
        value={incidentReports}
        lazy
        dataKey="incident_report_id_format"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        onRowSelect={(e) =>
          connStatus
            ? onShowDetail(e.data.incident_report_id)
            : noConectionAlert()
        }
        selectionMode="single"
        metaKeySelection={false}
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        rowsPerPageOptions={[5, 10, 25, 50]}
        loading={isLoading}
        tableStyle={{ minWidth: "50rem" }}
        isDataSelectable={() => true}
      >
        <Column
          field="daily_activity_report_id_format"
          hidden
          header="ID"
          filterPlaceholder="Search"
        />
        <Column
          field="created_at"
          header="Created At"
          body={createdDateBodyTemplate}
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="incident_date"
          header="Incident Date"
          body={incidentDateBodyTemplate}
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header="Type"
          className=" w-8rem md:w-12rem lg:w-14rem "
          body={typeBodyTemplate}
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="officer_name"
          header="User"
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header="Location"
          hidden
          body={mapBodyTemplate}
          filterPlaceholder="Search"
        />
        <Column
          field="details"
          header="Details"
          body={detailsTemplate}
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column header="" body={editBodyTemplate} className="w-1rem" />
        <Column header="" body={deleteBodyTemplate} className="w-1rem" />
      </DataTable>

      <ShowModal />
    </>
  );
};

export default Datatable;
