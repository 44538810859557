import { Button } from "primereact/button";
import List from "../list";
import Datatable from "./datatable";
import { useSectionPermissions } from "../../hooks/useSectionPermissions";
import { useModal } from "../../hooks/useModal";
import { MODAL_KEYS } from "../../constants/modalKeys";
import Sheet from "./sheet";
import useInvalidate from "../../hooks/useInvalidate";
import { useDeleteBriefingBoard } from "../../hooks/briefingboards";
import { confirmDialog } from "primereact/confirmdialog";
import DetailModal from "./detail-modal";

const BriefingBoards = () => {
  const invalidate = useInvalidate(["briefingboards"]);
  const { modalKeyOpen, itemId, handleOpenModal, handleCloseModal } =
    useModal();
  const { mutate: deleteBriefingBoard } = useDeleteBriefingBoard();

  const permissionsObject = useSectionPermissions("briefingboard", [
    "create",
    "show",
    "update",
    "delete",
  ]);

  return (
    <>
      <List>
        <List.Header title="Briefing Board">
          <Button
            icon="pi pi-refresh"
            rounded
            text
            aria-label="Refresh"
            tooltip="Refresh"
            onClick={() => invalidate()}
          />
        </List.Header>
        <List.Filters
          showCreate={permissionsObject.create}
          entity="Briefing Board"
          onCreate={() => handleOpenModal(MODAL_KEYS.SHEET)}
        />

        <Datatable
          isEditEnabled={permissionsObject.update}
          isDeleteEnabled={permissionsObject.delete}
          onShowDetail={(itemId) => handleOpenModal(MODAL_KEYS.MODAL, itemId)}
          onEdit={(itemId) => handleOpenModal(MODAL_KEYS.SHEET, itemId)}
          onDelete={(itemId) =>
            confirmDialog({
              header: "Delete Briefing Board",
              message: "Are you sure you want to delete this briefing board?",
              accept: () => deleteBriefingBoard(itemId),
              acceptLabel: "Delete",
              rejectLabel: "Cancel",
            })
          }
        />
      </List>

      {modalKeyOpen === MODAL_KEYS.SHEET && (
        <Sheet isVisible briefingBoardId={itemId} onClose={handleCloseModal} />
      )}
      {modalKeyOpen === MODAL_KEYS.MODAL && (
        <DetailModal
          isVisible
          briefingBoardId={itemId}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default BriefingBoards;
