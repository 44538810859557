import { useState } from "react";
import { Button } from "primereact/button";
import { useQueryClient } from "@tanstack/react-query";

import Datatable from "./datatable";
import { useSectionPermissions } from "../../hooks/useSectionPermissions";
import HouseholdSheet from "./household-sheet";
import List from "../../components/list";

const MODAL_KEYS = {
  SHEET: "sheet",
  MODAL: "modal",
};

export default function Household() {
  const [modalKeyOpen, setModalKeyOpen] = useState(null);
  const [householdId, setHouseholdId] = useState(null);
  const queryClient = useQueryClient();

  const permissionsObject = useSectionPermissions("household", [
    "create",
    "show",
    "update",
    "delete",
  ]);

  const handleRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: ["households"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const handleOpenModal = (key, householdId) => {
    setModalKeyOpen(key);
    if (householdId) setHouseholdId(householdId);
  };

  const handleCloseModal = () => {
    setModalKeyOpen(null);
    setHouseholdId(null);
  };

  return (
    <>
      <List>
        <List.Header title="Households">
          <Button
            icon="pi pi-refresh"
            rounded
            text
            aria-label="Refresh"
            tooltip="Refresh"
            onClick={handleRefresh}
          />
        </List.Header>
        <List.Filters
          showCreate={permissionsObject.create}
          // filterBy="user"
          entity="Household"
          // onSearch={handleSearch}
          // onFilterModal={() => handleOpenModal(MODAL_KEYS.FILTERS)}
          onCreate={() => handleOpenModal(MODAL_KEYS.SHEET)}
        />

        <Datatable
          permissionsObject={permissionsObject}
          onShowDetail={(itemId) => handleOpenModal(MODAL_KEYS.MODAL, itemId)}
          onEdit={(itemId) => handleOpenModal(MODAL_KEYS.SHEET, itemId)}
        />
      </List>

      {modalKeyOpen === MODAL_KEYS.SHEET && (
        <HouseholdSheet
          isVisible
          householdId={householdId}
          onClose={handleCloseModal}
        />
      )}
      {/* {modalKeyOpen === MODAL_KEYS.MODAL ? (
        <DarInfoModal isVisible darId={darId} onClose={handleCloseModal} />
      ) : null}
       */}
    </>
  );
}
