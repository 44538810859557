import { useState } from "react";

export const useModal = () => {
  const [modalKeyOpen, setModalKeyOpen] = useState(null);
  const [itemId, setItemId] = useState(null);

  const handleOpenModal = (key, id) => {
    setModalKeyOpen(key);
    if (id) setItemId(id);
  };

  const handleCloseModal = () => {
    setModalKeyOpen(null);
    setItemId(null);
  };

  return {
    modalKeyOpen,
    itemId,
    handleOpenModal,
    handleCloseModal,
  };
};
