import { useConnStatus } from "../../context/network";
import { useBriefingBoards } from "../../hooks/briefingboards";
import { DataTable } from "primereact/datatable";
import usePagination from "../../hooks/usePagination";
import { Column } from "primereact/column";
import moment from "moment";
import { Button } from "primereact/button";

export default function Datatable({
  isEditEnabled,
  isDeleteEnabled,
  onShowDetail,
  onEdit,
  onDelete,
}) {
  const { connStatus } = useConnStatus();
  const { page, rows, handlePageChange } = usePagination();

  const { briefingBoards, totalRecords, from, to, isLoading } =
    useBriefingBoards({
      rows,
      page,
    });

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <DataTable
      className={"h-fit hide-on-print"}
      resizableColumns
      columnResizeMode="expand"
      value={briefingBoards}
      lazy
      dataKey="briefing_board_id"
      paginator
      footer={footer}
      size="small"
      stripedRows
      scrollable
      onRowSelect={(e) => onShowDetail(e.data.briefing_board_id)}
      selectionMode="single"
      totalRecords={totalRecords}
      onPage={handlePageChange}
      rows={rows}
      rowsPerPageOptions={[5, 10, 25, 50]}
      loading={isLoading}
      emptyMessage="No data available"
      tableStyle={{ minWidth: "50rem" }}
      isDataSelectable={() => connStatus}
    >
      <Column header="User" body={(b) => `${b.first_name} ${b.last_name}`} />
      <Column
        field="created_at"
        header="Sent at"
        body={(b) => moment(b.created_at).format("yyyy-MM-DD HH:mm")}
      />
      <Column
        field="brief"
        header="Briefing"
        style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
      />
      <Column header="Read by" body={(b) => +b.read_by} />
      <Column header="Sent to" body={(b) => +b.notified_to} />

      <Column
        header=""
        body={(b) => (
          <Button
            disabled={+b.read_by !== 0 || !isEditEnabled || !connStatus}
            severity="info"
            icon="pi pi-pencil"
            size="small"
            onClick={() => onEdit(b.briefing_board_id)}
          />
        )}
        className="w-1rem"
      />
      <Column
        header=""
        body={(b) => (
          <Button
            disabled={+b.read_by !== 0 || !isDeleteEnabled || !connStatus}
            severity="danger"
            icon="pi pi-trash"
            size="small"
            onClick={() => onDelete(b.briefing_board_id)}
          />
        )}
        className="w-1rem"
      />
    </DataTable>
  );
}
