import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";

import { useLocalState } from "../../hooks/useLocalState";
import { useConnStatus } from "../../context/network";

import { useUpdateEffect } from "primereact/hooks";

import { ShowModal } from "./ShowModal";

import {
  useSafelistings,
  useSafelisting,
  useDeleteSafelisting,
} from "../../hooks/safelistingQueries";

import VisibleContext from "../../context/VisibleContext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { toast } from "react-toastify";

import { TOAST_CONFIG } from "../../utils/Constansts";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import secureLocalStorage from "react-secure-storage";

import MobileListView from "./MobileListView";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useProperty } from "../../context/PropertyProvider";

const Datatable = (permissionsObject) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const galleria = useRef(null);
  const { connStatus } = useConnStatus();

  const {
    VisibleFormContext,
    visibleLoadingModalContext,
    visibleModalContext,
    allowedOfflineActionContext,
  } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;
  const [visibleModal, setVisibleModal] = visibleModalContext;

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [dars, setSafelistings] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [property] = useProperty();

  let idToDelete = 0;

  let [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "safelisting_id",
    sortOrder: 0,
    filters: {
      daily_activity_report_id_format: { value: "", matchMode: "contains" },
      details: { details: "", matchMode: "contains" },
    },
  });

  const {
    isPending,
    isSuccess,
    isError: isErrorfetchedSafelistings,
    data: fetchedSafelistings,
    error,
    refetch,
  } = useSafelistings(lazyState.rows, lazyState.page, property);

  const {
    mutate: mutateDeleteSafelisting,
    data: deletedSafelisting,
    error: errorDeleteSafelisting,
    isPending: isPendingDeleteSafelisting,
    isSuccess: isSuccessDeleteSafelisting,
    p,
  } = useDeleteSafelisting();

  const {
    isPending: isPendingSafelisting,
    isSuccess: isSuccessSafelisting,
    isError: isErrorSafelisting,
    data: fetchedSafelisting,
    errorSafelisting,
  } = useSafelisting(visibleModal.itemId);

  useUpdateEffect(() => {
    if (isSuccess) {
      setSafelistings(fetchedSafelistings?.data.safelistings.data);
      setTotalRecords(fetchedSafelistings?.data.safelistings.total);
      setFrom(fetchedSafelistings?.data.safelistings.from);
      setTo(fetchedSafelistings?.data.safelistings.to);

      setLoading(false);
    }
    if (isPending) {
      setLoading(true);
    }

    if (isErrorfetchedSafelistings) {
      if (error?.response.status == 401) {
        toast.error(`You need to authenticate`, TOAST_CONFIG);

        navigate("/login");
      }
    }
  }, [fetchedSafelistings, isErrorfetchedSafelistings]);

  useUpdateEffect(() => {
    if (isSuccessDeleteSafelisting) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have delete a Safelisting`, TOAST_CONFIG);
    }
    if (isPendingDeleteSafelisting) {
      setLoading(true);
      //console.log(isPendingDeleteSafelisting);
    }
    if (errorDeleteSafelisting) {
      console.log(errorDeleteSafelisting);
    }
  }, [deletedSafelisting]);

  useEffect(() => {
    setlazyState((old) => ({
      ...old,
      first: 0,
      page: 1,
      rows: 10,
    }));
  }, [property]);

  const startDateBodyTemplate = (safelisting) => {
    return moment(safelisting.start_date).format("yyyy-MM-DD");
  };

  const endDateBodyTemplate = (safelisting) => {
    return moment(safelisting.end_date).format("yyyy-MM-DD");
  };

  const adminOverrideBodyTemplate = (safelisting) => {
    return safelisting.admin_override == 1 ? "Yes" : "No";
  };

  const createdDateBodyTemplate = (safelisting) => {
    return moment(utcToLocale(safelisting.created_at)).format(
      "yyyy-MM-DD HH:mm"
    );
  };

  const durationDaysBodyTemplate = (safelisting) => {
    const firstDate = new Date(safelisting.start_date);
    const secondDate = new Date(safelisting.end_date);

    const firstDateInMs = firstDate.getTime();
    const secondDateInMs = secondDate.getTime();

    const differenceBtwDates = secondDateInMs - firstDateInMs;

    const aDayInMs = 24 * 60 * 60 * 1000;

    const daysDiff = Math.round(differenceBtwDates / aDayInMs);
    //console.log(daysDiff)

    return `${daysDiff} days`;
  };

  const statusBodyTemplate = (safelisting) => {
    const firstDate = new Date(safelisting.end_date);
    const secondDate = new Date();

    if (secondDate >= firstDate) {
      return (
        <Tag
          severity="danger"
          className="w-3rem md:w-4rem lg:w-6rem "
          text="white"
          style={{
            padding: "0.4rem",
            backgroundColor: "#d32f2fd1",
            textAlign: "center",
          }}
        >
          Expired
        </Tag>
      );
    } else {
      return (
        <Tag
          severity="success"
          className="w-3rem md:w-4rem lg:w-6rem "
          text="white"
          style={{
            padding: "0.4rem",
            backgroundColor: "#689f38d1",
            textAlign: "center",
          }}
        >
          Active
        </Tag>
      );
    }
  };

  const showBodyTemplate = (safelisting) => {
    return (
      <Button
        severity="secondary"
        icon="pi pi-eye"
        size="small"
        onClick={() =>
          connStatus
            ? openModal(safelisting.safelisting_id)
            : noConectionAlert()
        }
        style={{ backgroundColor: "#64748b" }}
      />
    );
  };

  const editBodyTemplate = (safelisting) => {
    const firstDate = new Date(safelisting.end_date);
    const secondDate = new Date();

    if ((secondDate < firstDate) & permissionsObject.permissionsObject.update) {
      return (
        <Button
          severity="info"
          icon="pi pi-pencil"
          size="small"
          onClick={() =>
            connStatus
              ? openSidebarForm(
                  "Update Safelisting",
                  "Update",
                  safelisting.safelisting_id
                )
              : noConectionAlert()
          }
        />
      );
    } else {
      return (
        <Button severity="info" icon="pi pi-pencil" size="small" disabled />
      );
    }
  };

  const deleteBodyTemplate = (safelisting) => {
    const firstDate = new Date(safelisting.end_date);
    const secondDate = new Date();

    if ((secondDate < firstDate) & permissionsObject.permissionsObject.delete) {
      return (
        <Button
          severity="danger"
          icon="pi pi-trash"
          size="small"
          onClick={() =>
            connStatus
              ? confirmDelete(safelisting.safelisting_id)
              : noConectionAlert()
          }
        />
      );
    } else {
      return (
        <Button severity="danger" icon="pi pi-trash" size="small" disabled />
      );
    }
  };

  const accept = () => {
    mutateDeleteSafelisting(idToDelete);
  };

  const noConectionAlert = () => {
    toast.warn(
      "Action not allowed while you don't have internet connection",
      TOAST_CONFIG
    );
  };

  const reject = () => {
    toast.warn("You have rejected", TOAST_CONFIG);
  };

  const confirmDelete = (id) => {
    idToDelete = id;

    confirmDialog({
      key: 1,
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const onSelectionChange = (event) => {
    console.log(6666);
    const value = event.value;

    setSelectedItem(value);
    //setSelectAll(value.length === totalRecords);
  };

  const isSelectable = (data) => true;

  /*POR SI TENEMOS Q APLICAR EL PERMISO SHOW SOBRE CADA ITEM EN PARTICULAR
  const isRowSelectable = (event) =>{
    if(showPermission){
      if(event.data){
        isSelectable(event.data)
        return true
      }  
    } 
  }*/

  const isRowSelectable = (event) =>
    event.data ? isSelectable(event.data) : true;

  const onPage = (event) => {
    event.page = event.page + 1;
    setlazyState(event);
  };

  const onSort = (event) => {
    setlazyState(event);
  };

  const onFilter = (event) => {
    console.log(event);
    event["first"] = 0;
    setlazyState(event);
  };

  const openSidebarForm = (modalTitle, modalButton, id) => {
    setVisibleForm({
      visible: true,
      itemId: id,
      title: modalTitle,
    });
    setVisibleLoadingModal({
      visible: true,
      itemId: null,
      title: null,
    });
  };

  const openModal = (id) => {
    setVisibleModal({
      visible: true,
      itemId: id,
    });
  };

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <>
      <DataTable
        //scrollHeight="500px"
        className={"h-full hide-on-print"}
        resizableColumns
        columnResizeMode="expand"
        value={dars}
        lazy
        /*filterDisplay="row"*/ dataKey="safelisting_id"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        /*onRowSelect={(e) =>
          permissionsObject.permissionsObject.update
            ? connStatus
              ? openSidebarForm(
                  "Update Incident Report",
                  "Update",
                  e.data.safelisting_id
                )
              : noConectionAlert()
            : null
        }*/
        onRowSelect={(e) =>
          connStatus ? openModal(e.data.safelisting_id) : noConectionAlert()
        }
        selectionMode="single"
        metaKeySelection={false}
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onFilter={onFilter}
        filters={lazyState.filters}
        loading={loading}
        tableStyle={{ minWidth: "50rem" }}
        selection={selectedItem}
        isDataSelectable={isRowSelectable}
      >
        <Column
          field="safelisting_id"
          hidden
          header="ID"
          filterPlaceholder="Search"
        />

        <Column
          field="status"
          header="Status"
          body={statusBodyTemplate}
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header="License Plate"
          field="license_plate"
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="start_date"
          header="Start Date"
          body={startDateBodyTemplate}
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="end_date"
          header="End Date"
          body={endDateBodyTemplate}
          filterPlaceholder="Search"
          bodyClassName=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="duration"
          header="Duration"
          body={durationDaysBodyTemplate}
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header="Household"
          field="household_name"
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="created_at"
          dataType="date"
          header="Created At"
          body={createdDateBodyTemplate}
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="officer_name"
          header="Created By"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="admin_override"
          header="Admin Override"
          body={adminOverrideBodyTemplate}
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column header="" body={editBodyTemplate} className="w-1rem" />
        <Column header="" body={deleteBodyTemplate} className="w-1rem" />
      </DataTable>

      <ShowModal />
    </>
  );
};

export default Datatable;
