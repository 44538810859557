import { FloatLabel } from "primereact/floatlabel";
import { useProperty } from "../../context/PropertyProvider";
import {
  useBriefingBoard,
  useCreateBriefingBoard,
  useUpdateBriefingBoard,
} from "../../hooks/briefingboards";
import Loader from "../loader";
import { Button } from "primereact/button";
import { Controller, useForm } from "react-hook-form";
import { Sidebar } from "primereact/sidebar";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect } from "react";

function Header({ isSubmitting, briefingBoardId }) {
  const action = briefingBoardId ? "Updat" : "Creat";
  return (
    <div className="w-full flex align-items-center justify-content-between">
      <h2>{`${briefingBoardId ? "Update" : "New"} Briefing Board`}</h2>
      <div>
        <Button
          type="submit"
          form="briefing-board-form"
          disabled={isSubmitting}
        >
          {action}
          {isSubmitting ? "ing..." : "e"}
        </Button>
      </div>
    </div>
  );
}

function getInitialValues(propertyId, briefingBoard) {
  return {
    property_id: propertyId,
    brief: briefingBoard?.brief ?? "",
  };
}

export default function Sheet({ isVisible, briefingBoardId, onClose }) {
  const [propertyId] = useProperty();
  const { briefingBoard, isPending } = useBriefingBoard(briefingBoardId);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting: _isSubmitting },
  } = useForm({
    values: getInitialValues(propertyId, briefingBoard),
  });

  const {
    mutate: create,
    isSuccess: isCreateSuccess,
    isPending: isCreating,
  } = useCreateBriefingBoard({
    onSuccess: onClose,
  });
  const {
    mutate: update,
    isSuccess: isUpdateSuccess,
    isPending: isUpdating,
  } = useUpdateBriefingBoard(briefingBoardId);

  const isSubmitting = _isSubmitting || isCreating || isUpdating;

  const onSubmit = (data) => {
    const briefingBoard = {
      ...data,
    };

    if (briefingBoardId) {
      briefingBoard.briefing_board_id = briefingBoardId;
      update(briefingBoard);
    } else {
      create(briefingBoard);
    }
  };

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      onClose();
    }
  }, [isCreateSuccess, isUpdateSuccess, onClose]);

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      header={() => (
        <Header isSubmitting={isSubmitting} briefingBoardId={briefingBoardId} />
      )}
      onHide={onClose}
      className="w-full md:w-6 lg:w-5"
    >
      {isPending ? (
        <Loader />
      ) : (
        <form
          id="briefing-board-form"
          className="p-fluid pt-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="field">
            <FloatLabel>
              <Controller
                name="brief"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <InputTextarea id="brief" autoResize rows={5} {...field} />
                )}
              />
              <label htmlFor="brief">Brief</label>
            </FloatLabel>
            {errors.brief && (
              <small className="text-red-500">Brief is required</small>
            )}
          </div>
        </form>
      )}
    </Sidebar>
  );
}
