import { useForm } from "react-hook-form";
import { useProperty } from "../../context/PropertyProvider";
import { useCitation, useUpdateCitation } from "../../hooks/citationQueries";
import { useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { readFiles, resizeImgs } from "../../utils";
import Loader from "../loader";
import { Button } from "primereact/button";
import { CitationPrintable } from "./CitationPrintable";
import { Image } from "primereact/image";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";

const MAX_FILES = 5;

export default function ProofSheet({ isVisible, citationId, onClose }) {
  const filesRef = useRef();
  const [property] = useProperty();
  const [imagesUrls, setImagesUrls] = useState([]);
  const [isPrintActive, setIsPrintActive] = useState(false);
  const { mutateAsync, isPending: isUpdating, isSuccess } = useUpdateCitation();
  const { isPending, data: fetchedCitation } = useCitation(
    citationId,
    property
  );
  const citation = !isPending ? fetchedCitation.data.data : null;
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    values: {
      images: citation?.images ?? [],
    },
  });
  const images = watch("images");

  useEffect(() => {
    if (citation) {
      const images = citation.media.images;

      setImagesUrls(images ?? []);
    }
  }, [citation]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Proof added", TOAST_CONFIG);

      onClose();
    }
  }, [isSuccess]);

  const onSubmit = async (data) => {
    let base64Images = [];
    const toResize = images.filter((i) => i instanceof File);

    if (toResize.length) {
      const resized = await resizeImgs(toResize);
      base64Images = await readFiles(resized);

      base64Images = base64Images.map((b) => b.base64);
    }

    const citationData = {
      ...citation,
      images: base64Images,
    };

    mutateAsync(citationData);
  };

  const handleUploadFiles = async (e) => {
    const files = Array.from(e.target.files);
    if (files >= MAX_FILES) return;

    setValue("images", [...images, ...files]);

    const urls = files.map((f) => {
      return URL.createObjectURL(f);
    });
    setImagesUrls([...imagesUrls, ...urls]);

    e.target.value = "";
  };

  const handleSelectFiles = () => {
    filesRef.current.click();
  };

  const removeFiles = async (i) => {
    images.splice(i, 1);
    imagesUrls.splice(i, 1);

    setValue("images", images);
    setImagesUrls(imagesUrls);
  };

  const handlePrint = () => {
    setIsPrintActive(true);
  };

  return (
    <>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={onClose}
        header={() => (
          <div className="w-full flex align-items-center justify-content-between">
            <h2>Citation Proof</h2>
            <div>
              <Button type="submit" form="proof-form" disabled={isUpdating}>
                Save
              </Button>
            </div>
          </div>
        )}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {isPending ? (
          <Loader />
        ) : (
          <div className="flex flex-column gap-4">
            <Button
              className="w-full flex justify-content-center"
              disabled={isUpdating}
              onClick={handlePrint}
            >
              <span className="mr-2">Print</span> <i className="pi pi-print" />
            </Button>
            <form id="proof-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="border-1 surface-border border-round p-3 ">
                <div>
                  <FloatLabel>
                    <label
                      htmlFor="images"
                      className="px-1"
                      style={{
                        top: "-1rem",
                        left: "-.5rem",
                        fontSize: "12px",
                        backgroundColor: "#fff",
                      }}
                    >
                      Add proof to the citation
                    </label>
                  </FloatLabel>
                </div>
                <div className="field">
                  <input
                    id="images"
                    name="images"
                    hidden
                    multiple
                    type="file"
                    ref={filesRef}
                    onChange={handleUploadFiles}
                  />
                  <Button
                    type="button"
                    className="w-full justify-content-center"
                    disabled={isUpdating}
                    onClick={handleSelectFiles}
                  >
                    Select images
                  </Button>

                  <small className="block pt-1">
                    {errors.images && (
                      <div className="text-red-500">
                        {errors.images.message}
                      </div>
                    )}
                  </small>
                </div>
                {imagesUrls?.length > 0 ? (
                  <div className="field">
                    <ul className="m-0 p-0 list-none flex flex-column gap-1 w-full ">
                      {imagesUrls.map((image, i) => (
                        <li
                          key={image}
                          className={`hover:surface-hover border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between border-primary'}`}
                        >
                          <div className="flex align-items-center gap-1">
                            <Image src={image} width="80" height="80" />
                            <Button
                              icon="pi pi-trash"
                              onClick={() => removeFiles(i)}
                              severity="danger"
                              size="small"
                              disabled={isUpdating}
                              style={{ backgroundColor: "#d32f2f80" }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        )}
      </Sidebar>
      {isPrintActive ? (
        <CitationPrintable
          isVisible
          citationId={citationId}
          onClose={() => setIsPrintActive(false)}
        />
      ) : null}
    </>
  );
}
