import { useMutation } from "@tanstack/react-query";
import { markAsReadNotification } from "../../api/notifications";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";

export default function useMarkReadNotification() {
  const {
    mutate: markAsRead,
    isPending,
    isSuccess,
    reset,
  } = useMutation({
    mutationFn: markAsReadNotification,
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Notification marked as read", TOAST_CONFIG);
    }
  }, [isSuccess]);

  return { markAsRead, isPending, isSuccess, reset };
}
