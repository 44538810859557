import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import { Sidebar } from "primereact/sidebar";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";

export default function FiltersSheet({ isVisible, filters, onApply, onClose }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      details: filters.details,
      date_from: filters.date_from
        ? new Date(`${filters.date_from}T00:00:00`)
        : null,
      date_to: filters.date_to ? new Date(`${filters.date_to}T00:00:00`) : null,
    },
  });

  const onSubmit = (data) => {
    const filters = {};

    if (data.details) filters.details = data.details;
    if (data.date_from)
      filters.date_from = data.date_from.toISOString().slice(0, 10);
    if (data.date_to) filters.date_to = data.date_to.toISOString().slice(0, 10);

    onApply(filters);
  };

  const customHeader = (
    <div className="flex align-items-center justify-content-between w-full">
      <h2>Filters</h2>
      <Button form="filters-form" type="submit">
        Apply
      </Button>
    </div>
  );

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      header={customHeader}
      onHide={onClose}
      className="w-full md:w-6 lg:w-5"
    >
      <form id="filters-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-2 mb-2 pt-2">
          <div style={{ flexBasis: "50%" }}>
            <FloatLabel>
              <Controller
                name="date_from"
                control={control}
                rules={{
                  validate: (value, formData) => {
                    if (!value && formData.date_to) {
                      return "You must select a date";
                    }

                    return null;
                  },
                }}
                render={({ field }) => (
                  <Calendar
                    inputId="date_from"
                    dateFormat="mm/dd/yy"
                    className="w-full"
                    showButtonBar
                    maxDate={new Date()}
                    // value={new Date()}
                    {...field}
                  />
                )}
              />
              <label htmlFor="date_from">Date from</label>
            </FloatLabel>
          </div>
          <div style={{ flexBasis: "50%" }}>
            <FloatLabel>
              <Controller
                name="date_to"
                control={control}
                rules={{
                  validate: (value, formData) => {
                    const from = formData.date_from
                      ? formData.date_from.getTime()
                      : null;

                    const to = value ? value.getTime() : null;
                    if (!to && from) {
                      return "You must select a date";
                    } else if (from && to && from > to) {
                      return "'Date to' must be after 'Date from'";
                    }

                    return null;
                  },
                }}
                render={({ field }) => (
                  <Calendar
                    id="date_to"
                    dateFormat="mm/dd/yy"
                    className={classNames(
                      {
                        "p-invalid": Boolean(errors.date_to),
                      },
                      "w-full"
                    )}
                    showButtonBar
                    maxDate={new Date()}
                    {...field}
                  />
                )}
              />
              <label htmlFor="date_to">Date To</label>
            </FloatLabel>
            <small className="block pt-1">
              {errors.date_to && (
                <div className="text-red-500">{errors.date_to.message}</div>
              )}
            </small>
          </div>
        </div>
        <div className="field">
          <FloatLabel>
            <InputTextarea
              autoFocus
              maxLength={100}
              rows={2}
              cols={30}
              className={classNames(
                {
                  "p-invalid": Boolean(errors.details),
                },
                "w-full"
              )}
              style={{ resize: "none" }}
              {...register("details")}
            />

            <label htmlFor="details">Details</label>
          </FloatLabel>
          <small className="block pt-1">
            {errors.details && (
              <div className="text-red-500">{errors.details.message}</div>
            )}
          </small>
        </div>
      </form>
    </Sidebar>
  );
}
