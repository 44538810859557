import axiosInstance from "./axios";

export async function fetchBriefingBoards(rows, page, property_id) {
  const params = new URLSearchParams();

  params.append("page", page);
  params.append("context_property_id", property_id);

  // if (user_name) params.append("user_name", user_name);
  // if (details) params.append("details", details);
  // if (from) params.append("date_from", from);
  // if (to) params.append("date_to", to);

  let url = `briefingboard/list/${rows}/briefing_board_id/desc?${params.toString()}`;

  return await axiosInstance.get(url);
}

export async function fetchBriefingBoard(id) {
  let url = `briefingboard/show?briefing_board_id=${id}`;
  return await axiosInstance.get(url);
}

export function createBriefingBoard(data) {
  let url = `briefingboard/store`;
  return axiosInstance.post(url, data);
}

export function updateBriefingBoard(data) {
  let url = `briefingboard/update`;
  return axiosInstance.post(url, data);
}

export function deleteBriefingBoard(id) {
  let url = `briefingboard/delete`;
  return axiosInstance.delete(url, { data: { briefing_board_id: id } });
}
