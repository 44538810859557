import "./App.css";
import { useEffect, useRef, useState } from "react";

import RequireAuth from "./components/require_auth";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Layout from "./layouts/layout";
import Dar from "./components/dars/dar";
import Households from "./components/households/households";
import BriefingBoards from "./components/briefing_boards";
import IncidentReports from "./components/incident_reports/incident-reports";
import Citations from "./components/citations/citations";
import VehicleProfile from "./components/vehicle_profile/VehicleProfile";
import PoolAccessLogs from "./components/pool_access_logs/PoolAccessLogs";
import DispatchLogs from "./components/dispatch_logs/DispatchLogs";
import Safelistings from "./components/safelistings/Safelistings";
import Vehicles from "./components/vehicles/Vehicles";
import VehicleMaintenance from "./components/vehicle_maintenance/VehicleMaintenance";
import Properties from "./components/properties/properties";
import SelectProperty from "./components/select_property";

import Users from "./components/users/users";
import Login from "./components/login/Login";
import Forgot from "./components/forgot/forgot";
import Reset from "./components/reset/reset";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";

import { ToastContainer, toast } from "react-toastify";

import axios from "./api/axios";
import VisibleFormContext, { variable } from "./context/VisibleContext";

import Loading from "./components/Loading";

import { useLocalState } from "./hooks/useLocalState";
import Dashboards from "./components/dashboard/Dashboards";
import { useVersionChecker } from "./hooks/useVersionChecker";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import Offline from "./components/offline/offline";
import Acl from "./components/acl";

/*const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error?.request?.status === 401) {
        toast(`Please authenticate ${error?.request?.status}`);
      } else {
        toast(`resto ${error?.request?.status}`);
      }
    },
  }),
});
*/

export default () => <App />;

function App() {
  const toastRef = useRef();
  const [visibleForm, setVisibleForm] = useState(variable.visibleForm);
  const [visibleModal, setVisibleModal] = useState(variable.visibleModal);
  const [visibleLoadingModal, setVisibleLoadingModal] = useState(
    variable.visibleLoadingModal
  );
  const [allowedOfflineAction, setAllowedOfflineAction] = useState(
    variable.allowedOfflineAction
  );
  const [visibleCitation, setVisibleCitation] = useState(
    variable.visibleCitation
  );

  const { isNewVersionAvailable, reloadPage } = useVersionChecker();

  useEffect(() => {
    if (isNewVersionAvailable) {
      toastRef.current.show({
        severity: "info",
        summary: "New app version detected",
        detail: "New features available, please refresh the app.",
        sticky: true,
        content: (props) => (
          <div
            className="flex flex-column align-items-left"
            style={{ flex: "1" }}
          >
            <div className="flex align-items-center gap-2">
              <span className="font-bold text-900">
                {props.message.summary}
              </span>
            </div>
            <div className="font-medium text-lg my-3 text-900">
              {props.message.detail}
            </div>
            <Button
              className="p-button-sm flex"
              label="Refresh now"
              severity="info"
              onClick={reloadPage}
            />
          </div>
        ),
      });
    }
  }, [isNewVersionAvailable]);

  return (
    <VisibleFormContext.Provider
      value={{
        VisibleFormContext: [visibleForm, setVisibleForm],
        visibleLoadingModalContext: [
          visibleLoadingModal,
          setVisibleLoadingModal,
        ],
        visibleModalContext: [visibleModal, setVisibleModal],
        allowedOfflineActionContext: [
          allowedOfflineAction,
          setAllowedOfflineAction,
        ],
        visibleCitationContext: [visibleCitation, setVisibleCitation],
      }}
    >
      <div className="h-full">
        <ToastContainer />
        <Toast ref={toastRef} position="bottom-left" />

        <Routes>
          {/*PUBLIC ROUTES*/}
          <Route path="login" element={<Login />} />

          <Route path="forgot" element={<Forgot />} />

          <Route path="reset" element={<Reset />} />

          <Route path="/" element={<Navigate to="login" />} />

          {/*PRIVATE ROUTES*/}
          <Route element={<RequireAuth />}>
            <Route path="select-property" element={<SelectProperty />} />
            <Route path="loading" element={<Loading />} />

            <Route path="/" element={<Layout />}>
              <Route path="unauthorized" element={<Unauthorized />} />

              <Route element={<Acl permissions={["dar list"]} />}>
                <Route path="dashboard" element={<Dashboards />} />
              </Route>

              <Route element={<Acl permissions={["briefingboard list"]} />}>
                <Route path="briefing" element={<BriefingBoards />} />
              </Route>

              <Route element={<Acl permissions={["dar list"]} />}>
                <Route path="dar" element={<Dar />} />
              </Route>

              <Route element={<Acl permissions={["household list"]} />}>
                <Route path="households" element={<Households />} />
              </Route>

              <Route element={<Acl permissions={["incidentreport list"]} />}>
                <Route path="incidents" element={<IncidentReports />} />
              </Route>
              <Route element={<Acl permissions={["citation list"]} />}>
                <Route path="citations" element={<Citations />} />
              </Route>
              <Route element={<Acl permissions={["citation list"]} />}>
                <Route path="vehicleprofile" element={<VehicleProfile />} />
              </Route>
              <Route element={<Acl permissions={["poolaccesslog list"]} />}>
                <Route path="poolaccesslogs" element={<PoolAccessLogs />} />
              </Route>

              <Route element={<Acl permissions={["dispatchlog list"]} />}>
                <Route path="dispatchlogs" element={<DispatchLogs />} />
              </Route>

              <Route element={<Acl permissions={["user list"]} />}>
                <Route path="users" element={<Users />} />
              </Route>

              <Route element={<Acl permissions={["safelisting list"]} />}>
                <Route path="safelistings" element={<Safelistings />} />
              </Route>

              <Route element={<Acl permissions={["vehicle list"]} />}>
                <Route path="vehicles" element={<Vehicles />} />
              </Route>

              <Route
                element={<Acl permissions={["vehiclemaintenance list"]} />}
              >
                <Route
                  path="vehiclemaintenance"
                  element={<VehicleMaintenance />}
                />
              </Route>

              <Route element={<Acl permissions={["property list"]} />}>
                <Route path="properties" element={<Properties />} />
              </Route>

              <Route
                element={
                  <Acl
                    permissions={[
                      "dispatchlog create",
                      "dar create",
                      "incidentreport create",
                      "citation create",
                      "poolaccesslog create",
                    ]}
                  />
                }
              >
                <Route path="offline" element={<Offline />} />
              </Route>

              {/*CATCH ALL*/}
              <Route path="*" element={<Missing />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </VisibleFormContext.Provider>
  );
}
