import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchUser,
  fetchUsers,
  fetchRoles,
  addUser,
  updateUser,
  deleteUser,
  updateUserStatus,
  fetchPropertyManagers,
  fetchUserByCriteria,
} from "../api/userApi";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";
import { useEffect } from "react";

export function useRoles(permissionCreate) {
  return useQuery({
    queryKey: ["roles"],
    queryFn: () => fetchRoles(),
    retry: false,
    enabled: permissionCreate,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
  });
}

export function usePropertyManagers() {
  return useQuery({
    queryKey: ["property_managers"],
    queryFn: () => fetchPropertyManagers(),
  });
}

export function useUsers(rows, page, propertyId) {
  return useQuery({
    queryKey: ["users", { rows, page, propertyId }],
    queryFn: () => fetchUsers(rows, page, propertyId),
    retry: false,
    //staleTime: 10,
    refetchInterval: 400000,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
    onError: (error) => {
      console.log("ALL BAD");
      console.error(error.message);
    },
  });
}

export function useUser(userId) {
  const {
    data,
    isPending: _isPending,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: ["user", { userId }],
    queryFn: () => fetchUser(userId),
    enabled: Boolean(userId),
    retry: false,
  });
  const user = !_isPending && userId && isSuccess ? data.data.data : null;

  useEffect(() => {
    if (isError) {
      toast.error("Error fetching user", TOAST_CONFIG);
    }
  }, [isError]);

  return { user, isPending: userId && _isPending, isError };
}

export const useInsertUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addUser,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["user"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.success("User created successfully", TOAST_CONFIG);
    }
  }, [mutation]);

  return mutation;
};

export function useUpdateUser() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updateUser,
    //networkMode: offlineFirst,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["user"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured");
    },
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.success("User updated successfully", TOAST_CONFIG);
    }
  }, [mutation]);

  return mutation;
}

export function useDeleteUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUser,

    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["users"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
  });
}

export function useUpdateUserStatus() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUserStatus,

    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["users"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
  });
}

export function useUserByCriteria({
  q,
  propertyId,
  onlyResidents = true,
} = {}) {
  return useQuery({
    queryKey: ["users", { q, onlyResidents, propertyId }],
    queryFn: () => fetchUserByCriteria(q, propertyId, onlyResidents),
    retry: false,
    // enabled: q.length >= 3,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
  });
}
