import { useQueryClient } from "@tanstack/react-query";

export default function useInvalidate(queryKey) {
  const queryClient = useQueryClient();

  const handleInvalidate = () => {
    queryClient.invalidateQueries({
      queryKey,
      refetchActive: true,
      refetchInactive: false,
    });
  };

  return handleInvalidate;
}
