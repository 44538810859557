import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { useConnStatus } from "../../context/network";
import { useProperty } from "../../context/PropertyProvider";
import {
  useDeleteHousehold,
  useHouseholds,
} from "../../hooks/houseHoldQueries";
import usePagination from "../../hooks/usePagination";

export default function Datatable({ permissionsObject, onShowDetail, onEdit }) {
  const [property] = useProperty();
  const { connStatus } = useConnStatus();

  const { page, rows, handlePageChange } = usePagination();

  const { households, totalRecords, from, to, isPending, isError } =
    useHouseholds(rows, page, property);
  const { mutateAsync: mutateDeleteHousehold } = useDeleteHousehold();

  const editBodyTemplate = (household) => {
    if (permissionsObject.update) {
      return (
        <Button
          severity="info"
          icon="pi pi-pencil"
          size="small"
          disabled={!connStatus}
          onClick={() => onEdit(household.house_hold_id)}
        />
      );
    }
  };

  const deleteBodyTemplate = (household) => {
    if (permissionsObject.delete) {
      return (
        <>
          <Button
            icon="pi pi-trash"
            disabled={!connStatus}
            onClick={() =>
              confirmDialog({
                key: 1,
                message: "Do you want to delete this household?",
                header: "Delete Confirmation",
                icon: "pi pi-info-circle",
                defaultFocus: "reject",
                acceptClassName: "p-button-danger",
                accept: () => mutateDeleteHousehold(household.house_hold_id),
              })
            }
            severity="danger"
            size="small"
          />
        </>
      );
    }
  };

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <>
      <DataTable
        className={"h-fit hide-on-print"}
        resizableColumns
        columnResizeMode="expand"
        value={households}
        lazy
        dataKey="house_hold_id_format"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        metaKeySelection={false}
        rows={rows}
        totalRecords={totalRecords}
        onPage={handlePageChange}
        rowsPerPageOptions={[5, 10, 25, 50]}
        loading={isPending}
        emptyMessage="No data available"
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          field="name"
          header="Name"
          // className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          // field="created_at"
          header="Resident"
          // className=" w-8rem md:w-12rem lg:w-14rem "
          body={(h) => (h.user_id ? `${h.first_name} ${h.last_name}` : "-")}
          style={{ whiteSpace: "unset" }}
        />

        <Column
          // field="user_name"
          header="Address"
          filterPlaceholder="Search"
          // className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
          body={(h) => `${h.number} ${h.street}`}
        />

        <Column
          header="Property"
          // hidden
          body={(h) => h.property_name}
          // filterPlaceholder="Search"
        />

        <Column header="" body={editBodyTemplate} className="w-1rem" />
        <Column header="" body={deleteBodyTemplate} className="w-1rem" />
      </DataTable>
    </>
  );
}
