import { useQuery } from "@tanstack/react-query";
import { fetchBriefingBoards } from "../../api/briefing-board";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { useEffect } from "react";
import { useProperty } from "../../context/PropertyProvider";

export default function useBriefingBoards({ rows, page }) {
  const [propertyId] = useProperty();
  const {
    isLoading,
    isError: isErrorfetchedBriefingBoards,
    data: fetchedBriefingBoards,
    error,
  } = useQuery({
    queryKey: ["briefingboards", { rows, page, propertyId }],
    queryFn: () => fetchBriefingBoards(rows, page, propertyId),
    retry: false,
    networkMode: "offlineFirst",
    onSuccess: (data) => {
      return data;
    },
    throwOnError: (error) => {
      console.log("ALL BAD");
    },
  });

  const briefingBoards = fetchedBriefingBoards?.data.briefing_boards.data ?? [];
  const totalRecords = fetchedBriefingBoards?.data.briefing_boards.total ?? 0;
  const from = fetchedBriefingBoards?.data.briefing_boards.from ?? 0;
  const to = fetchedBriefingBoards?.data.briefing_boards.to ?? 0;

  useEffect(() => {
    if (isErrorfetchedBriefingBoards) {
      toast.error(error.code, TOAST_CONFIG);
    }
  }, [isErrorfetchedBriefingBoards]);

  return {
    briefingBoards,
    totalRecords,
    from,
    to,
    isLoading,
  };
}
