import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";

import { useLocalState } from "../../hooks/useLocalState";
import { useConnStatus } from "../../context/network";

import { useUpdateEffect } from "primereact/hooks";

import { ShowModal } from "./ShowModal";

import {
  usePoolAccessLogs,
  usePoolAccessLog,
  useDeletePoolAccessLog,
} from "../../hooks/poolAccessLogQueries";

import VisibleContext from "../../context/VisibleContext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { toast } from "react-toastify";

import { TOAST_CONFIG } from "../../utils/Constansts";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import secureLocalStorage from "react-secure-storage";
import { useProperty } from "../../context/PropertyProvider";

const Datatable = (permissionsObject) => {
  const navigate = useNavigate();
  const galleria = useRef(null);
  const { connStatus } = useConnStatus();

  const {
    VisibleFormContext,
    visibleLoadingModalContext,
    visibleModalContext,
    allowedOfflineActionContext,
  } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;
  const [visibleModal, setVisibleModal] = visibleModalContext;

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [dars, setPoolAccessLogs] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [property] = useProperty();

  let idToDelete = 0;

  let [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "pool_access_log_id",
    sortOrder: 0,
    filters: {
      daily_activity_report_id_format: { value: "", matchMode: "contains" },
      details: { details: "", matchMode: "contains" },
    },
  });

  const {
    isPending,
    isSuccess,
    isError: isErrorfetchedPoolAccessLogs,
    data: fetchedPoolAccessLogs,
    error,
    refetch,
  } = usePoolAccessLogs(lazyState.rows, lazyState.page, property);

  const {
    mutate: mutateDeletePoolAccessLog,
    data: deletedPoolAccessLog,
    error: errorDeletePoolAccessLog,
    isPending: isPendingDeletePoolAccessLog,
    isSuccess: isSuccessDeletePoolAccessLog,
    p,
  } = useDeletePoolAccessLog();

  const {
    isPending: isPendingPoolAccessLog,
    isSuccess: isSuccessPoolAccessLog,
    isError: isErrorPoolAccessLog,
    data: fetchedPoolAccessLog,
    errorPoolAccessLog,
  } = usePoolAccessLog(visibleModal.itemId);

  useUpdateEffect(() => {
    if (isSuccess) {
      setPoolAccessLogs(fetchedPoolAccessLogs?.data.pool_access_log.data);
      setTotalRecords(fetchedPoolAccessLogs?.data.pool_access_log.total);
      setFrom(fetchedPoolAccessLogs?.data.pool_access_log.from);
      setTo(fetchedPoolAccessLogs?.data.pool_access_log.to);

      setLoading(false);
    }
    if (isPending) {
      setLoading(true);
    }

    if (isErrorfetchedPoolAccessLogs) {
      if (error?.response.status == 401) {
        toast.error(`You need to authenticate`, TOAST_CONFIG);

        navigate("/login");
      }
    }
  }, [fetchedPoolAccessLogs, isErrorfetchedPoolAccessLogs]);

  useUpdateEffect(() => {
    if (isSuccessDeletePoolAccessLog) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have delete Dispatch Log`, TOAST_CONFIG);
    }
    if (isPendingDeletePoolAccessLog) {
      setLoading(true);
      //console.log(isPendingDeletePoolAccessLog);
    }
    if (errorDeletePoolAccessLog) {
      console.log(errorDeletePoolAccessLog);
    }
  }, [deletedPoolAccessLog]);

  useEffect(() => {
    setlazyState((old) => ({
      ...old,
      first: 0,
      page: 1,
      rows: 10,
    }));
  }, [property]);

  const dispatchDateBodyTemplate = (poolAccessLog) => {
    return moment(utcToLocale(poolAccessLog.pool_access_date)).format(
      "yyyy-MM-DD HH:mm"
    );
  };

  const createdDateBodyTemplate = (poolAccessLog) => {
    return moment(utcToLocale(poolAccessLog.created_at)).format(
      "yyyy-MM-DD HH:mm"
    );
  };

  const darDateDiffBodyTemplate = (poolAccessLog) => {
    const firstDate = new Date(poolAccessLog.pool_access_date);
    const secondDate = new Date(poolAccessLog.created_at);

    const firstDateInMs = firstDate.getTime();
    const secondDateInMs = secondDate.getTime();

    const differenceBtwDates = secondDateInMs - firstDateInMs;

    const aDayInMs = 24 * 60 * 60 * 1000;

    const daysDiff = Math.round(differenceBtwDates / aDayInMs);
    //console.log(daysDiff)

    return `${daysDiff} days`;
  };

  const detailsTemplate = (poolAccessLog) => {
    let details = poolAccessLog.details;

    if (poolAccessLog.details.length > 100) {
      details = poolAccessLog.details.substr(0, 100) + "...";
    }
    return details;
  };

  const typeBodyTemplate = (poolAccessLog) => {
    if (poolAccessLog.code_type === "Red") {
      return (
        <Tag
          severity="danger"
          className=" w-1rem  "
          text="white"
          style={{
            padding: "0.4rem",
            backgroundColor: "#d32f2fd1",
            textAlign: "center",
          }}
        ></Tag>
      );
    } else if (poolAccessLog.code_type === "Yellow") {
      return (
        <Tag
          severity="warning"
          className=" w-1rem  "
          text="black"
          style={{
            padding: "0.4rem",
            backgroundColor: "#fbc02dd1",
            textAlign: "center",
          }}
        ></Tag>
      );
    } else
      return (
        <Tag
          severity="success"
          className=" w-1rem  "
          text="white"
          style={{
            padding: "0.4rem",
            backgroundColor: "#689f38d1",
            textAlign: "center",
          }}
        ></Tag>
      );
  };

  const mapBodyTemplate = (poolAccessLog) => {
    return (
      <>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${poolAccessLog.gps_coordinates}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="pi pi-map-marker" rounded link text />
        </a>
      </>
    );
  };

  const showBodyTemplate = (poolAccessLog) => {
    return (
      <Button
        severity="secondary"
        icon="pi pi-eye"
        size="small"
        onClick={() =>
          connStatus
            ? openModal(poolAccessLog.pool_access_log_id)
            : noConectionAlert()
        }
        style={{ backgroundColor: "#64748b" }}
      />
    );
  };

  const editBodyTemplate = (poolAccessLog) => {
    if (permissionsObject.permissionsObject.update) {
      return (
        <Button
          severity="info"
          icon="pi pi-pencil"
          size="small"
          onClick={() =>
            connStatus
              ? openSidebarForm(
                  "Update DAR",
                  "Update",
                  poolAccessLog.pool_access_log_id
                )
              : noConectionAlert()
          }
        />
      );
    }
  };

  const deleteBodyTemplate = (poolAccessLog) => {
    if (permissionsObject.permissionsObject.delete) {
      //console.log(`allowedOfflineAction 'false' ${connStatus.isOnline}`)
      return (
        <>
          <Button
            icon="pi pi-trash"
            onClick={() =>
              connStatus
                ? confirmDelete(poolAccessLog.pool_access_log_id)
                : noConectionAlert()
            }
            severity="danger"
            size="small"
          />
        </>
      );
    }
  };

  const accept = () => {
    mutateDeletePoolAccessLog(idToDelete);
  };

  const noConectionAlert = () => {
    toast.warn(
      "Action not allowed while you don't have internet connection",
      TOAST_CONFIG
    );
  };

  const reject = () => {
    toast.warn("You have rejected", TOAST_CONFIG);
  };

  const confirmDelete = (id) => {
    idToDelete = id;

    confirmDialog({
      key: 1,
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const onSelectionChange = (event) => {
    console.log(6666);
    const value = event.value;

    setSelectedItem(value);
    //setSelectAll(value.length === totalRecords);
  };

  const isSelectable = (data) => true;

  /*POR SI TENEMOS Q APLICAR EL PERMISO SHOW SOBRE CADA ITEM EN PARTICULAR
  const isRowSelectable = (event) =>{
    if(showPermission){
      if(event.data){
        isSelectable(event.data)
        return true
      }  
    } 
  }*/

  const isRowSelectable = (event) =>
    event.data ? isSelectable(event.data) : true;

  const onPage = (event) => {
    event.page = event.page + 1;
    setlazyState(event);
  };

  const onSort = (event) => {
    setlazyState(event);
  };

  const onFilter = (event) => {
    console.log(event);
    event["first"] = 0;
    setlazyState(event);
  };

  const openSidebarForm = (modalTitle, modalButton, id) => {
    setVisibleForm({
      visible: true,
      itemId: id,
      title: modalTitle,
    });
    setVisibleLoadingModal({
      visible: true,
      itemId: null,
      title: null,
    });
  };

  const openModal = (id) => {
    setVisibleModal({
      visible: true,
      itemId: id,
    });
  };

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <>
      <DataTable
        //scrollHeight="500px"
        className={"h-full hide-on-print"}
        resizableColumns
        columnResizeMode="expand"
        value={dars}
        lazy
        /*filterDisplay="row"*/ dataKey="pool_access_log_id_format"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        /*onRowSelect={(e) =>
          permissionsObject.permissionsObject.update
            ? connStatus
              ? openSidebarForm(
                  "Update Incident Report",
                  "Update",
                  e.data.pool_access_log_id
                )
              : noConectionAlert()
            : null
        }*/
        onRowSelect={(e) =>
          connStatus ? openModal(e.data.pool_access_log_id) : noConectionAlert()
        }
        selectionMode="single"
        metaKeySelection={false}
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onFilter={onFilter}
        filters={lazyState.filters}
        loading={loading}
        tableStyle={{ minWidth: "50rem" }}
        selection={selectedItem}
        isDataSelectable={isRowSelectable}
      >
        <Column
          field="daily_activity_report_id_format"
          hidden
          header="ID"
          filterPlaceholder="Search"
        />

        <Column
          field="pool_access_date"
          header="Dispatch Log Date"
          body={dispatchDateBodyTemplate}
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="officer_name"
          header="User"
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="property_pool"
          header="Property Pool"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="house_hold"
          header="House Hold"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="name"
          header="Name"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="key_fob_number"
          header="Key fob number"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="number_of_guests"
          header="# guests"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="details"
          header="Details"
          body={detailsTemplate}
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column header="" body={deleteBodyTemplate} className="w-1rem" />
      </DataTable>

      <ShowModal />
    </>
  );
};

export default Datatable;
