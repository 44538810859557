import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createBriefingBoard } from "../../api/briefing-board";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";

export default function useCreateBriefingBoard({ onSuccess }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: createBriefingBoard,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["briefingboards"],
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.success("Briefing board created successfully", TOAST_CONFIG);
      onSuccess && onSuccess();
    }
  }, [mutation.isSuccess, onSuccess]);

  return mutation;
}
