import { useConnStatus } from "../context/network";

export default function ConnectionBanner() {
  const { connStatus } = useConnStatus();

  return (
    !connStatus && (
      <div className="flex justify-content-center align-content-center min-h-2rem w-full bg-yellow-300 font-semibold p-2">
        <div className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-2" />
          <span>
            Connection lost. You can continue working offline with limited
            functionality.
          </span>
        </div>
      </div>
    )
  );
}
