import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";

import { useLocalState } from "../../hooks/useLocalState";
import { useConnStatus } from "../../context/network";

import { useUpdateEffect } from "primereact/hooks";

import { ShowModal } from "./ShowModal";

import {
  useVehicles,
  useVehicle,
  useDeleteVehicle,
} from "../../hooks/vehicleQueries";

import VisibleContext from "../../context/VisibleContext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { toast } from "react-toastify";

import { TOAST_CONFIG } from "../../utils/Constansts";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import secureLocalStorage from "react-secure-storage";

import MobileListView from "./MobileListView";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useProperty } from "../../context/PropertyProvider";

const Datatable = (permissionsObject) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const galleria = useRef(null);
  const { connStatus } = useConnStatus();

  const {
    VisibleFormContext,
    visibleLoadingModalContext,
    visibleModalContext,
    allowedOfflineActionContext,
  } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;
  const [visibleModal, setVisibleModal] = visibleModalContext;

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [dars, setVehicles] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [property] = useProperty();

  let idToDelete = 0;

  let [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "vehicle_id",
    sortOrder: 0,
    filters: {
      daily_activity_report_id_format: { value: "", matchMode: "contains" },
      details: { details: "", matchMode: "contains" },
    },
  });

  const {
    isPending,
    isSuccess,
    isError: isErrorfetchedVehicles,
    data: fetchedVehicles,
    error,
    refetch,
  } = useVehicles(lazyState.rows, lazyState.page, property);

  const {
    mutate: mutateDeleteVehicle,
    data: deletedVehicle,
    error: errorDeleteVehicle,
    isPending: isPendingDeleteVehicle,
    isSuccess: isSuccessDeleteVehicle,
    p,
  } = useDeleteVehicle();

  const {
    isPending: isPendingVehicle,
    isSuccess: isSuccessVehicle,
    isError: isErrorVehicle,
    data: fetchedVehicle,
    errorVehicle,
  } = useVehicle(visibleModal.itemId);

  useUpdateEffect(() => {
    if (isSuccess) {
      setVehicles(fetchedVehicles?.data.vehicle.data);
      setTotalRecords(fetchedVehicles?.data.vehicle.total);
      setFrom(fetchedVehicles?.data.vehicle.from);
      setTo(fetchedVehicles?.data.vehicle.to);

      setLoading(false);
    }
    if (isPending) {
      setLoading(true);
    }

    if (isErrorfetchedVehicles) {
      if (error?.response.status == 401) {
        toast.error(`You need to authenticate`, TOAST_CONFIG);

        navigate("/login");
      }
    }
  }, [fetchedVehicles, isErrorfetchedVehicles]);

  useUpdateEffect(() => {
    if (isSuccessDeleteVehicle) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have delete a Vehicle`, TOAST_CONFIG);
    }
    if (isPendingDeleteVehicle) {
      setLoading(true);
      //console.log(isPendingDeleteVehicle);
    }
    if (errorDeleteVehicle) {
      console.log(errorDeleteVehicle);
    }
  }, [deletedVehicle]);

  useEffect(() => {
    setlazyState((old) => ({
      ...old,
      first: 0,
      page: 1,
      rows: 10,
    }));
  }, [property]);

  const addressBodyTemplate = (vehicle) => {
    return vehicle.street != null
      ? vehicle.street + " " + vehicle.number
      : "N/A";
  };

  const createdDateBodyTemplate = (vehicle) => {
    return moment(utcToLocale(vehicle.created_at)).format("yyyy-MM-DD HH:mm");
  };

  const showBodyTemplate = (vehicle) => {
    return (
      <Button
        severity="secondary"
        icon="pi pi-eye"
        size="small"
        onClick={() =>
          connStatus ? openModal(vehicle.vehicle_id) : noConectionAlert()
        }
        style={{ backgroundColor: "#64748b" }}
      />
    );
  };

  const editBodyTemplate = (vehicle) => {
    if (
      vehicle.citations_count == null &&
      vehicle.safelistings_count == null &&
      permissionsObject.permissionsObject.update
    ) {
      return (
        <Button
          severity="info"
          icon="pi pi-pencil"
          size="small"
          onClick={() =>
            connStatus
              ? openSidebarForm("Update Vehicle", "Update", vehicle.vehicle_id)
              : noConectionAlert()
          }
        />
      );
    } else {
      return (
        <Button severity="info" icon="pi pi-pencil" size="small" disabled />
      );
    }
  };

  const deleteBodyTemplate = (vehicle) => {
    if (
      vehicle.citations_count == null &&
      vehicle.safelistings_count == null &&
      permissionsObject.permissionsObject.delete
    ) {
      return (
        <Button
          severity="danger"
          icon="pi pi-trash"
          size="small"
          onClick={() =>
            connStatus ? confirmDelete(vehicle.vehicle_id) : noConectionAlert()
          }
        />
      );
    } else {
      return (
        <Button severity="danger" icon="pi pi-trash" size="small" disabled />
      );
    }
  };

  const accept = () => {
    mutateDeleteVehicle(idToDelete);
  };

  const noConectionAlert = () => {
    toast.warn(
      "Action not allowed while you don't have internet connection",
      TOAST_CONFIG
    );
  };

  const reject = () => {
    toast.warn("You have rejected", TOAST_CONFIG);
  };

  const confirmDelete = (id) => {
    idToDelete = id;

    confirmDialog({
      key: 1,
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const onSelectionChange = (event) => {
    console.log(6666);
    const value = event.value;

    setSelectedItem(value);
    //setSelectAll(value.length === totalRecords);
  };

  const isSelectable = (data) => true;

  /*POR SI TENEMOS Q APLICAR EL PERMISO SHOW SOBRE CADA ITEM EN PARTICULAR
  const isRowSelectable = (event) =>{
    if(showPermission){
      if(event.data){
        isSelectable(event.data)
        return true
      }  
    } 
  }*/

  const isRowSelectable = (event) =>
    event.data ? isSelectable(event.data) : true;

  const onPage = (event) => {
    event.page = event.page + 1;
    setlazyState(event);
  };

  const onSort = (event) => {
    setlazyState(event);
  };

  const onFilter = (event) => {
    console.log(event);
    event["first"] = 0;
    setlazyState(event);
  };

  const openSidebarForm = (modalTitle, modalButton, id) => {
    setVisibleForm({
      visible: true,
      itemId: id,
      title: modalTitle,
    });
    setVisibleLoadingModal({
      visible: true,
      itemId: null,
      title: null,
    });
  };

  const openModal = (id) => {
    setVisibleModal({
      visible: true,
      itemId: id,
    });
  };

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <>
      <DataTable
        //scrollHeight="500px"
        className={"h-full hide-on-print"}
        resizableColumns
        columnResizeMode="expand"
        value={dars}
        lazy
        /*filterDisplay="row"*/ dataKey="vehicle_id"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        /*onRowSelect={(e) =>
          permissionsObject.permissionsObject.update
            ? connStatus
              ? openSidebarForm(
                  "Update Incident Report",
                  "Update",
                  e.data.vehicle_id
                )
              : noConectionAlert()
            : null
        }*/
        onRowSelect={(e) =>
          connStatus ? openModal(e.data.vehicle_id) : noConectionAlert()
        }
        selectionMode="single"
        metaKeySelection={false}
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onFilter={onFilter}
        filters={lazyState.filters}
        loading={loading}
        tableStyle={{ minWidth: "50rem" }}
        selection={selectedItem}
        isDataSelectable={isRowSelectable}
      >
        <Column
          field="vehicle_id"
          hidden
          header="ID"
          filterPlaceholder="Search"
        />

        <Column
          field="created_at"
          header="Create Date"
          body={createdDateBodyTemplate}
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="officer_name"
          header="Created By"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="household_name"
          header="Household"
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="license_plate"
          header="License Plate"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="make"
          header="Make"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="model"
          header="Model"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="color"
          header="Color"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="address"
          header="Address"
          //body={addressBodyTemplate}
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column header="" body={editBodyTemplate} className="w-1rem" />
        <Column header="" body={deleteBodyTemplate} className="w-1rem" />
      </DataTable>

      <ShowModal />
    </>
  );
};

export default Datatable;
