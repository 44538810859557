import { useMutation, useQueryClient } from "@tanstack/react-query";
import { bulkSync } from "../api/bulk";

export function useBulk() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: bulkSync,
    networkMode: "online",
    retry: false,
    throwOnError: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["dars"],
        refetchActive: true,
        refetchInactive: false,
      });
    },
  });
}
