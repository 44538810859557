import { fetchBriefingBoard } from "../../api/briefing-board";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { useEffect } from "react";

export default function useBriefingBoard(id) {
  const {
    data,
    isPending: _isPending,
    isError,
  } = useQuery({
    queryKey: ["briefingboard", id],
    queryFn: () => fetchBriefingBoard(id),
    enabled: Boolean(id),
    staleTime: 0,
  });

  useEffect(() => {
    if (isError) {
      toast.error("Error fetching briefing board", TOAST_CONFIG);
    }
  }, [isError]);

  const isPending = id && _isPending;
  const briefingBoard = data?.data.data;

  return { briefingBoard, isPending };
}
