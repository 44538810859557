import { useState, useEffect, useRef } from "react";
import { usePosition } from "../../context/position";

import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";

import { Sidebar } from "primereact/sidebar";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { MultiSelect } from "primereact/multiselect";

import { InputSwitch } from "primereact/inputswitch";

import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Image } from "primereact/image";

import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";

import {
  useCreateCitations,
  useCitation,
  useInsertCitation,
  useUpdateCitation,
} from "../../hooks/citationQueries";

import { useVehicleByPropertyId } from "../../hooks/vehicleQueries";

import { useProperty } from "../../context/PropertyProvider";
import Loader from "../loader";
import { readFiles, resizeImgs } from "../../utils";
import { useOffline } from "../../context/offline";

const getDefaultValues = (citation) => {
  return {
    citation_types:
      citation?.citation_types?.map((c) => c.citation_type_id) ?? [],
    details: citation?.details,
    citation_date: citation?.citation_date,
    send_notification: citation?.send_notification ?? 0,
    vehicle_id: citation?.vehicle?.vehicle_id,
    images: citation?.media?.images ?? [],
  };
};

const MAX_FILES = 13;

const SideBar = ({
  isVisible,
  citationId,
  citation: _citation,
  onCreate,
  onClose,
}) => {
  const filesRef = useRef();
  const [property] = useProperty();
  const [imagesUrls, setImagesUrls] = useState([]);
  const { position, getPosition } = usePosition();

  const { addItem, updateItem } = useOffline();

  const { isPending: _isPending, data: fetchedCitation } = useCitation(
    citationId,
    property
  );
  const citation = !_isPending && citationId ? fetchedCitation.data.data : null;

  const {
    isPending: isPendingVehicleByPropertyId,
    data: fetchedVehicleByPropertyId,
  } = useVehicleByPropertyId(property);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    values: getDefaultValues(citation ?? _citation?.data),
  });

  const vehicles = isPendingVehicleByPropertyId
    ? []
    : fetchedVehicleByPropertyId.data.vehicles;

  const isPending = (citationId && _isPending) || isPendingVehicleByPropertyId;

  const { isPending: isPendingCreateCitations, data: fetchedCreateCitations } =
    useCreateCitations(property);
  const vehicleCitationTypes = isPendingCreateCitations
    ? []
    : fetchedCreateCitations?.data.citation_types;

  const {
    mutateAsync: mutateInsertCitation,
    data: addedCitation,
    isSuccess: isCitationCreated,
  } = useInsertCitation();

  const { mutateAsync: mutateUpdateCitation, isSuccess: isCitationUpdated } =
    useUpdateCitation();

  const images = watch("images");

  useEffect(() => {
    if (citation || _citation) {
      const images = citation?.media?.images || _citation.data.images;

      setImagesUrls(images ?? []);
    }
  }, [citation, _citation]);

  useEffect(() => {
    // refresh position
    getPosition();
  }, []);

  useEffect(() => {
    if (isCitationCreated) {
      const citation = addedCitation?.data.data;
      const msg = `Citation created #${citation.citation_id}`;

      toast.success(msg, TOAST_CONFIG);

      onCreate(citation.citation_id);
    }
  }, [isCitationCreated, addedCitation]);

  useEffect(() => {
    if (isCitationUpdated) {
      toast.success(`Citation updated #${citationId}`, TOAST_CONFIG);

      onClose();
    }
  }, [isCitationUpdated]);

  const onSubmit = async (data) => {
    const { latitude, longitude } = position;

    let base64Images = [];
    const toResize = images.filter((i) => i instanceof File);

    if (toResize.length) {
      const resized = await resizeImgs(toResize);
      base64Images = await readFiles(resized);

      base64Images = base64Images.map((b) => b.base64);
    }

    try {
      const citationData = {
        citation_types: data.citation_types.map((c) => ({
          citation_type_id: c,
        })),
        citation_date: citationId
          ? citation.citation_date
          : new Date().toISOString(),
        details: data.details,
        vehicle_id: data.vehicle_id,
        gps_coordinates: `${latitude},${longitude}`,
        property_id: property,
        send_notification: data.send_notification,
        images: [
          ...base64Images,
          ...images.filter((i) => typeof i === "string"),
        ],
      };

      if (_citation) {
        await updateItem(_citation.id, citationData);
      } else if (citationId === null && navigator.onLine) {
        await mutateInsertCitation(citationData);
      } else if (!navigator.onLine) {
        await addItem("citation", citationData);
        const msg = `The device has no connection. The citation will be created once connection is recovered`;

        toast.success(msg, TOAST_CONFIG);
        onClose();
      } else {
        citationData["citation_id"] = citationId;
        await mutateUpdateCitation(citationData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadFiles = async (e) => {
    const files = Array.from(e.target.files);
    if (files >= MAX_FILES) return;

    setValue("images", [...images, ...files]);

    const urls = files.map((f) => {
      return URL.createObjectURL(f);
    });
    setImagesUrls([...imagesUrls, ...urls]);

    e.target.value = "";
  };

  const handleSelectFiles = () => {
    filesRef.current.click();
  };

  const removeFiles = async (i) => {
    images.splice(i, 1);
    imagesUrls.splice(i, 1);

    setValue("images", images);
    setImagesUrls(imagesUrls);
  };

  const vehicleId = watch("vehicle_id");
  const vehicle = vehicleId
    ? vehicles.find((v) => v.vehicle_id === vehicleId)
    : null;

  return (
    <>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={onClose}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {isPending ? (
          <Loader />
        ) : (
          <>
            <h2>{citationId ? "Update citation" : "Create citation"}</h2>
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="vehicle_id"
                    rules={{
                      validate: (value) =>
                        value !== undefined || "You must select a vehicle",
                      valueAsNumber: true,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        placeholder="Select a vehicle"
                        optionValue="vehicle_id"
                        optionLabel="label"
                        options={vehicles}
                        {...field}
                      />
                    )}
                  />
                  <label htmlFor="vehicle_id">Vehicle</label>
                </span>
                <small className="block pt-1">
                  {errors.vehicle_id && (
                    <div className="text-red-500">
                      {errors.vehicle_id.message}
                    </div>
                  )}
                </small>
              </div>
              {vehicleId ? (
                <>
                  <div className="field">
                    <div className="formgrid grid">
                      <div className="field col">
                        {vehicle?.safelistings?.length > 0 ? (
                          <Message
                            className="mb-2"
                            style={{ justifyContent: "left" }}
                            severity="success"
                            text="This plate has an available permit"
                          />
                        ) : null}

                        {vehicle?.citations?.length > 0 ? (
                          <Card
                            title={
                              "Citations in this period: " +
                              vehicle?.citations_count +
                              " / " +
                              vehicle?.citations_before_towed
                            }
                            style={{ background: "#0ea5e985" }}
                            severity="info"
                          >
                            {vehicle?.citations.map((citation) => (
                              <div
                                id={citation.citation_id}
                                key={`citation-${citation.citation_id}`}
                                className="m-0 pb-2"
                              >
                                <small>
                                  {citation.citation_date +
                                    "    " +
                                    (citation.citation_types ?? "-")}
                                </small>
                              </div>
                            ))}
                          </Card>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="citation_types"
                    rules={{
                      validate: (value) =>
                        value !== undefined ||
                        "You must select a Citation type",
                      valueAsNumber: true,
                    }}
                    className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                    render={({ field }) => (
                      <MultiSelect
                        id={field.name}
                        placeholder="Select a type"
                        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                        optionValue="citation_type_id"
                        optionLabel="name"
                        options={vehicleCitationTypes}
                        {...field}
                      />
                    )}
                  />
                  <label htmlFor="type">Type</label>
                </span>
                <small className="block pt-1">
                  {errors.citation_types && (
                    <div className="text-red-500">
                      {errors.citation_types.message}
                    </div>
                  )}
                </small>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="details"
                    control={control}
                    rules={{ required: "Details is required." }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
                        autoFocus
                        maxLength={500}
                        rows={5}
                        cols={30}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="details">
                    Details <small>(max 500 characters)</small>
                  </label>
                </span>
                <small className="block pt-1">
                  {errors.details && (
                    <div className="text-red-500">{errors.details.message}</div>
                  )}
                </small>
              </div>

              <div className="field">
                <label htmlFor="images">
                  <input
                    id="images"
                    name="images"
                    hidden
                    multiple
                    type="file"
                    ref={filesRef}
                    onChange={handleUploadFiles}
                  />
                  <Button type="button" onClick={handleSelectFiles}>
                    Select images
                  </Button>
                </label>

                <small className="block pt-1">
                  {errors.images && (
                    <div className="text-red-500">{errors.images.message}</div>
                  )}
                </small>
              </div>
              {imagesUrls?.length > 0 ? (
                <div className="field">
                  <ul className="m-0 p-0 list-none border-1 surface-border border-round p-2 flex flex-column gap-1 w-full ">
                    {imagesUrls.map((image, i) => (
                      <li
                        key={uuidv4()}
                        className={`hover:surface-hover border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between border-primary'}`}
                      >
                        <div className="flex align-items-center gap-1">
                          <Image src={image} width="80" height="80" />
                          <Button
                            icon="pi pi-trash"
                            onClick={() => removeFiles(i)}
                            severity="danger"
                            size="small"
                            style={{ backgroundColor: "#d32f2f80" }}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="send_notification"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <InputSwitch
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />

                  <label htmlFor="include_gps_coordinates" className="ml-2">
                    &nbsp; &nbsp; &nbsp; &nbsp; Send notification to prop.
                    manager
                  </label>
                </span>
              </div>

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default SideBar;
