import * as React from "react";

const NetworkContext = React.createContext();
NetworkContext.displayName = "NetworkContext";

const NetworkProvider = ({ children }) => {
  const [connStatus, setConnStatus] = React.useState(navigator.onLine);

  const handler = React.useCallback((e) => {
    const isOnline = navigator.onLine;

    setConnStatus(isOnline);
  }, []);

  React.useEffect(() => {
    window.addEventListener("online", handler);
    window.addEventListener("offline", handler);

    return () => {
      window.removeEventListener("online", handler);
      window.removeEventListener("offline", handler);
    };
  }, [handler]);

  return (
    <NetworkContext.Provider
      value={{
        connStatus,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

const useConnStatus = () => {
  const context = React.useContext(NetworkContext);
  if (context === undefined) {
    throw new Error("useStore must be use withing a NetworkProvider");
  }
  return context;
};

export { useConnStatus };
export default NetworkProvider;
