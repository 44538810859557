import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";

function DetailTemplate(item) {
  if (item.type === "dar") {
    return <p>{item.data.details}</p>;
  }

  return null;
}

const SEVERITY = {
  pending: "warning",
  success: "success",
  error: "danger",
  saving: "info",
};

function StatusTemplate({ item, isPending }) {
  let value = isPending ? "saving" : "pending";
  if (item.hasOwnProperty("status")) {
    value = item.status ? "success" : "error";
  }

  return (
    <Badge value={value} severity={SEVERITY[value]} className="capitalize" />
  );
}

function EditTemplate({ isPending, onEdit }) {
  return (
    <Button
      icon="pi pi-pencil"
      severity="info"
      size="small"
      disabled={isPending}
      onClick={onEdit}
    />
  );
}

function DeleteTemplate({ isPending, onDelete }) {
  return (
    <Button
      icon="pi pi-trash"
      severity="danger"
      size="small"
      disabled={isPending}
      onClick={onDelete}
    />
  );
}

function ErrorsTemplate(item) {
  return item.errors
    ? item.errors.map((e, i) => <p key={`item-${item.id}-error-${i}`}>{e}</p>)
    : "-";
}

export default function OfflineDatatable({
  items,
  isPending,
  onEdit,
  onDelete,
}) {
  return (
    <DataTable stripedRows scrollable size="small" value={items}>
      <Column field="type" header="Type" className="capitalize" />
      <Column header="Details" body={DetailTemplate} />
      <Column
        header="Status"
        body={(i) => <StatusTemplate item={i} isPending={isPending} />}
      />
      <Column header="Errors" body={ErrorsTemplate} />

      <Column
        header=""
        body={(item) => (
          <EditTemplate isPending={isPending} onEdit={() => onEdit(item)} />
        )}
        className="w-1rem"
      />
      <Column
        header=""
        body={(item) => (
          <DeleteTemplate
            isPending={isPending}
            onDelete={() => onDelete(item)}
          />
        )}
        className="w-1rem"
      />
    </DataTable>
  );
}
