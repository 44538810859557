import { readAndCompressImage } from "browser-image-resizer";

const toCamelCase = (str) =>
  str
    .split(/[ _]/g)
    .map((t, i) => {
      if (i === 0) {
        return t.toLowerCase();
      }
      const cap = t[0].toUpperCase();
      return cap + t.substring(1).toLowerCase();
    })
    .join("");

const toSnakeCase = (string) =>
  string
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .replace(/[\s_]+/g, "_")
    .toLowerCase();

const objToSnakeCase = (formData) =>
  Object.keys(formData).reduce((acc, key) => {
    const newKey = toSnakeCase(key);
    let value = formData[key];

    if (value && typeof value === "object" && !Array.isArray(value)) {
      value = objToSnakeCase(value);
    } else if (
      Array.isArray(value) &&
      value.some((e) => typeof e === "object" && !Array.isArray(e))
    ) {
      value = value.map(objToSnakeCase);
    }

    return {
      ...acc,
      [newKey]: value,
    };
  }, {});

const objToCamelCase = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    const newKey = toCamelCase(key);
    let value = obj[key];
    if (value && typeof value === "object" && !Array.isArray(value)) {
      value = objToCamelCase(value);
    } else if (
      Array.isArray(value) &&
      value.some((e) => typeof e === "object" && !Array.isArray(e))
    ) {
      value = value.map(objToCamelCase);
    }

    return {
      ...acc,
      [newKey]: value,
    };
  }, {});

const readFiles = (files) => {
  if (files.length === 0) {
    return Promise.resolve([]);
  }

  return new Promise((resolve) => {
    const base64Files = [];
    let currentFile = 0;
    const readFileData = (file) => {
      const reader = new FileReader();
      reader.onload = () => {
        base64Files.push({
          base64: reader.result,
          name: file.name,
          type: file.type,
        });
        currentFile += 1;

        if (currentFile === files.length) {
          resolve(base64Files);
        }
      };

      reader.readAsDataURL(file);
    };

    for (const file of files) {
      readFileData(file);
    }
  });
};

const ITEMS_PER_PAGE = 10;

const timeout = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

const config = {
  quality: 1,
  maxWidth: 1920,
  maxHeight: 1080,
};

const resizeImgs = async (imgs) => {
  const resized = [];

  for (let img of imgs) {
    const res = await readAndCompressImage(img, config);
    resized.push(res);
  }

  return resized;
};

const clearStore = () => {
  Object.keys(localStorage).forEach((key) => {
    localStorage.setItem(key, null);
  });
};

export {
  toCamelCase,
  toSnakeCase,
  objToSnakeCase,
  objToCamelCase,
  readFiles,
  ITEMS_PER_PAGE,
  timeout,
  resizeImgs,
  clearStore,
};
