import { DB_NAME, DB_VERSION } from "./Constansts";

export async function openDb() {
  return new Promise((resolve) => {
    const reqDb = indexedDB.open(DB_NAME, DB_VERSION);

    reqDb.onerror = function () {
      console.log("error");
    };

    reqDb.onsuccess = function () {
      const db = reqDb.result;
      const transaction = db.transaction("offline_data", "readwrite");
      const store = transaction.objectStore("offline_data");
      resolve(store);
    };
  });
}

export async function get(id) {
  return new Promise(async (resolve, reject) => {
    const store = await openDb();
    const request = store.get(id);

    request.onsuccess = () => {
      const item = request.result;

      resolve(item);
    };

    request.onerror = (err) => {
      console.error("error", err);
      reject("Couldn't read offline store");
    };
  });
}

export async function getAll() {
  return new Promise(async (resolve, reject) => {
    const store = await openDb();
    const request = store.getAll();

    request.onsuccess = () => {
      const items = request.result;

      resolve(items);
    };

    request.onerror = (err) => {
      console.error("error", err);
      reject("Couldn't read offline store");
    };
  });
}

export async function add(type, data) {
  return new Promise(async (resolve, reject) => {
    const store = await openDb();

    const stored = store.add({
      temp_id: Date.now(),
      type,
      data,
    });

    stored.onsuccess = () => {
      resolve(stored.result);
    };

    stored.onerror = function () {
      reject(stored.error);
    };
  });
}

export async function update(id, item) {
  return new Promise(async (resolve) => {
    const store = await openDb();
    const request = store.put(item);

    request.onsuccess = () => {
      resolve();
    };
  });
}

export async function remove(id) {
  return new Promise(async (resolve) => {
    const store = await openDb();
    const request = store.delete(id);

    request.onsuccess = () => {
      resolve();
    };
  });
}
