import { useProperty } from "../context/PropertyProvider";
import { useContextUser } from "../context/UserProvider";
import { Outlet, Navigate } from "react-router-dom";

export default function Acl({ permissions }) {
  const [property] = useProperty();
  const { user, permissions: userPermissions } = useContextUser();

  if (user.role.must_select_property === 1 && !property) {
    return <Navigate to="/select-property" />;
  }

  return permissions.some((permission) => userPermissions.has(permission)) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" />
  );
}
