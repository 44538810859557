import { useEffect, useRef, useState } from "react";
import { usePosition } from "../../context/position";

import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";

import { useLocalState } from "../../hooks/useLocalState";

import { Sidebar } from "primereact/sidebar";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Image } from "primereact/image";
import { Message } from "primereact/message";
import { readAndCompressImage } from "browser-image-resizer";
import { toast } from "react-toastify";
import { useProperty } from "../../context/PropertyProvider";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";
import { useDar, useInsertDar, useUpdateDar } from "../../hooks/darQueries";
import Loader from "../loader";
import { readFiles, resizeImgs } from "../../utils";
import { useOffline } from "../../context/offline";

const MAX_FILES = 13;

const minDate = (userData) => {
  if (userData.isAdmin || userData.isSupervisor) {
    return new Date("2022-01-01");
  } else {
    return new Date();
  }
};

function getDefaultValues({ dar, today }) {
  return {
    dailyActivityReportTypeId: dar?.daily_activity_report_type_id,
    dailyActivityReportCodeId: dar?.daily_activity_report_code_id,
    details: dar?.details ?? "",
    dar_date: dar?.dar_date ? new Date(dar.dar_date) : today,
    send_notification: Boolean(dar?.send_notification ?? 0),
    include_gps_coordinates: Boolean(dar?.include_gps_coordinates ?? 0),
    images: dar?.images ?? [],
  };
}

function DarSheet({ isVisible, darId, dar: _dar, onClose }) {
  const today = useRef(new Date());
  const filesRef = useRef();
  const [userData] = useLocalState("", "userData");
  const [property] = useProperty();
  const [dailyActivityReportTypes] = useLocalState(
    "",
    "dailyActivityReportTypes"
  );
  const [dailyActivityReportCodes] = useLocalState(
    "",
    "dailyActivityReportCodes"
  );

  const { addItem, updateItem } = useOffline();
  const { position, getPosition } = usePosition();
  const [imagesUrls, setImagesUrls] = useState([]);

  const { isPending: _isPending, data: fetchedDar } = useDar(darId);
  const dar = !_isPending && darId ? fetchedDar.data.data : null;
  const isPending = darId && _isPending;

  const {
    mutateAsync: mutateInsertDar,
    data: addedDar,
    error: errorInsertDar,
    isError: isErrorInsertDar,
    isSuccess: isInsertSuccess,
  } = useInsertDar();

  const { mutateAsync: mutateUpdateDar, isSuccess: isUpdateSuccess } =
    useUpdateDar(darId);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm({
    values: getDefaultValues({
      dar: dar ?? _dar?.data,
      today: today.current,
    }),
  });

  const images = watch("images");

  useEffect(() => {
    // refresh position
    getPosition();
  }, []);

  useEffect(() => {
    if (dar || _dar) {
      const images = dar?.images || _dar.data.images;

      setImagesUrls(images ?? []);
    }
  }, [dar, _dar]);

  useEffect(() => {
    if (isErrorInsertDar) {
      console.log(isErrorInsertDar);
      console.log(errorInsertDar);
    }
  }, [isErrorInsertDar]);

  useEffect(() => {
    if (isInsertSuccess && addedDar) {
      const id = addedDar?.data.data.daily_activity_report_id;
      const msg = `DAR created #${id}`;
      toast.success(msg, TOAST_CONFIG);
      onClose();
    }
  }, [isInsertSuccess, addedDar, onClose]);

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success(`DAR updated #${darId}`, TOAST_CONFIG);
      onClose();
    }
  }, [isUpdateSuccess, onClose]);

  const onSubmit = async (data) => {
    try {
      const { latitude, longitude } = position;

      let base64Images = [];
      const toResize = images.filter((i) => i instanceof File);

      if (toResize.length) {
        const resized = await resizeImgs(toResize);
        base64Images = await readFiles(resized);

        base64Images = base64Images.map((b) => b.base64);
      }

      const darData = {
        daily_activity_report_type_id: data.dailyActivityReportTypeId,
        daily_activity_report_code_id: data.dailyActivityReportCodeId,
        dar_date: new Date(data.dar_date).toISOString(),
        automatic_dar: 0,
        details: data.details,
        gps_coordinates: `${latitude},${longitude}`,
        include_gps_coordinates: data.include_gps_coordinates,
        map: null,
        property_id: property,
        send_notification: data.send_notification,
        images: [
          ...base64Images,
          ...images.filter((i) => typeof i === "string"),
        ],
      };

      if (_dar) {
        await updateItem(_dar.id, darData);
        onClose();
      } else if (darId === null && navigator.onLine) {
        await mutateInsertDar(darData);
      } else if (!navigator.onLine) {
        await addItem("dar", darData);
        const msg = `The device has no connection. The DAR will be created once connection is recovered`;

        toast.success(msg, TOAST_CONFIG);
        onClose();
      } else {
        darData["daily_activity_report_id"] = darId;
        await mutateUpdateDar(darData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadFiles = async (e) => {
    const files = Array.from(e.target.files);
    if (files >= MAX_FILES) return;

    setValue("images", [...images, ...files]);

    const urls = files.map((f) => {
      return URL.createObjectURL(f);
    });
    setImagesUrls([...imagesUrls, ...urls]);

    e.target.value = "";
  };

  const handleSelectFiles = () => {
    filesRef.current.click();
  };

  const removeFiles = async (i) => {
    images.splice(i, 1);
    imagesUrls.splice(i, 1);

    setValue("images", images);
    setImagesUrls(imagesUrls);
  };

  return (
    <>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={onClose}
        className="w-full md:w-6 lg:w-5"
      >
        {isPending ? (
          <Loader />
        ) : (
          <>
            <h2>{darId ? "Update DAR" : "New DAR"}</h2>
            {!darId ? (
              <Message
                severity="info"
                text="Remember to explain WHO, WHAT, WHEN, WHERE, WHY, HOW and CONCLUSION"
                className="font-bold mb-3"
              />
            ) : null}
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <div className="formgrid grid">
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="dailyActivityReportTypeId"
                        rules={{
                          validate: (value) =>
                            value !== undefined || "You must select a DAR type",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a type"
                            optionLabel="type"
                            optionValue="daily_activity_report_type_id"
                            options={dailyActivityReportTypes}
                            {...field}
                          />
                        )}
                      />
                      <label htmlFor="type">Type</label>
                    </span>
                    <small className="block pt-1">
                      {errors.dailyActivityReportTypeId && (
                        <div className="text-red-500">
                          {errors.dailyActivityReportTypeId.message}
                        </div>
                      )}
                    </small>
                  </div>
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="dailyActivityReportCodeId"
                        rules={{
                          validate: (value) =>
                            value !== undefined || "You must select a DAR code",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a code"
                            optionLabel="type"
                            optionValue="daily_activity_report_code_id"
                            options={dailyActivityReportCodes}
                            {...field}
                          />
                        )}
                      />
                      <label htmlFor="type">Code</label>
                    </span>
                    <small className="block pt-1">
                      {errors.dailyActivityReportCodeId && (
                        <div className="text-red-500">
                          {errors.dailyActivityReportCodeId.message}
                        </div>
                      )}
                    </small>
                  </div>
                </div>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="dar_date"
                    render={({ field }) => (
                      <Calendar
                        id="dar_date"
                        name="dar_date"
                        dateFormat="yy-mm-dd"
                        showButtonBar
                        showTime
                        hourFormat="24"
                        maxDate={new Date()}
                        minDate={minDate(userData[0])}
                        hideOnDateTimeSelect
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />

                  <label htmlFor="dar_date">
                    Date <small>(yyyy-mm-dd)</small>
                  </label>
                </span>
                <small className="block pt-1">
                  {errors.dar_date && (
                    <div className="text-red-500">
                      {errors.dar_date.message}
                    </div>
                  )}
                </small>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="details"
                    control={control}
                    rules={{ required: "Details is required." }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
                        autoFocus
                        maxLength={500}
                        rows={5}
                        cols={30}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="details">
                    Details <small>(max 500 characters)</small>
                  </label>
                </span>
                <small className="block pt-1">
                  {errors.details && (
                    <div className="text-red-500">{errors.details.message}</div>
                  )}
                </small>
              </div>

              <div className="field">
                <label htmlFor="images">
                  <input
                    id="images"
                    name="images"
                    hidden
                    multiple
                    type="file"
                    ref={filesRef}
                    onChange={handleUploadFiles}
                  />
                  <Button type="button" onClick={handleSelectFiles}>
                    Select images
                  </Button>
                </label>

                <small className="block pt-1">
                  {errors.images && (
                    <div className="text-red-500">{errors.images.message}</div>
                  )}
                </small>
              </div>
              {imagesUrls?.length > 0 ? (
                <div className="field">
                  <ul className="m-0 p-0 list-none border-1 surface-border border-round p-2 flex flex-column gap-1 w-full ">
                    {imagesUrls.map((image, i) => (
                      <li
                        key={uuidv4()}
                        className={`hover:surface-hover border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between border-primary'}`}
                      >
                        <div className="flex align-items-center gap-1">
                          <Image src={image} width="80" height="80" />
                          <Button
                            icon="pi pi-trash"
                            onClick={() => removeFiles(i)}
                            severity="danger"
                            size="small"
                            style={{ backgroundColor: "#d32f2f80" }}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="send_notification"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <InputSwitch
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />

                  <label htmlFor="send_notification" className="ml-2">
                    &nbsp; &nbsp; &nbsp; &nbsp; Send notification to prop.
                    manager
                  </label>
                </span>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="include_gps_coordinates"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <InputSwitch
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        checked={value}
                        inputRef={ref}
                        //disabled={userData[0].isOfficer || userData[0].isPostCommander || userData[0].isSupervisor ? true : false}
                      />
                    )}
                  />

                  <label htmlFor="include_gps_coordinates" className="ml-2">
                    &nbsp; &nbsp; &nbsp; &nbsp; Include GPS coordinates
                  </label>
                </span>
              </div>

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
}

export default DarSheet;
