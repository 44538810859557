import { useEffect, useState } from "react";
import { useLocalState } from "../../hooks/useLocalState";

import Datatable from "./datatable";
import PropertySheet from "./property-sheet";
import PropertyInfoModal from "./property-info-modal";

import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";

import { useQueryClient } from "@tanstack/react-query";
import { useSectionPermissions } from "../../hooks/useSectionPermissions";
import { Card } from "primereact/card";
import { Bounce, toast } from "react-toastify";
import { Link } from "react-router-dom";

const MODAL_KEYS = {
  SHEET: "sheet",
  MODAL: "modal",
};

const CustomNot = ({ closeToast, toastProps }) => {
  return (
    <div className="flex align-content-end gap-2">
      <div>
        <p className="m-0" style={{ maxWidth: "34ch" }}>
          Property {toastProps.propertyName} needs to have at least one property
          manager.
        </p>
        <p className="m-0" style={{ maxWidth: "34ch" }}>
          If it doesn't exist yet, you can create it in the users section.
        </p>
      </div>
      <div className="flex">
        <Link to="users" className="mt-auto" asChild>
          <Button
            className="min-w-min white-space-nowrap "
            style={{ height: "max-content" }}
            onClick={() => closeToast()}
          >
            Go to users
          </Button>
        </Link>
      </div>
    </div>
  );
};

const Properties = () => {
  const [modalKeyOpen, setModalKeyOpen] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const queryClient = useQueryClient();
  const permissionsObject = useSectionPermissions("property", [
    "create",
    "show",
    "update",
    "delete",
  ]);

  const handleRefresh = (e) => {
    queryClient.invalidateQueries({
      queryKey: ["properties"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const handleOpenSheet = (propertyId) => {
    setModalKeyOpen(MODAL_KEYS.SHEET);
    if (propertyId) setPropertyId(propertyId);
  };

  const handleShowDetail = (propertyId) => {
    setModalKeyOpen(MODAL_KEYS.MODAL);
    if (propertyId) setPropertyId(propertyId);
  };

  const handleCloseModal = () => {
    setModalKeyOpen(null);
    setPropertyId(null);
  };

  const handleSave = (property) => {
    if (!propertyId && !property.property_managers.length) {
      toast.info(CustomNot, {
        propertyName: property.name,
        position: "bottom-left",
        autoClose: false,
        theme: "light",
        transition: Bounce,
        draggable: true,
      });
    }

    handleCloseModal();
  };

  return (
    <>
      <Card>
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className=" p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="Property"
              size="large"
              style={{ backgroundColor: "white", width: "7rem" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={handleRefresh}
            />
          </div>
          {permissionsObject.create ? (
            <Button
              label="New Property"
              id="newBtn"
              onClick={() => handleOpenSheet()}
            />
          ) : null}
        </div>
        <Datatable
          permissionsObject={permissionsObject}
          onShowDetail={handleShowDetail}
          onEdit={handleOpenSheet}
        />
      </Card>

      {modalKeyOpen === MODAL_KEYS.MODAL ? (
        <PropertyInfoModal
          isVisible
          propertyId={propertyId}
          onClose={handleCloseModal}
        />
      ) : null}
      {modalKeyOpen === MODAL_KEYS.SHEET ? (
        <PropertySheet
          isVisible
          propertyId={propertyId}
          onClose={handleCloseModal}
          onSave={handleSave}
        />
      ) : null}
    </>
  );
};

export default Properties;
