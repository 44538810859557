import { useQueryClient } from "@tanstack/react-query";
import { Button } from "primereact/button";
import List from "../list";
import Datatable from "./datatable";
import IncidentSheet from "./incident-sheet";
import { ShowModal } from "./incident-info-modal";
import { useSectionPermissions } from "../../hooks/useSectionPermissions";
import { useModal } from "../../hooks/useModal";
import { MODAL_KEYS } from "../../constants/modalKeys";

const IncidentReports = () => {
  const queryClient = useQueryClient();
  const { modalKeyOpen, itemId, handleOpenModal, handleCloseModal } =
    useModal();

  const permissionsObject = useSectionPermissions("incidentreport", [
    "create",
    "show",
    "update",
    "delete",
  ]);

  const handleRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: ["incidentReports"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  return (
    <>
      <List>
        <List.Header title="Incident Reports">
          <Button
            icon="pi pi-refresh"
            rounded
            text
            aria-label="Refresh"
            tooltip="Refresh"
            onClick={handleRefresh}
          />
        </List.Header>
        <List.Filters
          showCreate={permissionsObject.create}
          entity="Incident report"
          onCreate={() => handleOpenModal(MODAL_KEYS.SHEET)}
        />
        <Datatable
          permissionsObject={permissionsObject}
          onShowDetail={(incidentId) =>
            handleOpenModal(MODAL_KEYS.MODAL, incidentId)
          }
          onEdit={(incidentId) => handleOpenModal(MODAL_KEYS.SHEET, incidentId)}
        />
      </List>

      {modalKeyOpen === MODAL_KEYS.MODAL && (
        <ShowModal isVisible incidentId={itemId} onClose={handleCloseModal} />
      )}
      {modalKeyOpen === MODAL_KEYS.SHEET && (
        <IncidentSheet
          isVisible
          incidentId={itemId}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default IncidentReports;
