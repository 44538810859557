import { useState, useEffect, useRef } from "react";
import { usePosition } from "../../context/position";

import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";

import { useLocalState } from "../../hooks/useLocalState";

import { Sidebar } from "primereact/sidebar";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Image } from "primereact/image";

import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";

import {
  useIncidentReport,
  useInsertIncidentReport,
  useUpdateIncidentReport,
} from "../../hooks/incidentReportQueries";

import { useProperty } from "../../context/PropertyProvider";
import moment from "moment";
import { readFiles, resizeImgs } from "../../utils";
import { useOffline } from "../../context/offline";
import Loader from "../loader";

const MAX_FILES = 13;

const getDefaultValues = (incident) => {
  return {
    incidentReportTypeId: incident?.type,
    dailyActivityReportCodeId: incident?.code,
    details: incident?.details,
    incident_date: incident?.incident_date,
    send_notification: incident?.send_notification,
  };
};

const startOfYear = moment().startOf("year").toDate();

const SideBar = ({ isVisible, incidentId, incident: _incident, onClose }) => {
  const [property] = useProperty();
  const { addItem, updateItem } = useOffline();
  const [userData] = useLocalState("", "userData");
  const filesRef = useRef();
  const [incidentReportTypes] = useLocalState("", "incidentReportTypes");
  const { position, getPosition } = usePosition();
  const [imagesUrls, setImagesUrls] = useState([]);
  const minDateRef = useRef(
    userData[0].isAdmin || userData[0].isSupervisor ? startOfYear : new Date()
  );

  // const {
  //   isPending,
  //   isSuccess,
  //   isError,
  //   data: fetchedIncidentReport,
  //   error,
  // } = useIncidentReport(visibleForm.itemId);

  const { isPending: _isPending, data: fetchedIncident } =
    useIncidentReport(incidentId);
  const incidentReport =
    !_isPending && incidentId ? fetchedIncident.data.data : null;
  const isPending = incidentId && _isPending;

  const {
    mutateAsync: mutateInsertIncidentReport,
    data: addedIncident,
    error: errorInsertIncidentReport,
    isSuccess: isInsertSuccess,
    isError: isErrorInsertIncidentReport,
  } = useInsertIncidentReport();

  const {
    mutateAsync: mutateUpdateIncidentReport,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateIncidentReport();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm({
    values: getDefaultValues(_incident ?? incidentReport),
  });

  const images = watch("images");

  useEffect(() => {
    // refresh position
    getPosition();
  }, []);

  useEffect(() => {
    if (incidentReport || _incident) {
      const images = incidentReport?.images || _incident.data.images;

      setImagesUrls(images ?? []);
    }
  }, [incidentReport, _incident]);

  useEffect(() => {
    if (isErrorInsertIncidentReport) {
      console.log(isErrorInsertIncidentReport);
      console.log(errorInsertIncidentReport);
    }
  }, [isErrorInsertIncidentReport]);

  useEffect(() => {
    if (isInsertSuccess && addedIncident) {
      const id = addedIncident?.data.data.incident_report_id;
      const msg = `Incident report created #${id}`;
      toast.success(msg, TOAST_CONFIG);
      onClose();
    }
  }, [isInsertSuccess, addedIncident, onClose]);

  useEffect(() => {
    if (isUpdateSuccess) {
      const msg = `Incident report updated #${incidentId}`;
      toast.success(msg, TOAST_CONFIG);
      onClose();
    }
  }, [isUpdateSuccess, onClose]);

  const onSubmit = async (data) => {
    try {
      const { latitude, longitude } = position;

      let base64Images = [];
      const toResize = images.filter((i) => i instanceof File);

      if (toResize.length) {
        const resized = await resizeImgs(toResize);
        base64Images = await readFiles(resized);

        base64Images = base64Images.map((b) => b.base64);
      }

      let incidentData = {
        incident_report_type_id: data.incidentReportTypeId,
        incident_date: new Date(data.incident_date).toISOString(),
        details: data.details,
        gps_coordinates: `${latitude},${longitude}`,
        property_id: property,
        images: [
          ...base64Images,
          ...images.filter((i) => typeof i === "string"),
        ],
      };

      if (_incident) {
        await updateItem(_incident.id, incidentData);
        onClose();
      } else if (incidentId === null && navigator.onLine) {
        await mutateInsertIncidentReport(incidentData);
      } else if (!navigator.onLine) {
        await addItem("incident", incidentData);
        const msg = `The device has no connection. The Incident report will be created once connection is recovered`;

        toast.success(msg, TOAST_CONFIG);
        onClose();
      } else {
        incidentData["incident_report_id"] = incidentId;
        await mutateUpdateIncidentReport(incidentData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadFiles = async (e) => {
    const files = Array.from(e.target.files);
    if (files >= MAX_FILES) return;

    setValue("images", [...images, ...files]);

    const urls = files.map((f) => {
      return URL.createObjectURL(f);
    });
    setImagesUrls([...imagesUrls, ...urls]);

    e.target.value = "";
  };

  const handleSelectFiles = () => {
    filesRef.current.click();
  };

  const removeFiles = async (i) => {
    images.splice(i, 1);
    imagesUrls.splice(i, 1);

    setValue("images", images);
    setImagesUrls(imagesUrls);
  };

  return (
    <>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={onClose}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {isPending ? (
          <Loader />
        ) : (
          <>
            <h2>
              {incidentId ? "Update Incident report" : "New Incident report"}
            </h2>
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="incidentReportTypeId"
                    rules={{
                      validate: ({ value } = {}) =>
                        value !== undefined ||
                        "You must select a Incident type",
                      valueAsNumber: true,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        filter
                        placeholder="Select a type"
                        optionLabel="type"
                        optionValue="incident_report_type_id"
                        options={incidentReportTypes}
                        {...field}
                      />
                    )}
                  />
                  <label htmlFor="type">Type</label>
                </span>
                <small className="block pt-1">
                  {errors.incidentReportTypeId && (
                    <div className="text-red-500">
                      {errors.incidentReportTypeId.message}
                    </div>
                  )}
                </small>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="incident_date"
                    render={({ field }) => (
                      <Calendar
                        id="incident_date"
                        name="incident_date"
                        // onChange={(e) => {
                        //   setIncidentReportDate(e.value);
                        // }}
                        // value={incident_date}
                        dateFormat="mm/dd/yy"
                        showButtonBar
                        showTime
                        hourFormat="24"
                        maxDate={new Date()}
                        minDate={minDateRef.current}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />

                  <label htmlFor="incident_date">Incident Date</label>
                </span>
                <small className="block pt-1">
                  {errors.incident_date && (
                    <div className="text-red-500">
                      {errors.incident_date.message}
                    </div>
                  )}
                </small>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="details"
                    control={control}
                    rules={{ required: "Details is required." }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
                        autoFocus
                        maxLength={500}
                        rows={5}
                        cols={30}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="details">
                    Details <small>(max 500 characters)</small>
                  </label>
                </span>
                <small className="block pt-1">
                  {errors.details && (
                    <div className="text-red-500">{errors.details.message}</div>
                  )}
                </small>
              </div>

              <div className="field">
                <label htmlFor="images">
                  <input
                    id="images"
                    name="images"
                    hidden
                    multiple
                    type="file"
                    ref={filesRef}
                    onChange={handleUploadFiles}
                  />
                  <Button type="button" onClick={handleSelectFiles}>
                    Select images
                  </Button>
                </label>

                <small className="block pt-1">
                  {errors.images && (
                    <div className="text-red-500">{errors.images.message}</div>
                  )}
                </small>
              </div>
              {imagesUrls?.length > 0 ? (
                <div className="field">
                  <ul className="m-0 p-0 list-none border-1 surface-border border-round p-2 flex flex-column gap-1 w-full ">
                    {imagesUrls.map((image, i) => (
                      <li
                        key={uuidv4()}
                        className={`hover:surface-hover border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between border-primary'}`}
                      >
                        <div className="flex align-items-center gap-1">
                          <Image src={image} width="80" height="80" />
                          <Button
                            icon="pi pi-trash"
                            onClick={() => removeFiles(i)}
                            severity="danger"
                            size="small"
                            style={{ backgroundColor: "#d32f2f80" }}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default SideBar;
