import { useState } from "react";
import { useSectionPermissions } from "../../hooks/useSectionPermissions";
import Datatable from "./datatable";
import UserSheet from "./user-sheet";
import { UserInfoModal } from "./user-info-modal";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { ScrollTop } from "primereact/scrolltop";
import List from "../list";

import { useQueryClient } from "@tanstack/react-query";
import { Card } from "primereact/card";

const MODAL_KEYS = {
  SHEET: "sheet",
  MODAL: "modal",
};

const User = () => {
  const [modalKeyOpen, setModalKeyOpen] = useState(null);
  const [userId, setUserId] = useState(null);
  const queryClient = useQueryClient();

  const permissionsObject = useSectionPermissions("user", [
    "create",
    "show",
    "update",
    "delete",
  ]);

  const handleRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: ["users"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const handleOpenSheet = (userId) => {
    setModalKeyOpen(MODAL_KEYS.SHEET);
    if (userId) setUserId(userId);
  };

  const handleShowDetail = (userId) => {
    setModalKeyOpen(MODAL_KEYS.MODAL);
    if (userId) setUserId(userId);
  };

  const handleCloseModal = () => {
    setModalKeyOpen(null);
    setUserId(null);
  };

  return (
    <>
      <List>
        <List.Header title="Users">
          <Button
            icon="pi pi-refresh"
            rounded
            text
            aria-label="Refresh"
            tooltip="Refresh"
            onClick={handleRefresh}
          />
        </List.Header>
        <List.Filters
          showCreate={permissionsObject.create}
          entity="User"
          onCreate={() => handleOpenSheet()}
        />
        <Datatable
          permissionsObject={permissionsObject}
          onShowDetail={handleShowDetail}
          onEdit={handleOpenSheet}
        />
      </List>

      {modalKeyOpen === MODAL_KEYS.MODAL ? (
        <UserInfoModal isVisible userId={userId} onClose={handleCloseModal} />
      ) : null}
      {modalKeyOpen === MODAL_KEYS.SHEET ? (
        <UserSheet isVisible userId={userId} onClose={handleCloseModal} />
      ) : null}
    </>
  );
};

export default User;
