import { Sidebar } from "primereact/sidebar";
import Loader from "../loader";
import { useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {
  useHousehold,
  useInsertHousehold,
  useUpdateHousehold,
} from "../../hooks/houseHoldQueries";
import { useUpdateEffect } from "primereact/hooks";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { useProperty } from "../../context/PropertyProvider";
import { InputNumber } from "primereact/inputnumber";
import { FloatLabel } from "primereact/floatlabel";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { useUserByCriteria } from "../../hooks/userQueries";
import { classNames } from "primereact/utils";

function Header({ householdId, isPending, isSubmitting }) {
  const action = householdId ? "Updat" : "Creat";

  return (
    <div className="w-full flex align-items-center justify-content-between ">
      <div>
        <h2>{householdId ? "Update household" : "Create household"}</h2>
      </div>
      <div className="flex gap-2">
        <Button
          disabled={isSubmitting || isPending}
          type="submit"
          form="household"
          label={`${action}${isSubmitting ? "ing..." : "e"}`}
        />
      </div>
    </div>
  );
}

function getInitialValues(household) {
  return {
    name: household?.name ?? "",
    street: household?.street ?? "",
    number: household?.number ?? "",
    resident_id: household?.resident_id ?? "",
  };
}

export default function HouseholdSheet({ isVisible, householdId, onClose }) {
  const [property] = useProperty();
  const { household, isPending: isPendingHousehold } =
    useHousehold(householdId);
  const isPending = householdId && isPendingHousehold;

  const {
    control,
    handleSubmit: formSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    values: getInitialValues(household),
  });

  const { mutateAsync: mutateInsert, isSuccess: isSuccessInsert } =
    useInsertHousehold();

  const { mutateAsync: mutateUpdate, isSuccess: isSuccessUpdate } =
    useUpdateHousehold();

  const { data: dataUsers, isPending: isPendingUsers } = useUserByCriteria({
    q: "",
    propertyId: property,
    onlyResidents: false,
  });

  const suggestions =
    dataUsers?.data?.users.map((u) => ({
      ...u,
      full_name: `${u.first_name} ${u.last_name}`,
    })) || [];

  if (household && household.resident_id) {
    suggestions.push({
      user_id: household.resident_id,
      full_name: `${household.resident_first_name} ${household.resident_last_name}`,
    });
  }

  useUpdateEffect(() => {
    if (isSuccessInsert || isSuccessUpdate) {
      onClose();
    }
  }, [isSuccessInsert, isSuccessUpdate]);

  const handleSubmit = async (data) => {
    const mutate = householdId ? mutateUpdate : mutateInsert;
    let household = {
      ...data,
      property_id: property,
    };

    if (!data.resident_id) {
      household.resident_id = null;
    }

    if (householdId) {
      household.house_hold_id = householdId;
    }

    await mutate(household);
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      header={() => (
        <Header
          householdId={householdId}
          isPending={isPending}
          isSubmitting={isSubmitting}
        />
      )}
      onHide={onClose}
      className="w-full md:w-7 lg:w-7"
    >
      {isPending ? (
        <Loader />
      ) : (
        <form
          id="household"
          className="p-fluid pt-2"
          onSubmit={formSubmit(handleSubmit)}
        >
          <div className="field">
            <FloatLabel>
              <Controller
                control={control}
                name="name"
                rules={{ required: "This field is required" }}
                render={({ field, fieldState }) => (
                  <InputText
                    maxLength={50}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label htmlFor="name">Name</label>
            </FloatLabel>
            <small className="block pt-1">
              {errors.name && (
                <div className="text-red-500">{errors.name.message}</div>
              )}
            </small>
          </div>

          <div className="formgrid grid">
            <div className="field col">
              <FloatLabel>
                <Controller
                  control={control}
                  name="street"
                  rules={{ required: "This field is required" }}
                  render={({ field, fieldState }) => (
                    <InputText
                      maxLength={50}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="street">Street</label>
              </FloatLabel>
              <small className="block pt-1">
                {errors.street && (
                  <div className="text-red-500">{errors.street.message}</div>
                )}
              </small>
            </div>
            <div className="field col">
              <span className="p-float-label">
                <Controller
                  control={control}
                  name="number"
                  rules={{
                    required: "This field is required",
                    min: {
                      value: 0,
                      message: "This field is required",
                    },
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputNumber
                        min={0}
                        {...field}
                        value={field.value}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        onChange={(e) => {
                          const value = e.value ?? "";
                          field.onChange(value);
                        }}
                      />
                    );
                  }}
                />
                <label htmlFor="number">Number</label>
              </span>
              <small className="block pt-1">
                {errors.number && (
                  <div className="text-red-500">{errors.number.message}</div>
                )}
              </small>
            </div>
          </div>
          <div className="field">
            <FloatLabel>
              <Controller
                control={control}
                name="resident_id"
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    optionValue="user_id"
                    optionLabel="full_name"
                    showClear
                    options={suggestions}
                  />
                )}
              />
              <label htmlFor="resident_id">Resident</label>
            </FloatLabel>
            <small className="block pt-1">
              {errors.resident_id && (
                <div className="text-red-500">{errors.resident_id.message}</div>
              )}
            </small>
          </div>
        </form>
      )}
    </Sidebar>
  );
}
