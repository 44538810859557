import axios from "./axios";
import * as moment from "moment";
import { useLocalState } from "../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";
import { DB_NAME, DB_VERSION } from "../utils/Constansts";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";

import { blobToArrayBuffer } from "../helpers/helpers";

//import { ACCESS_TOKEN } from "../utils/Constansts";

export async function fetchCreateCitations(property_id) {
  let url = `citation/create/${property_id}`;

  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
    //return error;
  }
}

export async function fetchCitations(
  rows,
  page,
  property_id,
  sort_field,
  sort_order,
  user_name,
  citation_date_from,
  citation_date_to,
  license_plate,
  details
) {
  const params = new URLSearchParams();

  params.append("page", page);
  params.append("context_property_id", property_id);

  if (user_name) params.append("user_name", user_name);
  if (citation_date_from)
    params.append("citation_date_from", citation_date_from);
  if (citation_date_to) params.append("citation_date_to", citation_date_to);
  if (license_plate) params.append("license_plate", license_plate);
  if (details) params.append("details", details);

  const url = `citation/list/${rows}/${sort_field}/${sort_order}?${params.toString()}`;

  return await axios.get(url);
}

export async function fetchCitationTypes() {
  let url = "citationtype";
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
  }
}

export function fetchCitation(id, context_property_id) {
  let url = `citation/show?citation_id=${id}&context_property_id=${context_property_id}`;
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }
}

export async function addCitation(data) {
  let url = `citation/store`;
  const response = await axios.post(url, data);
  return response;
}

export function updateCitation(json) {
  let url = `citation/update`;
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axios.post(url, json, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

export function deleteCitation(id) {
  let url = `citation/delete`;
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axios.delete(url, {
    data: { citation_id: id },
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}
