import { Controller } from "react-hook-form";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import { useState } from "react";
import { Button } from "primereact/button";

const citations_by_options = [
  { name: "Household", value: 1, className: "text-xs p-2 min-w-min" },
  { name: "Plate", value: 2, className: "text-xs p-2 min-w-min" },
];

const citations_apply_by_options = [
  {
    name: "Individual rules",
    value: 1,
    className: "text-xs p-2 min-w-min white-space-nowrap",
  },
  {
    name: "All the same",
    value: 2,
    className: "text-xs p-2 min-w-min white-space-nowrap",
  },
];

const citations_period_optiosn = [
  {
    name: "Rooling window",
    value: 1,
    className: "text-xs p-2 min-w-min white-space-nowrap",
  },
  {
    name: "Calendar Month",
    value: 2,
    className: "text-xs p-2 min-w-min white-space-nowrap",
  },
  {
    name: "Calendar Year",
    value: 3,
    className: "text-xs p-2 min-w-min white-space-nowrap",
  },
];

const safelistings_options = [
  {
    name: "Household",
    value: 1,
    className: "text-xs p-2 min-w-min",
  },
  {
    name: "Plate",
    value: 2,
    className: "text-xs p-2 min-w-min",
  },
];

const safelisting_period_options = [
  {
    name: "Rolling window",
    value: 1,
    className: "text-xs p-2 min-w-min white-space-nowrap",
  },
  {
    name: "Calendar month",
    value: 2,
    className: "text-xs p-2 min-w-min white-space-nowrap",
  },
  {
    name: "Calendar year",
    value: 3,
    className: "text-xs p-2 min-w-min white-space-nowrap",
  },
];

const count_to_to_options = [
  { name: "Yes", value: 1, className: "text-xs p-2 min-w-min" },
  { name: "No", value: 0, className: "text-xs p-2 min-w-min" },
];

function SectionSwitch({ control, label, name, errors, disabled, children }) {
  return (
    <div className="border-solid border-round border-1 border-200 p-3">
      <div className="flex flex-column gap-3 ">
        <div className="flex justify-content-between align-items-center">
          <label htmlFor={name} className="text-base">
            {label}
          </label>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <InputSwitch
                {...field}
                checked={field.value}
                disabled={disabled}
              />
            )}
          />
        </div>
        {errors[name] && (
          <small className="text-red-500">{errors[name].message}</small>
        )}
      </div>
      {children ? <div className="mt-4">{children}</div> : null}
    </div>
  );
}

export default function PermissionsPanel({
  watch,
  control,
  errors,
  mandatorySections,
  vehicleCitationTypes,
}) {
  const poolaccesslogs = watch("poolaccesslogs");
  const safelistings = watch("safelistings");
  const permits_period_by = watch("permits_period_by");
  const lunch_break_deductible = watch("lunch_break_deductible");
  const citations = watch("citations");
  const citations_apply_by = watch("citations_apply_by");

  return (
    <>
      <div className="mb-3">
        <div className="mb-4">
          <p className="text-xl font-semibold m-0">
            Property Management Sections
          </p>
        </div>

        <div className="mb-4 flex flex-column gap-2">
          <SectionSwitch
            control={control}
            name="safelistings"
            label="Safelistings"
            disabled={mandatorySections.includes("safelistings")}
            errors={errors}
          >
            {safelistings ? (
              <div>
                <div className="mb-4 flex gap-4 justify-content-start">
                  <div className="">
                    <Controller
                      control={control}
                      name="permits_by"
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <div className="flex flex-column gap-1 text-xs">
                          <div className="flex align-items-center gap-1">
                            <label
                              htmlFor="permits_by"
                              className="white-space-nowrap"
                            >
                              <span>Safelistings by</span>
                            </label>
                            <SelectButton
                              {...field}
                              optionLabel="name"
                              className="gap-1"
                              options={safelistings_options}
                            />
                          </div>
                          {errors["permits_by"] && (
                            <span className="text-red-500">
                              {errors["permits_by"].message}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <div className="flex justify-content-between">
                    <Controller
                      control={control}
                      name="permits_period_by"
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <div className="flex flex-column gap-1 text-xs">
                          <div className="flex align-items-center text-xs gap-1">
                            <label
                              htmlFor="permits_period_by"
                              className="white-space-nowrap"
                            >
                              Period by
                            </label>
                            <SelectButton
                              {...field}
                              optionLabel="name"
                              className="gap-1"
                              options={safelisting_period_options}
                            />
                          </div>
                          {errors["permits_period_by"] && (
                            <span className="text-red-500">
                              {errors["permits_period_by"].message}
                            </span>
                          )}
                        </div>
                      )}
                    />

                    {permits_period_by === 1 ? (
                      <Controller
                        name="number_of_days"
                        control={control}
                        rules={{
                          required: "Number of days is required.",
                          min: 0,
                          max: 100,
                        }}
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            suffix=" days"
                            className="w-6rem"
                            min={0}
                            max={100}
                            useGrouping={false}
                            placeholder="0"
                            inputClassName="text-xs text-center p-2"
                          />
                        )}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="mb-4">
                  <Controller
                    name="number_of_parking_permits"
                    control={control}
                    rules={{
                      required: "Number of days is required.",
                      min: 0,
                      max: 100,
                    }}
                    render={({ field }) => (
                      <div className="text-xs flex justify-content-between align-items-center">
                        <label
                          htmlFor="number_of_parking_permits"
                          className="white-space-nowrap flex flex-column"
                        >
                          <span>
                            Number of parking permits granted per selected
                            period
                          </span>
                          {errors.number_of_parking_permits && (
                            <span className="text-red-500">
                              {errors.number_of_parking_permits.message}
                            </span>
                          )}
                        </label>
                        <InputNumber
                          {...field}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          className="max-w-6rem"
                          min={0}
                          max={100}
                          placeholder="0"
                          inputClassName="text-xs text-center p-2"
                        />
                      </div>
                    )}
                  />
                </div>

                <div className="mb-4">
                  <Controller
                    name="days_in_a_row"
                    control={control}
                    rules={{
                      required: "Number of days is required.",
                      min: 0,
                      max: 100,
                    }}
                    render={({ field }) => (
                      <div className="text-xs flex gap-1 justify-content-between align-items-center">
                        <label
                          htmlFor="days_in_a_row"
                          className="white-space-nowrap flex flex-column"
                        >
                          <span>How many days in a row can be requested?</span>
                          {errors.days_in_a_row && (
                            <span className="text-red-500">
                              {errors.days_in_a_row.message}
                            </span>
                          )}
                        </label>
                        <InputNumber
                          {...field}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          className="max-w-6rem"
                          min={0}
                          max={100}
                          placeholder="0"
                          inputClassName="text-xs text-center p-2"
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            ) : null}
          </SectionSwitch>

          <SectionSwitch
            control={control}
            name="households"
            label="Households"
            disabled={mandatorySections.includes("households")}
            errors={errors}
          />

          <SectionSwitch
            control={control}
            name="pools"
            label="Pools"
            disabled={mandatorySections.includes("pools")}
            errors={errors}
          />

          <SectionSwitch
            control={control}
            name="vehicles"
            label="Vehicles"
            disabled={mandatorySections.includes("vehicles")}
            errors={errors}
          />
        </div>
      </div>

      <div className="mb-3">
        <div className="mb-4">
          <p className="text-xl font-semibold m-0">User Management Sections</p>
        </div>

        <div className="mb-4 flex flex-column gap-2">
          <SectionSwitch
            control={control}
            name="users"
            label="Users"
            disabled={mandatorySections.includes("users")}
            errors={errors}
          />
          <SectionSwitch
            control={control}
            name="schedule"
            label="Schedules"
            disabled={mandatorySections.includes("schedule")}
            errors={errors}
          />
          <SectionSwitch
            control={control}
            name="shifts"
            label="Shifts"
            disabled={mandatorySections.includes("shifts")}
            errors={errors}
          />
          <SectionSwitch
            control={control}
            name="vehiclemaintenance"
            label="Vehicle Maintenance"
            disabled={mandatorySections.includes("vehiclemaintenance")}
            errors={errors}
          />

          <SectionSwitch
            control={control}
            name="lunch_break_deductible"
            label="Lunch deductible"
            disabled={mandatorySections.includes("lunch_break_deductible")}
            errors={errors}
          >
            {lunch_break_deductible ? (
              <div className="mb-4 flex gap-4 justify-content-start">
                <Controller
                  name={"lunch_duration"}
                  control={control}
                  rules={{
                    required: "Lunch duration is required.",
                    min: 0,
                    max: 100,
                  }}
                  render={({ field }) => (
                    <div className="flex gap-2 align-items-center text-xs">
                      <label htmlFor="lunch_duration">Lunch duration</label>
                      <InputNumber
                        {...field}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        className="w-6rem "
                        min={0}
                        max={100}
                        useGrouping={false}
                        inputClassName="text-xs text-center p-2"
                      />
                      <small className="block pt-1">
                        {errors.lunch_duration && (
                          <div className="text-red-500">
                            {errors.lunch_duration.message}
                          </div>
                        )}
                      </small>
                    </div>
                  )}
                />
                <Controller
                  name={"breaks_duration"}
                  control={control}
                  rules={{
                    required: "Break duration is required.",
                    min: 0,
                    max: 100,
                  }}
                  render={({ field }) => (
                    <div className="flex gap-2 align-items-center text-xs">
                      <label htmlFor="breaks_duration">Breaks duration</label>
                      <InputNumber
                        {...field}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        className="w-6rem"
                        min={0}
                        max={100}
                        useGrouping={false}
                        inputClassName="text-xs text-center p-2"
                      />
                      <small className="block pt-1">
                        {errors.breaks_duration && (
                          <div className="text-red-500">
                            {errors.breaks_duration.message}
                          </div>
                        )}
                      </small>
                    </div>
                  )}
                />
              </div>
            ) : null}
          </SectionSwitch>

          <Controller
            name="allowed_distance"
            control={control}
            rules={{
              required: "Allowed distance is required.",
              min: 0,
              max: 100,
            }}
            render={({ field }) => (
              <div className="flex flex-column gap-3 border-solid border-round border-1 border-200 p-3">
                <div className="flex align-items-center gap-2 justify-content-between align-items-center">
                  <label htmlFor="allowed_distance">
                    Allowed distance loging from property
                  </label>
                  <InputNumber
                    {...field}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    className="w-6rem "
                    min={0}
                    max={100}
                    useGrouping={false}
                    inputClassName="text-xs text-center p-2"
                  />
                </div>
                {errors.allowed_distance && (
                  <small className="text-red-500">
                    {errors.allowed_distance.message}
                  </small>
                )}
              </div>
            )}
          />
        </div>
      </div>

      <div className="mb-3">
        <div className="mb-4">
          <p className="text-xl font-semibold m-0">User Notifications</p>
        </div>

        <div className="mb-4 flex flex-column gap-2">
          <SectionSwitch
            control={control}
            name="briefing_board"
            label="Briefing board"
            disabled={mandatorySections.includes("briefing_board")}
            errors={errors}
          />
        </div>
      </div>

      <div className="mb-3">
        <div className="mb-4">
          <p className="text-xl font-semibold m-0">Report Sections</p>
        </div>

        <div className="mb-4 flex flex-column gap-2">
          <SectionSwitch
            control={control}
            name="dar"
            label="DARs"
            disabled={mandatorySections.includes("dar")}
            errors={errors}
          />

          <SectionSwitch
            control={control}
            name="incidents"
            label="Incident Reports"
            disabled={mandatorySections.includes("incidents")}
            errors={errors}
          />

          <SectionSwitch
            control={control}
            name="dispatchlogs"
            label="Dispatch Logs"
            disabled={mandatorySections.includes("dispatchlogs")}
            errors={errors}
          />

          <SectionSwitch
            control={control}
            name="poolaccesslogs"
            label="Pool Access Logs"
            disabled={mandatorySections.includes("poolaccesslogs")}
            errors={errors}
          >
            {poolaccesslogs ? (
              <Controller
                name="number_of_guest_in_pool"
                control={control}
                rules={{
                  required: "Number of enabled guest in the pool is required.",
                  min: 0,
                  max: 100,
                }}
                render={({ field }) => (
                  <div className="flex justify-content-between align-items-center text-xs">
                    <label
                      htmlFor="number_of_guest_in_pool"
                      className="flex flex-column gap-2"
                    >
                      <span>Number of enabled guest in the pool</span>
                      {errors.number_of_guest_in_pool && (
                        <small className="text-red-500">
                          {errors.number_of_guest_in_pool.message}
                        </small>
                      )}
                    </label>
                    <InputNumber
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      className="w-6rem "
                      min={0}
                      max={100}
                      placeholder="0"
                      useGrouping={false}
                      inputClassName="text-xs text-center p-2"
                    />
                  </div>
                )}
              />
            ) : null}
          </SectionSwitch>

          <SectionSwitch
            control={control}
            name="citations"
            label="Citations"
            disabled={mandatorySections.includes("citations")}
            errors={errors}
          >
            {citations ? (
              <div className="flex flex-column gap-3">
                <Controller
                  control={control}
                  name="citations_by"
                  rules={{
                    required: "This field is required",
                  }}
                  render={({ field }) => (
                    <div className="flex flex-column gap-1 text-xs">
                      <div className="flex align-items-center gap-1">
                        <label
                          htmlFor="citations_by"
                          className="white-space-nowrap"
                        >
                          <span>Citations by</span>
                        </label>
                        <SelectButton
                          {...field}
                          optionLabel="name"
                          className="gap-1"
                          options={citations_by_options}
                        />
                      </div>
                      {errors["citations_by"] && (
                        <span className="text-red-500">
                          {errors["citations_by"].message}
                        </span>
                      )}
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  name="citations_apply_by"
                  rules={{
                    required: "This field is required",
                  }}
                  render={({ field }) => (
                    <div className="flex flex-column gap-1 text-xs">
                      <div className="flex align-items-center text-xs gap-1">
                        <label
                          htmlFor="citations_apply_by"
                          className="white-space-nowrap"
                        >
                          Citations apply by
                        </label>
                        <SelectButton
                          {...field}
                          optionLabel="name"
                          className="gap-1"
                          options={citations_apply_by_options}
                        />
                      </div>
                      {errors["citations_apply_by"] && (
                        <span className="text-red-500">
                          {errors["citations_apply_by"].message}
                        </span>
                      )}
                    </div>
                  )}
                />

                {citations_apply_by === 2 ? (
                  <CitationsPeriod
                    name="citation_period_by"
                    daysName="citations_number_of_days"
                    control={control}
                    errors={errors}
                  />
                ) : null}

                {vehicleCitationTypes.map((vc) => (
                  <div
                    key={vc.citation_type_id}
                    className="flex flex-column gap-2"
                  >
                    <Controller
                      control={control}
                      name={vc.key}
                      render={({ field }) => (
                        <div className="flex flex-column gap-3">
                          <div className="flex align-items-center text-xs gap-1">
                            <Checkbox
                              {...field}
                              inputId={vc.key}
                              checked={field.value}
                            />
                            <label
                              htmlFor={vc.key}
                              className="white-space-nowrap"
                            >
                              {vc.name}
                            </label>
                          </div>
                          {field.value && (
                            <div className="ml-4 flex flex-column gap-4">
                              {(vc.type !== null ||
                                citations_apply_by === 1) && (
                                <>
                                  <CitationTypeInputs
                                    citation={vc}
                                    control={control}
                                  />
                                  {citations_apply_by === 1 ? (
                                    <CitationsPeriod
                                      name={`${vc.key}-period-by`}
                                      daysName={`${vc.key}-days`}
                                      control={control}
                                      errors={errors}
                                    />
                                  ) : null}
                                </>
                              )}
                              <CitationsTow
                                name={`${vc.key}-count-to-tow`}
                                control={control}
                                errors={errors}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    />
                    {errors[vc.key] && (
                      <small className="text-red-500">
                        {errors[vc.key].message}
                      </small>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </SectionSwitch>
        </div>
      </div>
    </>
  );
}

function CitationsPeriod({ name, daysName, control, errors }) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: "This field is required" }}
      render={({ field }) => (
        <div className="flex flex-column gap-2 text-xs">
          <div className="flex align-items-center justify-content-between">
            <div className="flex align-items-center gap-1">
              <label htmlFor={name} className="white-space-nowrap">
                Citations period
              </label>
              <SelectButton
                {...field}
                optionLabel="name"
                className="gap-1"
                options={citations_period_optiosn}
              />
            </div>
            {field.value === 1 ? (
              <Controller
                name={daysName}
                control={control}
                rules={{
                  required: "Number of days is required.",
                  min: 0,
                  max: 100,
                }}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    suffix=" days"
                    className="w-6rem"
                    min={0}
                    max={100}
                    useGrouping={false}
                    placeholder="0"
                    inputClassName="text-xs text-center p-2"
                  />
                )}
              />
            ) : null}
          </div>
          {errors[name] && (
            <span className="text-red-500">{errors[name].message}</span>
          )}
          {field.value === 1 && errors[daysName] && (
            <span className="text-red-500">{errors[daysName].message}</span>
          )}
        </div>
      )}
    />
  );
}

function CitationsTow({ name, control, errors }) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: "This field is required",
      }}
      defaultValue={1}
      render={({ field }) => (
        <div className="flex flex-column gap-2 text-xs">
          <div className="flex align-items-center gap-1 ">
            <label htmlFor={name} className="white-space-nowrap">
              Count to reach tow?
            </label>
            <SelectButton
              {...field}
              optionLabel="name"
              className="gap-1"
              options={count_to_to_options}
            />
          </div>
          {errors[name] && (
            <span className="text-red-500">{errors[name].message}</span>
          )}
        </div>
      )}
    />
  );
}

const MAX_RANGE_COUNT = 3;

function CitationTypeInputs({ citation, control }) {
  const _count = Object.keys(control._formValues).filter((k) =>
    k.startsWith(`${citation.key}-from`)
  ).length;

  const [rangeCount, setRangeCount] = useState(() => {
    const count = _count ? _count : 1;

    return new Array(count).fill("").map((_, i) => i + 1);
  });

  const handleAddRange = () => {
    if (rangeCount.length < MAX_RANGE_COUNT)
      setRangeCount((v) => [...v, v.at(-1) + 1]);
  };

  const handleRemoveRange = (c, i) => {
    control.unregister(`${citation.key}-from-${c}`);
    control.unregister(`${citation.key}-to-${c}`);
    const range = [...rangeCount];
    range.splice(i, 1);

    setRangeCount(range);
  };

  if (citation.type === null) return null;

  if (citation.type === "numeric") {
    return (
      <Controller
        control={control}
        name={`${citation.key}-value`}
        render={({ field }) => (
          <InputNumber
            {...field}
            value={field.value}
            onChange={(e) => field.onChange(e.value)}
            className="w-6rem"
            min={0}
            max={100}
            placeholder="0"
            inputClassName="text-xs text-center p-2"
          />
        )}
      />
    );
  } else if (citation.type === "range") {
    return rangeCount.map((c, i) => (
      <div
        key={`${citation.key}-range-${c}`}
        className="flex align-items-center gap-2"
      >
        <Controller
          control={control}
          name={`${citation.key}-from-${c}`}
          render={({ field }) => (
            <InputNumber
              {...field}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              className="w-6rem"
              min={0}
              max={100}
              placeholder="0"
              inputClassName="text-xs text-center p-2"
            />
          )}
        />
        <span>-</span>
        <Controller
          control={control}
          name={`${citation.key}-to-${c}`}
          render={({ field }) => (
            <InputNumber
              {...field}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              className="w-6rem"
              min={0}
              max={100}
              placeholder="0"
              inputClassName="text-xs text-center p-2"
            />
          )}
        />
        {rangeCount.length > 1 ? (
          <Button
            className="max-w-2rem max-h-2rem p-2 flex justify-content-center"
            text
            onClick={() => handleRemoveRange(c, i)}
          >
            <i className="pi pi-times" />
          </Button>
        ) : null}
        {i === rangeCount.length - 1 && rangeCount.length < MAX_RANGE_COUNT ? (
          <Button
            className="max-w-2rem max-h-2rem p-2 flex justify-content-center"
            onClick={handleAddRange}
          >
            <i className="pi pi-plus" />
          </Button>
        ) : null}
      </div>
    ));
  }
}
