import { Card } from "primereact/card";
import Datatable from "./datatable";
import { useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import DarSheet from "../dars/dar-sheet";
import { useOffline } from "../../context/offline";
import { Message } from "primereact/message";

const MODAL_KEYS = {
  DAR: "dar",
};

export default function Offline() {
  const { items, isPending, removeItem } = useOffline();
  const [openModal, setOpenModal] = useState();
  const [item, setItem] = useState();

  const handleEdit = (item) => {
    setItem(item);
    setOpenModal(MODAL_KEYS.DAR);
  };

  const handleDelete = (item) => {
    confirmDialog({
      key: 1,
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: async () => {
        await removeItem(item.id);
      },
    });
  };

  const handleClose = () => {
    setOpenModal(null);
    setItem(null);
  };

  return (
    <>
      <Card>
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className="flex align-content-center">
            <p className="text-2xl m-0 flex align-content-center flex-wrap">
              Offline data
            </p>
          </div>
        </div>
        {isPending ? (
          <div className="my-2">
            <Message
              className="py-3 px-3"
              text="Connection restore, syncing data. Please wait..."
            />
          </div>
        ) : null}
        <Datatable
          items={items}
          isPending={isPending}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      </Card>
      {openModal === MODAL_KEYS.DAR ? (
        <DarSheet isVisible dar={item} onClose={handleClose} />
      ) : null}
    </>
  );
}
